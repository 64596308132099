import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class IntervalService {
  /**
   * returns an interval id to clear later.
   *
   * @param callback
   * @param interval
   */
  setInterval(callback: () => void, interval: number): number {
    return window.setInterval(callback, interval);
  }

  /**
   * clear a given interval setup by the above function..
   *
   * @param intervalId
   */
  clearInterval(intervalId: number) {
    window.clearInterval(intervalId);
  }
}
