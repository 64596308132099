/**
 * Created by davidhaveman on 7/25/17.
 */
import { Type } from "@angular/core";
import { CardTypeComponent } from "../models/types/card.type.interface";
import { FeedCardTypeComponent } from "../models/types/feed.card.type.interface";

const registry = new Map<string, Type<FeedCardTypeComponent>>();
const cardRegistry = new Map<string, Type<CardTypeComponent>>();

let defaultComponent: Type<FeedCardTypeComponent> = null;

export class RegistryService {
  public static register(name: string, comp: Type<FeedCardTypeComponent>): void {
    console.log("Registering component for type : " + name);
    registry.set(name, comp);
  }

  public static registerCard(name: string, comp: Type<CardTypeComponent>): void {
    console.log("Registering component for card type : " + name);
    cardRegistry.set(name, comp);
  }

  static getCardComponent(name: string): Type<CardTypeComponent> {
    return cardRegistry.get(name);
  }

  static get(name: string): Type<FeedCardTypeComponent> {
    let result = registry.get(name);
    if (result == null) {
      result = defaultComponent;
    }

    return result;
  }

  static default(def: Type<FeedCardTypeComponent>) {
    defaultComponent = def;
  }
}
