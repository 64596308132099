import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from "@angular/forms";

// tslint:disable-next-line:only-arrow-functions
export function maxAmount(max: number, min: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const forbidden = control.value > max;
    if (forbidden) {
      return { maxValue: { value: control.value } };
    } else if (control.value < min) {
      return { minValue: { value: control.value } };
    } else {
      return null;
    }
  };
}

//the tslint rule for selector names does not play nice with the input bindings
@Directive({
  selector: "[maxValue]", // tslint:disable-line
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValueValidatorDirective, multi: true }]
})
export class MaxValueValidatorDirective implements Validator {
  @Input() maxValue: number;
  @Input() minValue: number;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.maxValue ? maxAmount(this.maxValue, this.minValue)(control) : null;
  }
}
