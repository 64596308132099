import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, Injectable, NgModule, NgZone } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { Ng2Webstorage } from "@rars/ngx-webstorage";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard, CardService } from "./shared";
import { WindowRef } from "./shared/services/window.ref";

import "hammerjs";

import { CookieService } from "ngx-cookie-service";
import { MenuComponent } from "./menu/menu.component";
import { AuthService } from "./shared/services/auth.service";
import { BridgeService } from "./shared/services/bridge.service";
import { CacheHelper } from "./shared/services/cache.helper";
import { HttpGet } from "./shared/services/http.get";
import { ErrorInterceptor } from "./shared/services/http.interceptor";
import { MessagingService } from "./shared/services/messaging.service";
import { SharedModule } from "./shared/shared.module";

import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PartnerReferralComponent } from "./partner-referral/partner-referral.component";
import { ErrorsHandler } from "./shared/services/errors-handler";
import { MobileService } from "./shared/services/mobile.service";
import { FirebaseRemoteConfigureService } from "./shared/services/firebase.service";
import { AmplitudeService } from "./shared/services/amplitude.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TraceService } from "@sentry/angular";
import { Router } from "@angular/router";

// tslint:disable-next-line:only-arrow-functions
export function HttpLoaderFactory(http: HttpClient) {
  const origin = window.location.origin;
  return new TranslateHttpLoader(http, `${origin}/assets/i18n/`, ".json");
}
// ...
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: { enable: true },
    pinch: { enable: false },
    rotate: { enable: false },
  } as any;
}

@NgModule({
  declarations: [AppComponent, MenuComponent, PartnerReferralComponent], //TODO Menu&PartnerReferral should be in their own modules not here
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HammerModule,
    HttpClientModule,
    Ng2Webstorage,
    SharedModule,
    TranslateModule.forRoot({
      defaultLanguage: "en", //changing this is an easy way to test out different languages
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  providers: [
    AmplitudeService,
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    MessagingService,
    CookieService,
    AuthGuard,
    BridgeService,
    AuthService,
    HttpGet,
    WindowRef,
    CardService,
    MobileService,
    FirebaseRemoteConfigureService,
    {
      provide: TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  publishMessage(message: string): void {
    this._ngZone.run(() => {
      this.messageBus.publish(message);
    });
  }

  pushEvent(code: string, payload: any): void {
    this._ngZone.run(() => {
      console.log(`code: ${code}`);
      console.log(`payload:\n\n${payload.toString()}\n`);
      this.messageBus.publish({ code, payload });
    });
  }

  readonly oneWeekSeconds = 600000; // not exactly a week but I like round numbers
  readonly defaultTimeoutSeconds = 120;

  /**
   * puts data in the local storage cache
   * cache timeout is determined by the key
   * @param {string} key
   * @param {string} value (must be valid JSON), gets put in cache under key
   */
  webstorage(key: string, value: string): void {
    this._ngZone.run(() => {
      const seconds = this.calculateTimeout(key);
      const destringifiedValue = JSON.parse(value);
      this.cacheHelper.start(key, seconds, destringifiedValue, true);
    });
  }

  /**
   * use a default timeout value, unless the key is 'version', then use one week
   * @param {string}key
   */
  private calculateTimeout(key: string) {
    return key === "version" ? this.oneWeekSeconds : this.defaultTimeoutSeconds;
  }

  constructor(
    protected messageBus: MessagingService,
    private _ngZone: NgZone,
    private cacheHelper: CacheHelper,
    private firebaseRemoteConfigureService: FirebaseRemoteConfigureService,
    private traceService: TraceService
  ) {
    this.firebaseRemoteConfigureService.setUpConfig();
  }
}
