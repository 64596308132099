/**
 * PLEASE BREAK UP THE KEYS BY PAGE/SECTION AND ALPHABETIZE THEM WHERE POSSIBLE
 */
export enum MicroAccountsTranslationKey {
  // dashboard keys
  MICRO_ACCOUNTS_DASHBOARD_TITLE = "MICRO_ACCOUNTS_DASHBOARD_TITLE",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_MANAGE_ACCOUNTS = "MICRO_ACCOUNTS_DASHBOARD_ACTION_MANAGE_ACCOUNTS",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_ACCOUNT_DETAILS = "MICRO_ACCOUNTS_DASHBOARD_ACTION_ACCOUNT_DETAILS",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_DIGITAL_CARD_DETAILS = "MICRO_ACCOUNTS_DASHBOARD_ACTION_DIGITAL_CARD_DETAILS",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_LOCK_UNLOCK_CARD = "MICRO_ACCOUNTS_DASHBOARD_ACTION_LOCK_UNLOCK_CARD",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_VIEW_STATEMENTS = "MICRO_ACCOUNTS_DASHBOARD_ACTION_VIEW_STATEMENTS",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_LOST_STOLEN_CARD = "MICRO_ACCOUNTS_DASHBOARD_ACTION_LOST_STOLEN_CARD",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_RESET_PIN = "MICRO_ACCOUNTS_DASHBOARD_ACTION_RESET_PIN",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_FIND_ATM = "MICRO_ACCOUNTS_DASHBOARD_ACTION_FIND_ATM",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_SUPPORT = "MICRO_ACCOUNTS_DASHBOARD_ACTION_SUPPORT",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_CASH_ADVANCE = "MICRO_ACCOUNTS_DASHBOARD_ACTION_CASH_ADVANCE",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_TRANSFER_FUNDS = "MICRO_ACCOUNTS_DASHBOARD_ACTION_TRANSFER_FUNDS",
  MICRO_ACCOUNTS_DASHBOARD_ACTION_MORE = "MICRO_ACCOUNTS_DASHBOARD_ACTION_MORE",
  MICRO_ACCOUNTS_DASHBOARD_DRAWER_TITLE = "MICRO_ACCOUNTS_DASHBOARD_DRAWER_TITLE",
  MICRO_ACCOUNTS_DASHBOARD_DRAWER_SUBTITLE = "MICRO_ACCOUNTS_DASHBOARD_DRAWER_SUBTITLE",
  MICRO_ACCOUNTS_DASHBOARD_DRAWER_OPTION_1_TITLE = "MICRO_ACCOUNTS_DASHBOARD_DRAWER_OPTION_1_TITLE",
  MICRO_ACCOUNTS_DASHBOARD_DRAWER_OPTION_1_SUBTITLE = "MICRO_ACCOUNTS_DASHBOARD_DRAWER_OPTION_1_SUBTITLE",
  MICRO_ACCOUNTS_DASHBOARD_DRAWER_OPTION_2_TITLE = "MICRO_ACCOUNTS_DASHBOARD_DRAWER_OPTION_2_TITLE",
  MICRO_ACCOUNTS_DASHBOARD_DRAWER_OPTION_2_SUBTITLE = "MICRO_ACCOUNTS_DASHBOARD_DRAWER_OPTION_2_SUBTITLE",
  MICRO_ACCOUNTS_DASHBOARD_VERIFY_MODAL_TITLE = "MICRO_ACCOUNTS_DASHBOARD_VERIFY_MODAL_TITLE",
  MICRO_ACCOUNTS_DASHBOARD_VERIFY_MODAL_BODY = "MICRO_ACCOUNTS_DASHBOARD_VERIFY_MODAL_BODY",
  MICRO_ACCOUNTS_DASHBOARD_VERIFY_MODAL_DECLINE = "MICRO_ACCOUNTS_DASHBOARD_VERIFY_MODAL_DECLINE",
  MICRO_ACCOUNTS_DASHBOARD_VERIFY_MODAL_ACCEPT = "MICRO_ACCOUNTS_DASHBOARD_VERIFY_MODAL_ACCEPT",

  // all transactions keys
  MICRO_ACCOUNTS_ALL_TRANSACTIONS_NO_TX_MERCHANT = "MICRO_ACCOUNTS_ALL_TRANSACTIONS_NO_TX_MERCHANT",
  MICRO_ACCOUNTS_ALL_TRANSACTIONS_PAGE_TITLE = "MICRO_ACCOUNTS_ALL_TRANSACTIONS_PAGE_TITLE",
  MICRO_ACCOUNTS_ALL_TRANSACTIONS_LOADING_ERROR = "MICRO_ACCOUNTS_ALL_TRANSACTIONS_LOADING_ERROR",
  MICRO_ACCOUNTS_ALL_TRANSACTIONS_BALANCE = "MICRO_ACCOUNTS_ALL_TRANSACTIONS_BALANCE",
  MICRO_ACCOUNTS_ALL_TRANSACTIONS_STATEMENTS = "MICRO_ACCOUNTS_ALL_TRANSACTIONS_STATEMENTS",
  MICRO_ACCOUNTS_ALL_TRANSACTIONS_SEARCH = "MICRO_ACCOUNTS_ALL_TRANSACTIONS_SEARCH",

  // account details
  MICRO_ACCOUNTS_ACH_DETAILS = "MICRO_ACCOUNTS_ACH_DETAILS",
  MICRO_ACCOUNTS_ACH_BALANCE = "MICRO_ACCOUNTS_ACH_BALANCE",
  MICRO_ACCOUNTS_ACH_ACCOUNT_NUMBER = "MICRO_ACCOUNTS_ACH_ACCOUNT_NUMBER",
  MICRO_ACCOUNTS_ACH_ROUTING_NUMBER = "MICRO_ACCOUNTS_ACH_ROUTING_NUMBER",
  MICRO_ACCOUNTS_ACH_CLIPBOARD = "MICRO_ACCOUNTS_ACH_CLIPBOARD",

  // card activation
  MICRO_ACCOUNTS_CARD_ACTIVATION_TITLE = "MICRO_ACCOUNTS_CARD_ACTIVATION_TITLE",
  MICRO_ACCOUNTS_CARD_ACTIVATION_MESSAGE = "MICRO_ACCOUNTS_CARD_ACTIVATION_MESSAGE",
  MICRO_ACCOUNTS_CARD_ACTIVATION_CTA = "MICRO_ACCOUNTS_CARD_ACTIVATION_CTA",
  MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_SHIP = "MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_SHIP",
  MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_ISSUE_ERROR = "MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_ISSUE_ERROR",
  MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_ACTIVATE = "MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_ACTIVATE",
  MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_SHIP_ERROR = "MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_SHIP_ERROR",
  MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_TERMINATED = "MICRO_ACCOUNTS_CARD_ACTIVATION_BANNER_TERMINATED",

  // card activation error keys
  MICRO_ACCOUNTS_CARD_ACTIVATION_ERROR_TITLE = "MICRO_ACCOUNTS_CARD_ACTIVATION_ERROR_TITLE",
  MICRO_ACCOUNTS_CARD_ACTIVATION_ERROR_MESSAGE = "MICRO_ACCOUNTS_CARD_ACTIVATION_ERROR_MESSAGE",
  MICRO_ACCOUNTS_CARD_ACTIVATION_ERROR_PRIMARY_CTA = "MICRO_ACCOUNTS_CARD_ACTIVATION_ERROR_PRIMARY_CTA",
  MICRO_ACCOUNTS_CARD_ACTIVATION_ERROR_SECONDARY_CTA = "MICRO_ACCOUNTS_CARD_ACTIVATION_ERROR_SECONDARY_CTA",

  // card activation pin
  MICRO_ACCOUNTS_CARD_CREATE_PIN_TITLE = "MICRO_ACCOUNTS_CARD_CREATE_PIN_TITLE",
  MICRO_ACCOUNTS_CARD_RESET_PIN_TITLE = "MICRO_ACCOUNTS_CARD_RESET_PIN_TITLE",
  MICRO_ACCOUNTS_CARD_CREATE_PIN_ACTION = "MICRO_ACCOUNTS_CARD_CREATE_PIN_ACTION",
  MICRO_ACCOUNTS_CARD_RESET_PIN_ACTION = "MICRO_ACCOUNTS_CARD_RESET_PIN_ACTION",
  MICRO_ACCOUNTS_CARD_CREATE_PIN_PROCESSING = "MICRO_ACCOUNTS_CARD_CREATE_PIN_PROCESSING",
  MICRO_ACCOUNTS_CARD_RESET_PIN_PROCESSING = "MICRO_ACCOUNTS_CARD_RESET_PIN_PROCESSING",
  MICRO_ACCOUNTS_CARD_CREATE_PIN_ERROR = "MICRO_ACCOUNTS_CARD_CREATE_PIN_ERROR",
  MICRO_ACCOUNTS_CARD_CREATE_PIN_INSTRUCTIONS = "MICRO_ACCOUNTS_CARD_CREATE_PIN_INSTRUCTIONS",
  MICRO_ACCOUNTS_CARD_CREATE_PIN_INSTRUCTIONS_2 = "MICRO_ACCOUNTS_CARD_CREATE_PIN_INSTRUCTIONS_2",
  MICRO_ACCOUNTS_CARD_CREATE_PIN_SUCCESS = "MICRO_ACCOUNTS_CARD_CREATE_PIN_SUCCESS",

  // card expiration date check
  MICRO_ACCOUNTS_CARD_EXPIRATION_TITLE = "MICRO_ACCOUNTS_CARD_EXPIRATION_TITLE",
  MICRO_ACCOUNTS_CARD_EXPIRATION_MESSAGE = "MICRO_ACCOUNTS_CARD_EXPIRATION_MESSAGE",
  MICRO_ACCOUNTS_CARD_EXPIRATION_PROCESSING = "MICRO_ACCOUNTS_CARD_EXPIRATION_PROCESSING",
  MICRO_ACCOUNTS_CARD_EXPIRATION_ERROR_1 = "MICRO_ACCOUNTS_CARD_EXPIRATION_ERROR_1",
  MICRO_ACCOUNTS_CARD_EXPIRATION_ERROR_2 = "MICRO_ACCOUNTS_CARD_EXPIRATION_ERROR_2",

  // card activation last 4 check
  MICRO_ACCOUNTS_CARD_LAST4_ACTIVATE_TITLE = "MICRO_ACCOUNTS_CARD_LAST4_ACTIVATE_TITLE",
  MICRO_ACCOUNTS_CARD_LAST4_RESET_TITLE = "MICRO_ACCOUNTS_CARD_LAST4_RESET_TITLE",
  MICRO_ACCOUNTS_CARD_LAST4_PROCESSING = "MICRO_ACCOUNTS_CARD_LAST4_PROCESSING",
  MICRO_ACCOUNTS_CARD_LAST4_ERROR_1 = "MICRO_ACCOUNTS_CARD_LAST4_ERROR_1",
  MICRO_ACCOUNTS_CARD_LAST4_ERROR_2 = "MICRO_ACCOUNTS_CARD_LAST4_ERROR_2",
  MICRO_ACCOUNTS_CARD_LAST4_MESSAGE = "MICRO_ACCOUNTS_CARD_LAST4_MESSAGE",

  // card termination ssn check
  MICRO_ACCOUNTS_CARD_TERMINATION_SSN_TITLE = "MICRO_ACCOUNTS_CARD_TERMINATION_SSN_TITLE",
  MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MESSAGE = "MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MESSAGE",
  MICRO_ACCOUNTS_CARD_TERMINATION_SSN_PROCESSING = "MICRO_ACCOUNTS_CARD_TERMINATION_SSN_PROCESSING",
  MICRO_ACCOUNTS_CARD_TERMINATION_SSN_ERROR_1 = "MICRO_ACCOUNTS_CARD_TERMINATION_SSN_ERROR_1",
  MICRO_ACCOUNTS_CARD_TERMINATION_SSN_ERROR_2 = "MICRO_ACCOUNTS_CARD_TERMINATION_SSN_ERROR_2",
  MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MODAL_TITLE = "MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MODAL_TITLE",
  MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MODAL_BODY = "MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MODAL_BODY",
  MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MODAL_DECLINE = "MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MODAL_DECLINE",
  MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MODAL_ACCEPT = "MICRO_ACCOUNTS_CARD_TERMINATION_SSN_MODAL_ACCEPT",

  // consents for spending
  MICRO_ACCOUNTS_SPENDING_CONSENTS_CTA = "MICRO_ACCOUNTS_SPENDING_CONSENTS_CTA",
  MICRO_ACCOUNTS_SPENDING_CONSENTS_DISMISS = "MICRO_ACCOUNTS_SPENDING_CONSENTS_DISMISS",
  MICRO_ACCOUNTS_SPENDING_CONSENTS_TITLE = "MICRO_ACCOUNTS_SPENDING_CONSENTS_TITLE",

  // digital/virtual card intro
  MICRO_ACCOUNTS_DIGITAL_CARD_TITLE = "MICRO_ACCOUNTS_DIGITAL_CARD_TITLE",
  MICRO_ACCOUNTS_DIGITAL_CARD_MESSAGE = "MICRO_ACCOUNTS_DIGITAL_CARD_MESSAGE",
  MICRO_ACCOUNTS_DIGITAL_CARD_CTA = "MICRO_ACCOUNTS_DIGITAL_CARD_CTA",
  MICRO_ACCOUNTS_DIGITAL_DISPLAY_TITLE = "MICRO_ACCOUNTS_DIGITAL_DISPLAY_TITLE",
  MICRO_ACCOUNTS_DIGITAL_DISPLAY_LABEL_1 = "MICRO_ACCOUNTS_DIGITAL_DISPLAY_LABEL_1",
  MICRO_ACCOUNTS_DIGITAL_DISPLAY_LABEL_2 = "MICRO_ACCOUNTS_DIGITAL_DISPLAY_LABEL_2",
  MICRO_ACCOUNTS_DIGITAL_DISPLAY_LABEL_3 = "MICRO_ACCOUNTS_DIGITAL_DISPLAY_LABEL_3",

  // find ATM
  MICRO_ACCOUNTS_FIND_ATM_TITLE = "MICRO_ACCOUNTS_FIND_ATM_TITLE",
  MICRO_ACCOUNTS_FIND_ATM_MESSAGE_1 = "MICRO_ACCOUNTS_FIND_ATM_MESSAGE_1",
  MICRO_ACCOUNTS_FIND_ATM_MESSAGE_2 = "MICRO_ACCOUNTS_FIND_ATM_MESSAGE_2",
  MICRO_ACCOUNTS_FIND_ATM_MESSAGE_3 = "MICRO_ACCOUNTS_FIND_ATM_MESSAGE_3",
  MICRO_ACCOUNTS_FIND_ATM_MESSAGE_4 = "MICRO_ACCOUNTS_FIND_ATM_MESSAGE_4",
  MICRO_ACCOUNTS_FIND_ATM_CTA = "MICRO_ACCOUNTS_FIND_ATM_CTA",

  // learn more carousel
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_TITLE = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_TITLE",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_TEXT",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_OVERLAY = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_OVERLAY",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_SUBTITLE = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_SUBTITLE",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_1_HEAD = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_1_HEAD",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_1_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_1_TEXT",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_2_HEAD = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_2_HEAD",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_2_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_2_TEXT",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_3_HEAD = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_3_HEAD",
  MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_3_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_SPENDING_SECTION_QUESTION_3_TEXT",
  MICRO_ACCOUNTS_LEARN_MORE_TRACKING_TITLE = "MICRO_ACCOUNTS_LEARN_MORE_TRACKING_TITLE",
  MICRO_ACCOUNTS_LEARN_MORE_TRACKING_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_TRACKING_TEXT",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_TITLE = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_TITLE",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_TEXT",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_OVERLAY = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_OVERLAY",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_SUBTITLE = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_SUBTITLE",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_1_HEAD = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_1_HEAD",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_1_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_1_TEXT",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_2_HEAD = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_2_HEAD",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_2_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_2_TEXT",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_3_HEAD = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_3_HEAD",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_3_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_3_TEXT",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_4_HEAD = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_4_HEAD",
  MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_4_TEXT = "MICRO_ACCOUNTS_LEARN_MORE_SAVINGS_SECTION_QUESTION_4_TEXT",

  // link paycheck direct deposit
  MICRO_ACCOUNTS_LINK_PAYCHECK_TITLE = "MICRO_ACCOUNTS_LINK_PAYCHECK_TITLE",
  MICRO_ACCOUNTS_LINK_PAYCHECK_MESSAGE_1 = "MICRO_ACCOUNTS_LINK_PAYCHECK_MESSAGE_1",
  MICRO_ACCOUNTS_LINK_PAYCHECK_MESSAGE_2 = "MICRO_ACCOUNTS_LINK_PAYCHECK_MESSAGE_2",
  MICRO_ACCOUNTS_LINK_PAYCHECK_ITEM_1 = "MICRO_ACCOUNTS_LINK_PAYCHECK_ITEM_1",
  MICRO_ACCOUNTS_LINK_PAYCHECK_ITEM_2 = "MICRO_ACCOUNTS_LINK_PAYCHECK_ITEM_2",
  MICRO_ACCOUNTS_LINK_PAYCHECK_ITEM_3 = "MICRO_ACCOUNTS_LINK_PAYCHECK_ITEM_3",
  MICRO_ACCOUNTS_LINK_PAYCHECK_ACCOUNT_LABEL = "MICRO_ACCOUNTS_LINK_PAYCHECK_ACCOUNT_LABEL",
  MICRO_ACCOUNTS_LINK_PAYCHECK_ROUTING_LABEL = "MICRO_ACCOUNTS_LINK_PAYCHECK_ROUTING_LABEL",

  // lock/unlock card
  MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_LOCK_ACTION = "MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_LOCK_ACTION",
  MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_UNLOCK_ACTION = "MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_UNLOCK_ACTION",
  MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_TITLE_CARD = "MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_TITLE_CARD",
  MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_TOGGLE_CARD = "MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_TOGGLE_CARD",
  MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_INSTRUCTIONS_1 = "MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_INSTRUCTIONS_1",
  MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_INSTRUCTIONS_2 = "MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_INSTRUCTIONS_2",
  MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_BULLET_1 = "MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_BULLET_1",
  MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_BULLET_2 = "MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_BULLET_2",
  MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_CTA = "MICRO_ACCOUNTS_CARD_LOCK_UNLOCK_CTA",

  // lost card
  MICRO_ACCOUNTS_CARD_LOST_TITLE = "MICRO_ACCOUNTS_CARD_LOST_TITLE",
  MICRO_ACCOUNTS_CARD_LOST_INSTRUCTIONS_1 = "MICRO_ACCOUNTS_CARD_LOST_INSTRUCTIONS_1",
  MICRO_ACCOUNTS_CARD_LOST_INSTRUCTIONS_2 = "MICRO_ACCOUNTS_CARD_LOST_INSTRUCTIONS_2",
  MICRO_ACCOUNTS_CARD_LOST_LOCK_LINK = "MICRO_ACCOUNTS_CARD_LOST_LOCK_LINK",
  MICRO_ACCOUNTS_CARD_LOST_CTA = "MICRO_ACCOUNTS_CARD_LOST_CTA",

  // report transaction issue
  MICRO_ACCOUNTS_REPORT_ISSUE_TITLE = "MICRO_ACCOUNTS_REPORT_ISSUE_TITLE",
  MICRO_ACCOUNTS_REPORT_ISSUE_TX_MERCHANT = "MICRO_ACCOUNTS_REPORT_ISSUE_TX_MERCHANT",
  MICRO_ACCOUNTS_REPORT_ISSUE_TX_DATE = "MICRO_ACCOUNTS_REPORT_ISSUE_TX_DATE",
  MICRO_ACCOUNTS_REPORT_ISSUE_TX_DATE_ERROR = "MICRO_ACCOUNTS_REPORT_ISSUE_TX_DATE_ERROR",
  MICRO_ACCOUNTS_REPORT_ISSUE_TX_AMOUNT = "MICRO_ACCOUNTS_REPORT_ISSUE_TX_AMOUNT",
  MICRO_ACCOUNTS_REPORT_ISSUE_TX_AMOUNT_ERROR = "MICRO_ACCOUNTS_REPORT_ISSUE_TX_AMOUNT_ERROR",
  MICRO_ACCOUNTS_REPORT_ISSUE_TX_DETAILS = "MICRO_ACCOUNTS_REPORT_ISSUE_TX_DETAILS",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_SELECT = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_SELECT",
  MICRO_ACCOUNTS_REPORT_ISSUE_CTA = "MICRO_ACCOUNTS_REPORT_ISSUE_CTA",
  MICRO_ACCOUNTS_REPORT_ISSUE_LOADING = "MICRO_ACCOUNTS_REPORT_ISSUE_LOADING",
  MICRO_ACCOUNTS_REPORT_ISSUE_PROCESSING = "MICRO_ACCOUNTS_REPORT_ISSUE_PROCESSING",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_PLACEHOLDER = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_PLACEHOLDER",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_1 = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_1",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_2 = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_2",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_3 = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_3",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_CANCEL = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_MENU_CANCEL",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_AMOUNT_CORRECTION = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_AMOUNT_CORRECTION",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_PLACEHOLDER = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_PLACEHOLDER",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_MENU_1 = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_MENU_1",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_MENU_2 = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_MENU_2",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_MENU_CANCEL = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_MENU_CANCEL",
  MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_LOST_DATE = "MICRO_ACCOUNTS_REPORT_ISSUE_ACTION_CARD_POSSESSION_LOST_DATE",

  // ship card confirm address
  MICRO_ACCOUNTS_SHIP_CARD_TITLE = "MICRO_ACCOUNTS_SHIP_CARD_TITLE",
  MICRO_ACCOUNTS_SHIP_CARD_TOAST = "MICRO_ACCOUNTS_SHIP_CARD_TOAST",
  MICRO_ACCOUNTS_SHIP_CARD_MESSAGE_1 = "MICRO_ACCOUNTS_SHIP_CARD_MESSAGE_1",
  MICRO_ACCOUNTS_SHIP_CARD_MESSAGE_2 = "MICRO_ACCOUNTS_SHIP_CARD_MESSAGE_2",
  MICRO_ACCOUNTS_SHIP_CARD_ADDRESS_LABEL = "MICRO_ACCOUNTS_SHIP_CARD_ADDRESS_LABEL",
  MICRO_ACCOUNTS_SHIP_CARD_EDIT_LINK = "MICRO_ACCOUNTS_SHIP_CARD_EDIT_LINK",
  MICRO_ACCOUNTS_SHIP_CARD_CTA = "MICRO_ACCOUNTS_SHIP_CARD_CTA",
  MICRO_ACCOUNTS_SHIP_CARD_LOADING = "MICRO_ACCOUNTS_SHIP_CARD_LOADING",
  MICRO_ACCOUNTS_SHIP_CARD_PROCESSING = "MICRO_ACCOUNTS_SHIP_CARD_PROCESSING",
  MICRO_ACCOUNTS_SHIP_CARD_ERROR = "MICRO_ACCOUNTS_SHIP_CARD_ERROR",

  // ship card mailing address
  MICRO_ACCOUNTS_CARD_ADDRESS_TITLE = "MICRO_ACCOUNTS_CARD_ADDRESS_TITLE",
  MICRO_ACCOUNTS_CARD_ADDRESS_STREET_LABEL = "MICRO_ACCOUNTS_CARD_ADDRESS_STREET_LABEL",
  MICRO_ACCOUNTS_CARD_ADDRESS_STREET_2_LABEL = "MICRO_ACCOUNTS_CARD_ADDRESS_STREET_2_LABEL",
  MICRO_ACCOUNTS_CARD_ADDRESS_CITY_LABEL = "MICRO_ACCOUNTS_CARD_ADDRESS_CITY_LABEL",
  MICRO_ACCOUNTS_CARD_ADDRESS_STATE_LABEL = "MICRO_ACCOUNTS_CARD_ADDRESS_STATE_LABEL",
  MICRO_ACCOUNTS_CARD_ADDRESS_ZIP_LABEL = "MICRO_ACCOUNTS_CARD_ADDRESS_ZIP_LABEL",
  MICRO_ACCOUNTS_CARD_ADDRESS_CTA = "MICRO_ACCOUNTS_CARD_ADDRESS_CTA",
  MICRO_ACCOUNTS_CARD_ADDRESS_PROCESSING = "MICRO_ACCOUNTS_CARD_ADDRESS_PROCESSING",
  MICRO_ACCOUNTS_CARD_ADDRESS_ERROR = "MICRO_ACCOUNTS_CARD_ADDRESS_ERROR",

  // ship card congrats
  MICRO_ACCOUNTS_CARD_READY_TITLE = "MICRO_ACCOUNTS_CARD_READY_TITLE",
  MICRO_ACCOUNTS_CARD_READY_BODY = "MICRO_ACCOUNTS_CARD_READY_BODY",
  MICRO_ACCOUNTS_CARD_READY_CTA = "MICRO_ACCOUNTS_CARD_READY_CTA",
  MICRO_ACCOUNTS_CARD_READY_SECONDARY_CTA = "MICRO_ACCOUNTS_CARD_READY_SECONDARY_CTA",

  // account statements
  MICRO_ACCOUNTS_STATEMENTS_TITLE = "MICRO_ACCOUNTS_STATEMENTS_TITLE",
  MICRO_ACCOUNTS_STATEMENTS_NONE = "MICRO_ACCOUNTS_STATEMENTS_NONE",

  // support
  MICRO_ACCOUNTS_SUPPORT_TITLE = "MICRO_ACCOUNTS_SUPPORT_TITLE",
  MICRO_ACCOUNTS_SUPPORT_MESSAGE = "MICRO_ACCOUNTS_SUPPORT_MESSAGE",
  MICRO_ACCOUNTS_SUPPORT_FOOTER = "MICRO_ACCOUNTS_SUPPORT_FOOTER",
  MICRO_ACCOUNTS_SUPPORT_FOOTER_CALL = "MICRO_ACCOUNTS_SUPPORT_FOOTER_CALL",
  MICRO_ACCOUNTS_SUPPORT_CHAT_LABEL = "MICRO_ACCOUNTS_SUPPORT_CHAT_LABEL",
  MICRO_ACCOUNTS_SUPPORT_CALL_LABEL = "MICRO_ACCOUNTS_SUPPORT_CALL_LABEL",

  // transaction details
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_TITLE = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_TITLE",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_NO_MERCHANT = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_NO_MERCHANT",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_AMOUNT = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_AMOUNT",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_DATE = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_DATE",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_STATUS = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_STATUS",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_TYPE = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_TYPE",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_ISSUE_HEADLINE = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_ISSUE_HEADLINE",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_ISSUE_MESSAGE = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_ISSUE_MESSAGE",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_REPORT_LINK = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_REPORT_LINK",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_UNAUTHORIZED = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_UNAUTHORIZED",
  MICRO_ACCOUNTS_TRANSACTION_DETAILS_SHIP_CTA = "MICRO_ACCOUNTS_TRANSACTION_DETAILS_SHIP_CTA",

  // this section for keys reused across multiple account pages (or across both savings & spending)
  // high-level
  MICRO_ACCOUNTS_SAVINGS = "MICRO_ACCOUNTS_SAVINGS",
  MICRO_ACCOUNTS_SAVINGS_PRODUCT_NAME = "MICRO_ACCOUNTS_SAVINGS_PRODUCT_NAME",
  MICRO_ACCOUNTS_SPENDING = "MICRO_ACCOUNTS_SPENDING",
  MICRO_ACCOUNTS_SPENDING_PRODUCT_NAME = "MICRO_ACCOUNTS_SPENDING_PRODUCT_NAME",
  MICRO_ACCOUNTS_TRANSFER = "MICRO_ACCOUNTS_TRANSFER",
  MICRO_ACCOUNTS_WITHDRAW = "MICRO_ACCOUNTS_WITHDRAW",
  MICRO_ACCOUNTS_TODAY = "MICRO_ACCOUNTS_TODAY",
  MICRO_ACCOUNTS_PENDING = "MICRO_ACCOUNTS_PENDING",
  MICRO_ACCOUNTS_COMPLETE = "MICRO_ACCOUNTS_COMPLETE",
  // footer
  MICRO_ACCOUNTS_SHARED_BANKING_SERVICES_FOOTER = "MICRO_ACCOUNTS_SHARED_BANKING_SERVICES_FOOTER",
  // consents
  MICRO_ACCOUNTS_CONSENTS_ACCEPT = "MICRO_ACCOUNTS_CONSENTS_ACCEPT",
  MICRO_ACCOUNTS_CONSENTS_INSTRUCTIONS = "MICRO_ACCOUNTS_CONSENTS_INSTRUCTIONS",
  MICRO_ACCOUNTS_CONSENTS_DEPOSIT_PRE_LINK = "MICRO_ACCOUNTS_CONSENTS_DEPOSIT_PRE_LINK",
  MICRO_ACCOUNTS_CONSENTS_DEPOSIT_LINK = "MICRO_ACCOUNTS_CONSENTS_DEPOSIT_LINK",
  MICRO_ACCOUNTS_CONSENTS_CARDHOLDER_PRE_LINK = "MICRO_ACCOUNTS_CONSENTS_CARDHOLDER_PRE_LINK",
  MICRO_ACCOUNTS_CONSENTS_CARDHOLDER_LINK = "MICRO_ACCOUNTS_CONSENTS_CARDHOLDER_LINK",
  MICRO_ACCOUNTS_CONSENTS_CUSTODIAL_PRE_LINK = "MICRO_ACCOUNTS_CONSENTS_CUSTODIAL_PRE_LINK",
  MICRO_ACCOUNTS_CONSENTS_CUSTODIAL_LINK = "MICRO_ACCOUNTS_CONSENTS_CUSTODIAL_LINK",
  MICRO_ACCOUNTS_CONSENTS_CUSTODIAL_POST_LINK = "MICRO_ACCOUNTS_CONSENTS_CUSTODIAL_POST_LINK",
  MICRO_ACCOUNTS_CONSENTS_PAYROLL_PRE_LINK = "MICRO_ACCOUNTS_CONSENTS_PAYROLL_PRE_LINK",
  MICRO_ACCOUNTS_CONSENTS_PAYROLL_LINK = "MICRO_ACCOUNTS_CONSENTS_PAYROLL_LINK",
  MICRO_ACCOUNTS_CONSENTS_WITHDRAWAL_PRE_LINK = "MICRO_ACCOUNTS_CONSENTS_WITHDRAWAL_PRE_LINK",
  MICRO_ACCOUNTS_CONSENTS_KICKFORWARD_PRE_LINK = "MICRO_ACCOUNTS_CONSENTS_KICKFORWARD_PRE_LINK",
  MICRO_ACCOUNTS_CONSENTS_KICKFORWARD_LINK = "MICRO_ACCOUNTS_CONSENTS_KICKFORWARD_LINK",
  MICRO_ACCOUNTS_CONSENTS_KICKFORWARD_POST_LINK = "MICRO_ACCOUNTS_CONSENTS_KICKFORWARD_POST_LINK",
  MICRO_ACCOUNTS_CONSENTS_CASHADVANCE_PRE_LINK = "MICRO_ACCOUNTS_CONSENTS_CASHADVANCE_PRE_LINK",
  MICRO_ACCOUNTS_CONSENTS_CASHADVANCE_LINK = "MICRO_ACCOUNTS_CONSENTS_CASHADVANCE_LINK",

  // transaction
  MICRO_ACCOUNTS_TRANSACTION_ARRIVAL = "MICRO_ACCOUNTS_TRANSACTION_ARRIVAL",
  MICRO_ACCOUNTS_TRANSACTION_PAYCHECK = "MICRO_ACCOUNTS_TRANSACTION_PAYCHECK",
  MICRO_ACCOUNTS_TRANSACTION_NAME_1 = "MICRO_ACCOUNTS_TRANSACTION_NAME_1",
  MICRO_ACCOUNTS_TRANSACTION_NAME_TO = "MICRO_ACCOUNTS_TRANSACTION_NAME_TO",
  MICRO_ACCOUNTS_TRANSACTION_NAME_FROM = "MICRO_ACCOUNTS_TRANSACTION_NAME_FROM",
  MICRO_ACCOUNTS_TRANSACTION_NAME_2 = "MICRO_ACCOUNTS_TRANSACTION_NAME_2",
  // transactions
  MICRO_ACCOUNTS_RECENT_TRANSACTIONS_NONE = "MICRO_ACCOUNTS_RECENT_TRANSACTIONS_NONE",
  MICRO_ACCOUNTS_RECENT_TRANSACTIONS_TITLE = "MICRO_ACCOUNTS_RECENT_TRANSACTIONS_TITLE",
  MICRO_ACCOUNTS_RECENT_TRANSACTIONS_ALL = "MICRO_ACCOUNTS_RECENT_TRANSACTIONS_ALL",

  // manage accounts
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_EMPTY_CTA = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_EMPTY_CTA",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_EMPTY_TITLE = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_EMPTY_TITLE",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_EMPTY_MESSAGE = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_EMPTY_MESSAGE",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_TITLE = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_TITLE",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_MAX_MODAL_TITLE = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_MAX_MODAL_TITLE",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_MAX_MODAL_BODY = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_MAX_MODAL_BODY",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_MAX_MODAL_CTA = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_MAX_MODAL_CTA",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_TOAST_DELETED = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_TOAST_DELETED",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_TOAST_UPDATED = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_TOAST_UPDATED",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_CTA = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_CTA",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_FUNDS = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_FUNDS",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_BALANCE = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_BALANCE",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_HALF_CELL_1_TITLE = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_HALF_CELL_1_TITLE",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_HALF_CELL_2_TITLE = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_HALF_CELL_2_TITLE",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_HALF_TITLE = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_HALF_TITLE",
  MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_HALF_MESSAGE = "MICRO_ACCOUNTS_MANAGE_ACCOUNTS_LINK_TYPE_HALF_MESSAGE",

  // linking banks
  MICRO_ACCOUNTS_LINK_ACCOUNT_TITLE = "MICRO_ACCOUNTS_LINK_ACCOUNT_TITLE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_BODY = "MICRO_ACCOUNTS_LINK_ACCOUNT_BODY",
  MICRO_ACCOUNTS_LINK_ACCOUNT_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_CTA",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ROUTING_LABEL = "MICRO_ACCOUNTS_LINK_ACCOUNT_ROUTING_LABEL",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ROUTING_ERROR = "MICRO_ACCOUNTS_LINK_ACCOUNT_ROUTING_ERROR",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_LABEL = "MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_LABEL",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_ERROR = "MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_ERROR",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_CONFIRM_LABEL = "MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_CONFIRM_LABEL",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_CONFIRM_ERROR = "MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_CONFIRM_ERROR",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_CONFIRM_MISMATCH = "MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_CONFIRM_MISMATCH",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_LABEL = "MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_LABEL",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_PLACEHOLDER = "MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_PLACEHOLDER",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_CHECKING = "MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_CHECKING",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_SAVINGS = "MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_SAVINGS",
  // linking banners
  MICRO_ACCOUNTS_LINK_ACCOUNT_BANNER_ROUTING_ERROR = "MICRO_ACCOUNTS_LINK_ACCOUNT_BANNER_ROUTING_ERROR",
  MICRO_ACCOUNTS_LINK_ACCOUNT_BANNER_RESEND_DEPOSITS = "MICRO_ACCOUNTS_LINK_ACCOUNT_BANNER_RESEND_DEPOSITS",
  MICRO_ACCOUNTS_LINK_ACCOUNT_BANNER_VERIFY_DEPOSITS = "MICRO_ACCOUNTS_LINK_ACCOUNT_BANNER_VERIFY_DEPOSITS",
  // linked account success
  MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_TITLE = "MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_TITLE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_MESSAGE = "MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_MESSAGE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_CTA",
  MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_ITEM_1 = "MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_ITEM_1",
  MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_ITEM_2 = "MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_ITEM_2",
  MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_ITEM_3 = "MICRO_ACCOUNTS_LINK_ACCOUNT_SUCCESS_ITEM_3",
  // linked account error
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_ANOTHER_TITLE = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_ANOTHER_TITLE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_ANOTHER_MESSAGE = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_ANOTHER_MESSAGE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_ANOTHER_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_ANOTHER_CTA",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_ANOTHER_SECONDARY_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_ANOTHER_SECONDARY_CTA",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_UNABLE_TITLE = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_UNABLE_TITLE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_UNABLE_MESSAGE = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_UNABLE_MESSAGE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_UNABLE_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_UNABLE_CTA",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_UNABLE_SECONDARY_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_UNABLE_SECONDARY_CTA",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_TITLE_1 = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_TITLE_1",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_TITLE_2 = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_TITLE_2",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MESSAGE = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MESSAGE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_CTA",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_SECONDARY_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_SECONDARY_CTA",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MODAL_TITLE = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MODAL_TITLE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MODAL_MESSAGE = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MODAL_MESSAGE",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MODAL_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MODAL_CTA",
  MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MODAL_SECONDARY_CTA = "MICRO_ACCOUNTS_LINK_ACCOUNT_ERROR_RESEND_MODAL_SECONDARY_CTA",
  // verify linked account next steps
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_WAITING_TITLE = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_WAITING_TITLE",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_WAITING_CTA = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_WAITING_CTA",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_MESSAGE = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_MESSAGE",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_RESEND_MESSAGE = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_RESEND_MESSAGE",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_TITLE = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_TITLE",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_VERIFY_CTA = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_VERIFY_CTA",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_VERIFY_SECONDARY_CTA = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_VERIFY_SECONDARY_CTA",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_1_RESEND_LABEL = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_1_RESEND_LABEL",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_1_LABEL = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_1_LABEL",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_2_LABEL = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_2_LABEL",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_2_TEXT = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_2_TEXT",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_3_LABEL = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_3_LABEL",
  MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_3_TEXT = "MICRO_ACCOUNTS_VERIFY_ACCOUNT_NEXT_STEPS_STEP_3_TEXT",
  // verify linked account form
  MICRO_ACCOUNTS_VERIFY_AMOUNT_LABEL = "MICRO_ACCOUNTS_VERIFY_AMOUNT_LABEL",
  MICRO_ACCOUNTS_VERIFY_AMOUNT_ERROR = "MICRO_ACCOUNTS_VERIFY_AMOUNT_ERROR",
  MICRO_ACCOUNTS_VERIFY_AMOUNT_TITLE = "MICRO_ACCOUNTS_VERIFY_AMOUNT_TITLE",
  MICRO_ACCOUNTS_VERIFY_AMOUNT_CTA = "MICRO_ACCOUNTS_VERIFY_AMOUNT_CTA",
  MICRO_ACCOUNTS_VERIFY_INSTRUCTIONS = "MICRO_ACCOUNTS_VERIFY_INSTRUCTIONS",
  MICRO_ACCOUNTS_VERIFY_INSTRUCTIONS_ERROR = "MICRO_ACCOUNTS_VERIFY_INSTRUCTIONS_ERROR",
  MICRO_ACCOUNTS_VERIFY_INSTRUCTIONS_ATTEMPTS = "MICRO_ACCOUNTS_VERIFY_INSTRUCTIONS_ATTEMPTS",
  // linked bank details
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_BANK_LABEL = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_BANK_LABEL",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_ACCOUNT_NUMBER_LABEL = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_ACCOUNT_NUMBER_LABEL",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_ACCOUNT_TYPE_LABEL = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_ACCOUNT_TYPE_LABEL",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_ACCOUNT_METHOD_LABEL = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_ACCOUNT_METHOD_LABEL",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_TITLE = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_TITLE",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_CTA = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_CTA",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_VERIFY_CAPTION = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_VERIFY_CAPTION",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_VERIFY_CELL = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_VERIFY_CELL",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_RETRY_CELL = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_RETRY_CELL",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_PENDING_MODAL_TITLE = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_PENDING_MODAL_TITLE",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_PENDING_MODAL_MESSAGE = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_PENDING_MODAL_MESSAGE",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_PENDING_MODAL_CTA = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_PENDING_MODAL_CTA",
  MICRO_ACCOUNTS_LINKED_BANK_DISCONNECT_TITLE = "MICRO_ACCOUNTS_LINKED_BANK_DISCONNECT_TITLE",
  MICRO_ACCOUNTS_LINKED_BANK_DISCONNECT_MESSAGE = "MICRO_ACCOUNTS_LINKED_BANK_DISCONNECT_MESSAGE",
  MICRO_ACCOUNTS_LINKED_BANK_DISCONNECT_CTA = "MICRO_ACCOUNTS_LINKED_BANK_DISCONNECT_CTA",
  MICRO_ACCOUNTS_LINKED_BANK_DISCONNECT_SECONDARY_CTA = "MICRO_ACCOUNTS_LINKED_BANK_DISCONNECT_SECONDARY_CTA",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_BALANCE = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_BALANCE",
  MICRO_ACCOUNTS_LINKED_BANK_DETAILS_WITHDRAW = "MICRO_ACCOUNTS_LINKED_BANK_DETAILS_WITHDRAW",

  // resend microdeposits
  MICRO_ACCOUNTS_RESEND_DEPOSIT_TITLE = "MICRO_ACCOUNTS_RESEND_DEPOSIT_TITLE",
  MICRO_ACCOUNTS_RESEND_DEPOSIT_MESSAGE_1 = "MICRO_ACCOUNTS_RESEND_DEPOSIT_MESSAGE_1",
  MICRO_ACCOUNTS_RESEND_DEPOSIT_MESSAGE_2 = "MICRO_ACCOUNTS_RESEND_DEPOSIT_MESSAGE_2",
  MICRO_ACCOUNTS_RESEND_DEPOSIT_CTA = "MICRO_ACCOUNTS_RESEND_DEPOSIT_CTA",
  MICRO_ACCOUNTS_RESEND_DEPOSIT_SECONDARY_CTA = "MICRO_ACCOUNTS_RESEND_DEPOSIT_SECONDARY_CTA",
  MICRO_ACCOUNTS_RESEND_DEPOSIT_CONFIRM_MODAL_TITLE = "MICRO_ACCOUNTS_RESEND_DEPOSIT_CONFIRM_MODAL_TITLE",
  MICRO_ACCOUNTS_RESEND_DEPOSIT_CONFIRM_MODAL_MESSAGE = "MICRO_ACCOUNTS_RESEND_DEPOSIT_CONFIRM_MODAL_MESSAGE",
  MICRO_ACCOUNTS_RESEND_DEPOSIT_CONFIRM_MODAL_CTA = "MICRO_ACCOUNTS_RESEND_DEPOSIT_CONFIRM_MODAL_CTA",
  MICRO_ACCOUNTS_RESEND_DEPOSIT_CONFIRM_MODAL_SECONDARY_CTA = "MICRO_ACCOUNTS_RESEND_DEPOSIT_CONFIRM_MODAL_SECONDARY_CTA",

  // transfer funds
  MICRO_ACCOUNTS_TRANSFER_TITLE = "MICRO_ACCOUNTS_TRANSFER_TITLE",
  MICRO_ACCOUNTS_TRANSFER_ACH_OVERDRAFT = "MICRO_ACCOUNTS_TRANSFER_ACH_OVERDRAFT",
  MICRO_ACCOUNTS_TRANSFER_FROM_LABEL = "MICRO_ACCOUNTS_TRANSFER_FROM_LABEL",
  MICRO_ACCOUNTS_TRANSFER_FROM_PLACEHOLDER = "MICRO_ACCOUNTS_TRANSFER_FROM_PLACEHOLDER",
  MICRO_ACCOUNTS_TRANSFER_TO_LABEL = "MICRO_ACCOUNTS_TRANSFER_TO_LABEL",
  MICRO_ACCOUNTS_TRANSFER_TO_PLACEHOLDER = "MICRO_ACCOUNTS_TRANSFER_TO_PLACEHOLDER",
  MICRO_ACCOUNTS_TRANSFER_DATE_LABEL = "MICRO_ACCOUNTS_TRANSFER_DATE_LABEL",
  MICRO_ACCOUNTS_TRANSFER_REASON_LABEL = "MICRO_ACCOUNTS_TRANSFER_REASON_LABEL",
  MICRO_ACCOUNTS_TRANSFER_REASON_PLACEHOLDER = "MICRO_ACCOUNTS_TRANSFER_REASON_PLACEHOLDER",
  MICRO_ACCOUNTS_TRANSFER_REASON_LINK = "MICRO_ACCOUNTS_TRANSFER_REASON_LINK",
  MICRO_ACCOUNTS_TRANSFER_AMOUNT_LABEL = "MICRO_ACCOUNTS_TRANSFER_AMOUNT_LABEL",
  MICRO_ACCOUNTS_TRANSFER_AMOUNT_ERROR_DEFAULT = "MICRO_ACCOUNTS_TRANSFER_AMOUNT_ERROR_DEFAULT",
  MICRO_ACCOUNTS_TRANSFER_AMOUNT_ERROR_OVERDRAFT = "MICRO_ACCOUNTS_TRANSFER_AMOUNT_ERROR_OVERDRAFT",
  MICRO_ACCOUNTS_TRANSFER_CTA = "MICRO_ACCOUNTS_TRANSFER_CTA",
  MICRO_ACCOUNTS_TRANSFER_SELECT_ACCOUNT_TRAY_TITLE = "MICRO_ACCOUNTS_TRANSFER_SELECT_ACCOUNT_TRAY_TITLE",
  MICRO_ACCOUNTS_TRANSFER_SELECT_ACCOUNT_TRAY_LINK = "MICRO_ACCOUNTS_TRANSFER_SELECT_ACCOUNT_TRAY_LINK",
  MICRO_ACCOUNTS_TRANSFER_SELECT_REASON_TRAY_TITLE = "MICRO_ACCOUNTS_TRANSFER_SELECT_REASON_TRAY_TITLE",
  MICRO_ACCOUNTS_TRANSFER_MAX_MODAL_TITLE = "MICRO_ACCOUNTS_TRANSFER_MAX_MODAL_TITLE",
  MICRO_ACCOUNTS_TRANSFER_MAX_MODAL_MESSAGE = "MICRO_ACCOUNTS_TRANSFER_MAX_MODAL_MESSAGE",
  MICRO_ACCOUNTS_TRANSFER_MAX_MODAL_ACCEPT = "MICRO_ACCOUNTS_TRANSFER_MAX_MODAL_ACCEPT",
  MICRO_ACCOUNTS_TRANSFER_ACH_MSG = "MICRO_ACCOUNTS_TRANSFER_ACH_MSG",
  MICRO_ACCOUNTS_TRANSFER_ACH_DEBIT_MSG = "MICRO_ACCOUNTS_TRANSFER_ACH_DEBIT_MSG",
  MICRO_ACCOUNTS_TRANSFER_SAVINGS_MSG = "MICRO_ACCOUNTS_TRANSFER_SAVINGS_MSG",
  MICRO_ACCOUNTS_TRANSFER_COMPANY_SAVINGS_MSG = "MICRO_ACCOUNTS_TRANSFER_COMPANY_SAVINGS_MSG",
  MICRO_ACCOUNTS_TRANSFER_COMPANY_SAVINGS_DEBIT_MSG = "MICRO_ACCOUNTS_TRANSFER_COMPANY_SAVINGS_DEBIT_MSG",
  MICRO_ACCOUNTS_TRANSFER_SPSAV_MSG = "MICRO_ACCOUNTS_TRANSFER_SPSAV_MSG",
  MICRO_ACCOUNTS_TRANSFER_CONFIRM_MODAL_TITLE = "MICRO_ACCOUNTS_TRANSFER_CONFIRM_MODAL_TITLE",
  MICRO_ACCOUNTS_TRANSFER_CONFIRM_MODAL_CTA = "MICRO_ACCOUNTS_TRANSFER_CONFIRM_MODAL_CTA",
  MICRO_ACCOUNTS_TRANSFER_CONFIRM_MODAL_SECONDARY_CTA = "MICRO_ACCOUNTS_TRANSFER_CONFIRM_MODAL_SECONDARY_CTA",
  MICRO_ACCOUNTS_TRANSFER_CELL_FROM_LABEL = "MICRO_ACCOUNTS_TRANSFER_CELL_FROM_LABEL",
  MICRO_ACCOUNTS_TRANSFER_CELL_TO_LABEL = "MICRO_ACCOUNTS_TRANSFER_CELL_TO_LABEL",
  MICRO_ACCOUNTS_TRANSFER_CELL_AMOUNT_LABEL = "MICRO_ACCOUNTS_TRANSFER_CELL_AMOUNT_LABEL",
  MICRO_ACCOUNTS_TRANSFER_CELL_ARRIVAL_LABEL = "MICRO_ACCOUNTS_TRANSFER_CELL_ARRIVAL_LABEL",
  MICRO_ACCOUNTS_TRANSFER_COMPLETE_TOAST = "MICRO_ACCOUNTS_TRANSFER_COMPLETE_TOAST",
  MICRO_ACCOUNTS_TRANSFER_SPENDING_EMPTY = "MICRO_ACCOUNTS_TRANSFER_SPENDING_EMPTY",
  MICRO_ACCOUNTS_TRANSFER_SAVINGS_EMPTY = "MICRO_ACCOUNTS_TRANSFER_SAVINGS_EMPTY",
  MICRO_ACCOUNTS_TRANSFER_SAVINGS_LIMIT = "MICRO_ACCOUNTS_TRANSFER_SAVINGS_LIMIT",
  MICRO_ACCOUNTS_TRANSFER_REASON_MODAL_TITLE = "MICRO_ACCOUNTS_TRANSFER_REASON_MODAL_TITLE",
  MICRO_ACCOUNTS_TRANSFER_REASON_MODAL_MESSAGE = "MICRO_ACCOUNTS_TRANSFER_REASON_MODAL_MESSAGE",
  MICRO_ACCOUNTS_TRANSFER_REASON_MODAL_CTA = "MICRO_ACCOUNTS_TRANSFER_REASON_MODAL_CTA",
  MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_UNEXPECTED = "MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_UNEXPECTED",
  MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_OVERDUE = "MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_OVERDUE",
  MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_DEBT = "MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_DEBT",
  MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_NORMAL = "MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_NORMAL",
  MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_OTHER = "MICRO_ACCOUNTS_SAVINGS_TRANSFER_REASON_OTHER",
  // transfer funds error
  MICRO_ACCOUNTS_TRANSFER_ERROR_TITLE = "MICRO_ACCOUNTS_TRANSFER_ERROR_TITLE",
  MICRO_ACCOUNTS_TRANSFER_ERROR_MESSAGE = "MICRO_ACCOUNTS_TRANSFER_ERROR_MESSAGE",
  MICRO_ACCOUNTS_TRANSFER_ERROR_CTA = "MICRO_ACCOUNTS_TRANSFER_ERROR_CTA",
  MICRO_ACCOUNTS_TRANSFER_ERROR_SECONDARY_CTA = "MICRO_ACCOUNTS_TRANSFER_ERROR_SECONDARY_CTA",

  // this section for cash advance (separate feature?)
  // cash advance dashboard
  MICRO_CASH_ADVANCE_DASHBOARD_PAYDAY_TITLE = "MICRO_CASH_ADVANCE_DASHBOARD_PAYDAY_TITLE",
  MICRO_CASH_ADVANCE_DASHBOARD_FUNDS_TITLE = "MICRO_CASH_ADVANCE_DASHBOARD_FUNDS_TITLE",
  MICRO_CASH_ADVANCE_DASHBOARD_FUNDS_DESC = "MICRO_CASH_ADVANCE_DASHBOARD_FUNDS_DESC",
  MICRO_CASH_ADVANCE_DASHBOARD_DEDUCTION_TITLE = "MICRO_CASH_ADVANCE_DASHBOARD_DEDUCTION_TITLE",
  MICRO_CASH_ADVANCE_DASHBOARD_HISTORY_TITLE = "MICRO_CASH_ADVANCE_DASHBOARD_HISTORY_TITLE",
  MICRO_CASH_ADVANCE_DASHBOARD_PAGE_TITLE = "MICRO_CASH_ADVANCE_DASHBOARD_PAGE_TITLE",
  MICRO_CASH_ADVANCE_DASHBOARD_PAGE_ERROR = "MICRO_CASH_ADVANCE_DASHBOARD_PAGE_ERROR",
  MICRO_CASH_ADVANCE_DASHBOARD_LABEL_PAYDAY_TODAY = "MICRO_CASH_ADVANCE_DASHBOARD_LABEL_PAYDAY_TODAY",
  MICRO_CASH_ADVANCE_DASHBOARD_LABEL_PAYDAY_FUTURE_0 = "MICRO_CASH_ADVANCE_DASHBOARD_LABEL_PAYDAY_FUTURE_0",
  MICRO_CASH_ADVANCE_DASHBOARD_LABEL_PAYDAY_FUTURE_1 = "MICRO_CASH_ADVANCE_DASHBOARD_LABEL_PAYDAY_FUTURE_1",
  MICRO_CASH_ADVANCE_DASHBOARD_LABEL_PAYDAY_LAST = "MICRO_CASH_ADVANCE_DASHBOARD_LABEL_PAYDAY_LAST",
  MICRO_CASH_ADVANCE_DASHBOARD_LABEL_DEDUCTION_NOTICE_0 = "MICRO_CASH_ADVANCE_DASHBOARD_LABEL_DEDUCTION_NOTICE_0",
  MICRO_CASH_ADVANCE_DASHBOARD_LABEL_DEDUCTION_NOTICE_1 = "MICRO_CASH_ADVANCE_DASHBOARD_LABEL_DEDUCTION_NOTICE_1",
  MICRO_CASH_ADVANCE_DASHBOARD_LABEL_DEDUCTION_NOTICE_NEXT = "MICRO_CASH_ADVANCE_DASHBOARD_LABEL_DEDUCTION_NOTICE_NEXT",
  MICRO_CASH_ADVANCE_DASHBOARD_ACTION_LABEL = "MICRO_CASH_ADVANCE_DASHBOARD_ACTION_LABEL",
  MICRO_CASH_ADVANCE_DASHBOARD_ACTION_LABEL_CANCELED = "MICRO_CASH_ADVANCE_DASHBOARD_ACTION_LABEL_CANCELED",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CONFIRM_TITLE = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CONFIRM_TITLE",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CONFIRM_BODY = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CONFIRM_BODY",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CONFIRM_CTA_DECLINE = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CONFIRM_CTA_DECLINE",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CANCELED_TITLE = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CANCELED_TITLE",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CANCELED_BODY = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CANCELED_BODY",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CANCELED_CTA_DECLINE = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CANCELED_CTA_DECLINE",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CANCELED_CTA_ACCEPT = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_CANCELED_CTA_ACCEPT",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_REENABLE_TITLE = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_REENABLE_TITLE",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_REENABLE_BODY = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_REENABLE_BODY",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_REENABLE_CTA_DECLINE = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_REENABLE_CTA_DECLINE",
  MICRO_CASH_ADVANCE_DASHBOARD_MODAL_REENABLE_CTA_ACCEPT = "MICRO_CASH_ADVANCE_DASHBOARD_MODAL_REENABLE_CTA_ACCEPT",
  MICRO_CASH_ADVANCE_DASHBOARD_PENDING_ADVANCE_BANNER = "MICRO_CASH_ADVANCE_DASHBOARD_PENDING_ADVANCE_BANNER",
  MICRO_CASH_ADVANCE_DASHBOARD_OPT_OUT_ADVANCE_BANNER = "MICRO_CASH_ADVANCE_DASHBOARD_OPT_OUT_ADVANCE_BANNER",
  // cash advance intro carousel
  MICRO_CASH_ADVANCE_LEARN_MORE_ONE_TITLE = "MICRO_CASH_ADVANCE_LEARN_MORE_ONE_TITLE",
  MICRO_CASH_ADVANCE_LEARN_MORE_ONE_TEXT = "MICRO_CASH_ADVANCE_LEARN_MORE_ONE_TEXT",
  MICRO_CASH_ADVANCE_LEARN_MORE_TWO_TITLE = "MICRO_CASH_ADVANCE_LEARN_MORE_TWO_TITLE",
  MICRO_CASH_ADVANCE_LEARN_MORE_TWO_TEXT = "MICRO_CASH_ADVANCE_LEARN_MORE_TWO_TEXT",
  MICRO_CASH_ADVANCE_LEARN_MORE_THREE_TITLE = "MICRO_CASH_ADVANCE_LEARN_MORE_THREE_TITLE",
  MICRO_CASH_ADVANCE_LEARN_MORE_THREE_TEXT = "MICRO_CASH_ADVANCE_LEARN_MORE_THREE_TEXT",
  MICRO_CASH_ADVANCE_LEARN_MORE_OVERLAY = "MICRO_CASH_ADVANCE_LEARN_MORE_OVERLAY",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_SUBTITLE = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_SUBTITLE",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_1_HEAD = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_1_HEAD",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_1_TEXT = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_1_TEXT",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_2_HEAD = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_2_HEAD",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_2_TEXT = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_2_TEXT",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_3_HEAD = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_3_HEAD",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_3_TEXT = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_3_TEXT",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_4_HEAD = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_4_HEAD",
  MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_4_TEXT = "MICRO_CASH_ADVANCE_LEARN_MORE_SECTION_QUESTION_4_TEXT",
  // cash advance deductions
  MICRO_CASH_ADVANCE_DEDUCTION_DEFAULT = "MICRO_CASH_ADVANCE_DEDUCTION_DEFAULT",
  MICRO_CASH_ADVANCE_DEDUCTION_PLANNED = "MICRO_CASH_ADVANCE_DEDUCTION_PLANNED",
  MICRO_CASH_ADVANCE_DEDUCTION_COMPLETE = "MICRO_CASH_ADVANCE_DEDUCTION_COMPLETE",
  MICRO_CASH_ADVANCE_DEDUCTION_PARTIAL = "MICRO_CASH_ADVANCE_DEDUCTION_PARTIAL",
  MICRO_CASH_ADVANCE_DEDUCTION_MISSED = "MICRO_CASH_ADVANCE_DEDUCTION_MISSED",
  MICRO_CASH_ADVANCE_DEDUCTION_OPTOUT = "MICRO_CASH_ADVANCE_DEDUCTION_OPTOUT",
  MICRO_CASH_ADVANCE_DEDUCTION_CANCEL = "MICRO_CASH_ADVANCE_DEDUCTION_CANCEL",
  // cash advance details
  MICRO_CASH_ADVANCE_DETAILS_TITLE = "MICRO_CASH_ADVANCE_DETAILS_TITLE",
  MICRO_CASH_ADVANCE_DETAILS_ERROR = "MICRO_CASH_ADVANCE_DETAILS_ERROR",
  MICRO_CASH_ADVANCE_DETAILS_TRACKER_ONE = "MICRO_CASH_ADVANCE_DETAILS_TRACKER_ONE",
  MICRO_CASH_ADVANCE_DETAILS_TRACKER_TWO = "MICRO_CASH_ADVANCE_DETAILS_TRACKER_TWO",
  MICRO_CASH_ADVANCE_DETAILS_TRACKER_THREE = "MICRO_CASH_ADVANCE_DETAILS_TRACKER_THREE",
  MICRO_CASH_ADVANCE_DETAILS_TRACKER_FOUR = "MICRO_CASH_ADVANCE_DETAILS_TRACKER_FOUR",
  MICRO_CASH_ADVANCE_DETAILS_ADVANCE_BALANCE = "MICRO_CASH_ADVANCE_DETAILS_ADVANCE_BALANCE",
  MICRO_CASH_ADVANCE_DETAILS_ADVANCE_AMOUNT = "MICRO_CASH_ADVANCE_DETAILS_ADVANCE_AMOUNT",
  MICRO_CASH_ADVANCE_DETAILS_ADVANCE_DATE = "MICRO_CASH_ADVANCE_DETAILS_ADVANCE_DATE",
  MICRO_CASH_ADVANCE_DETAILS_DEDUCTION_AMOUNT = "MICRO_CASH_ADVANCE_DETAILS_DEDUCTION_AMOUNT",
  MICRO_CASH_ADVANCE_DETAILS_DATE_PAID = "MICRO_CASH_ADVANCE_DETAILS_DATE_PAID",
  MICRO_CASH_ADVANCE_DETAILS_PAYCHECK = "MICRO_CASH_ADVANCE_DETAILS_PAYCHECK",
  MICRO_CASH_ADVANCE_DETAILS_FUNDS = "MICRO_CASH_ADVANCE_DETAILS_FUNDS",
  MICRO_CASH_ADVANCE_DETAILS_EST_ARRIVAL = "MICRO_CASH_ADVANCE_DETAILS_EST_ARRIVAL",
  MICRO_CASH_ADVANCE_DETAILS_TYPE = "MICRO_CASH_ADVANCE_DETAILS_TYPE",
  MICRO_CASH_ADVANCE_DETAILS_TYPE_DEDUCTION = "MICRO_CASH_ADVANCE_DETAILS_TYPE_DEDUCTION",
  MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_LABEL = "MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_LABEL",
  MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_LABEL = "MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_LABEL",
  MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_MODAL_TITLE = "MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_MODAL_TITLE",
  MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_MODAL_MESSAGE = "MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_MODAL_MESSAGE",
  MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_MODAL_DECLINE = "MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_MODAL_DECLINE",
  MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_MODAL_ACCEPT = "MICRO_CASH_ADVANCE_DETAILS_OPT_OUT_MODAL_ACCEPT",
  MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_MODAL_TITLE = "MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_MODAL_TITLE",
  MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_MODAL_MESSAGE = "MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_MODAL_MESSAGE",
  MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_MODAL_DECLINE = "MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_MODAL_DECLINE",
  MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_MODAL_ACCEPT = "MICRO_CASH_ADVANCE_DETAILS_RE_ENABLE_MODAL_ACCEPT",
  MICRO_CASH_ADVANCE_DETAILS_STATE_PLANNED = "MICRO_CASH_ADVANCE_DETAILS_STATE_PLANNED",
  MICRO_CASH_ADVANCE_DETAILS_STATE_PLANNED_ACTION = "MICRO_CASH_ADVANCE_DETAILS_STATE_PLANNED_ACTION",
  MICRO_CASH_ADVANCE_DETAILS_STATE_PAYROLL = "MICRO_CASH_ADVANCE_DETAILS_STATE_PAYROLL",
  MICRO_CASH_ADVANCE_DETAILS_STATE_OPT_OUT = "MICRO_CASH_ADVANCE_DETAILS_STATE_OPT_OUT",
  MICRO_CASH_ADVANCE_DETAILS_STATE_OPT_OUT_ACTION = "MICRO_CASH_ADVANCE_DETAILS_STATE_OPT_OUT_ACTION",
  MICRO_CASH_ADVANCE_DETAILS_STATE_COMPLETED = "MICRO_CASH_ADVANCE_DETAILS_STATE_COMPLETED",
  MICRO_CASH_ADVANCE_DETAILS_STATE_PROCESSING = "MICRO_CASH_ADVANCE_DETAILS_STATE_PROCESSING",
  MICRO_CASH_ADVANCE_DETAILS_STATE_COMPLETED_PARTIAL = "MICRO_CASH_ADVANCE_DETAILS_STATE_COMPLETED_PARTIAL",
  MICRO_CASH_ADVANCE_DETAILS_STATE_MISSED = "MICRO_CASH_ADVANCE_DETAILS_STATE_MISSED",
  MICRO_CASH_ADVANCE_DETAILS_ADVANCE_CANCELLED = "MICRO_CASH_ADVANCE_DETAILS_ADVANCE_CANCELLED",
  // cash advance request
  MICRO_CASH_ADVANCE_REQUEST_TITLE = "MICRO_CASH_ADVANCE_REQUEST_TITLE",
  MICRO_CASH_ADVANCE_REQUEST_TITLE_2 = "MICRO_CASH_ADVANCE_REQUEST_TITLE_2",
  MICRO_CASH_ADVANCE_REQUEST_CTA = "MICRO_CASH_ADVANCE_REQUEST_CTA",
  MICRO_CASH_ADVANCE_REQUEST_BODY = "MICRO_CASH_ADVANCE_REQUEST_BODY",
  MICRO_CASH_ADVANCE_REQUEST_LINK = "MICRO_CASH_ADVANCE_REQUEST_LINK",
  MICRO_CASH_ADVANCE_REQUEST_AMOUNT_LABEL = "MICRO_CASH_ADVANCE_REQUEST_AMOUNT_LABEL",
  MICRO_CASH_ADVANCE_REQUEST_COST_LABEL = "MICRO_CASH_ADVANCE_REQUEST_COST_LABEL",
  MICRO_CASH_ADVANCE_REQUEST_FREE_LABEL = "MICRO_CASH_ADVANCE_REQUEST_FREE_LABEL",
  MICRO_CASH_ADVANCE_REQUEST_DEDUCTION_AMOUNT_LABEL = "MICRO_CASH_ADVANCE_REQUEST_DEDUCTION_AMOUNT_LABEL",
  MICRO_CASH_ADVANCE_REQUEST_DATE_LABEL = "MICRO_CASH_ADVANCE_REQUEST_DATE_LABEL",
  MICRO_CASH_ADVANCE_REQUEST_VALIDATION_TIMEOUT = "MICRO_CASH_ADVANCE_REQUEST_VALIDATION_TIMEOUT",
  MICRO_CASH_ADVANCE_REQUEST_FUNDING_COMPLETE = "MICRO_CASH_ADVANCE_REQUEST_FUNDING_COMPLETE",
  MICRO_CASH_ADVANCE_REQUEST_DEDUCTION_COMPLETE = "MICRO_CASH_ADVANCE_REQUEST_DEDUCTION_COMPLETE",

  // this section for generic UX components that are not account-specific but used by it
  MICRO_MENU_MODAL_CANCEL_OPTION = "MICRO_MENU_MODAL_CANCEL_OPTION",
  MICRO_CAROUSEL_LINK = "MICRO_CAROUSEL_LINK",
  MICRO_CAROUSEL_CTA = "MICRO_CAROUSEL_CTA",
  MICRO_SELECT_LIST_CLOSE_BUTTON = "MICRO_SELECT_LIST_CLOSE_BUTTON",
  MICRO_SELECT_LIST_PLACEHOLDER = "MICRO_SELECT_LIST_PLACEHOLDER",
  MICRO_ERROR_RETRY_ACTION = "MICRO_ERROR_RETRY_ACTION",
  MICRO_ERROR_RETRY_MESSAGE_1 = "MICRO_ERROR_RETRY_MESSAGE_1",
  MICRO_ERROR_RETRY_MESSAGE_2 = "MICRO_ERROR_RETRY_MESSAGE_2",
  MICRO_ERROR_RETRY_CTA = "MICRO_ERROR_RETRY_CTA",

  // this section for spending account waiting room
  MICRO_ACCOUNTS_SPENDING_WAITING_ERROR_CTA = "MICRO_ACCOUNTS_SPENDING_WAITING_ERROR_CTA",
  MICRO_ACCOUNTS_SPENDING_WAITING_STEP_1 = "MICRO_ACCOUNTS_SPENDING_WAITING_STEP_1",
  MICRO_ACCOUNTS_SPENDING_WAITING_STEP_2 = "MICRO_ACCOUNTS_SPENDING_WAITING_STEP_2",
  MICRO_ACCOUNTS_SPENDING_WAITING_STEP_3 = "MICRO_ACCOUNTS_SPENDING_WAITING_STEP_3",
  MICRO_ACCOUNTS_SPENDING_WAITING_STEP_4 = "MICRO_ACCOUNTS_SPENDING_WAITING_STEP_4",
  MICRO_ACCOUNTS_SPENDING_WAITING_ACTION_HEADER = "MICRO_ACCOUNTS_SPENDING_WAITING_ACTION_HEADER",
  MICRO_ACCOUNTS_SPENDING_WAITING_TITLE_START = "MICRO_ACCOUNTS_SPENDING_WAITING_TITLE_START",
  MICRO_ACCOUNTS_SPENDING_WAITING_TITLE_COMPLETE = "MICRO_ACCOUNTS_SPENDING_WAITING_TITLE_COMPLETE",
  MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VERIFYING_HEADER = "MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VERIFYING_HEADER",
  MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VERIFYING_DESC = "MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VERIFYING_DESC",
  MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VALIDATING_HEADER = "MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VALIDATING_HEADER",
  MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VALIDATING_DESC = "MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VALIDATING_DESC",
  MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VERIFIED_HEADER = "MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VERIFIED_HEADER",
  MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VERIFIED_DESC = "MICRO_ACCOUNTS_SPENDING_WAITING_DOCS_VERIFIED_DESC",

  MICRO_EXTERNAL_ACCOUNTS_EXIT_SUCCESS_CTA = "MICRO_EXTERNAL_ACCOUNTS_EXIT_SUCCESS_CTA",
  MICRO_EXTERNAL_ACCOUNTS_EXIT_SUCCESS_SECONDARY_CTA = "MICRO_EXTERNAL_ACCOUNTS_EXIT_SUCCESS_SECONDARY_CTA",
  MICRO_EXTERNAL_ACCOUNTS_EXIT_SUCCESS_BODY = "MICRO_EXTERNAL_ACCOUNTS_EXIT_SUCCESS_BODY",
  MICRO_EXTERNAL_ACCOUNTS_EXIT_SUCCESS_TITLE = "MICRO_EXTERNAL_ACCOUNTS_EXIT_SUCCESS_TITLE",
  MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_CTA = "MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_CTA",
  MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_SECONDARY_CTA = "MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_SECONDARY_CTA",
  MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_BODY_OAUTH = "MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_BODY_OAUTH",
  MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_BODY_NON_OAUTH = "MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_BODY_NON_OAUTH",
  MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_TITLE = "MICRO_EXTERNAL_ACCOUNTS_EXIT_FAIL_TITLE",
  MICRO_EXTERNAL_ACCOUNTS_INTRO_CTA = "MICRO_EXTERNAL_ACCOUNTS_INTRO_CTA",
  MICRO_EXTERNAL_ACCOUNTS_INTRO_TITLE = "MICRO_EXTERNAL_ACCOUNTS_INTRO_TITLE",
  MICRO_EXTERNAL_ACCOUNTS_INTRO_BODY = "MICRO_EXTERNAL_ACCOUNTS_INTRO_BODY",
}
