import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Route, Router, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { MobileService } from "../services/mobile.service";
import { SavingsService } from "../../financial-solutions/shared/services/savings.service";
import { BridgeService } from "../services/bridge.service";

@Injectable()
export class SpendingSetupGuard implements CanLoad {
  constructor(private mobileStateService: MobileService, private router: Router, private bridgeService: BridgeService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkStatus();
  }

  checkStatus(): Observable<boolean> {
    return this.mobileStateService.fetchMobileState().pipe(
      map((response) => {
        if (MobileService.hasNoSpendingAccount(response) || MobileService.hasWaitingSpendingAccount(response)) {
          return true;
        } else if (MobileService.hasFunctionalSpendingAccount(response)) {
          this.router.navigate(["dda"]);
          return false;
        }
        this.bridgeService.feed();
        return false;
      })
    );
  }
}
