import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs/Rx";
import { InjectedData } from "./bridge.service";

declare var Injected: InjectedData;

@Injectable({
  providedIn: "root",
})
export class LogService {
  private httpClient: HttpClient;
  constructor(private http: HttpBackend, private injector: Injector) {
    //https://stackoverflow.com/a/49013534/763648
    this.httpClient = new HttpClient(http);
  }

  log(logObj): Observable<any> {
    if (typeof Injected === "object") {
      return Observable.of("nope.");
    } else if (logObj && typeof logObj === "object") {
      console.log(JSON.stringify(logObj));
      return Observable.of("nope.");
    } else if (typeof logObj === "string") {
      console.log(logObj);
      return Observable.of("nope.");
    }
  }
}
