import { EventEmitter } from "@angular/core";
/**
 * new way to import ENV specific values from completely outside of the code.
 */
import { ServerConstants } from "./shared/models";
import { Build } from "./shared/models/builddate";
import { Card } from "./shared/models/types/card";
import { Config } from "./shared/models/types/config";
import { SimpleResponse } from "./shared/models/types/simple.response";

declare var CONSTANTS: ServerConstants;

/**
 * Created by davidhaveman on 8/18/17.
 */
export class Globals {
  public static readonly supportPhone: string = CONSTANTS.supportPhone;

  // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html#amazon-cognito-user-pools-using-the-id-token
  // The ID token expires one hour after the user authenticates.
  // https://docs.aws.amazon.com/cognito/latest/developerguide/authentication.html
  // the Mobile SDK for iOS and the Mobile SDK for Android automatically refresh your ID and access tokens if there is a valid (non-expired)
  // refresh token present, and the ID and access tokens have a minimum remaining validity of 5 minutes.
  // 60 minutes - 5 minutes = 55 minutes
  public static readonly MAX_SESSION_AGE_MINUTES = 55;

  public static messageBus: EventEmitter<any> = new EventEmitter<any>();

  public static build = Build;

  public static rootUrl: string = CONSTANTS.apiUrl; // "https://gtf8lfen6k.execute-api.us-west-2.amazonaws.com/DEV2/";
  public static rootDemoUrl: string = CONSTANTS.apiDemoUrl || CONSTANTS.apiUrl;

  public static GenericError: SimpleResponse = { statusCode: 400, message: " Error loading data!" };

  public static PaperClasses: string[] = ["paper--single", "paper--double", "paper--tripple"];

  public static readonly loanVendors = CONSTANTS.loanVendors;
  public static readonly synapsePublicKey = CONSTANTS.synapsePublicKey;

  public static readonly configs = {
    profile: "client",
    updateEmails: "client",
    updatePhones: "client",

    assetsUrl: "financialprofile/asset",
    liabilitiesUrl: "financialprofile/liability",
    typeUrl: "financialprofile/types",
    cardsUrl: "card",
    savingsUrl: "savings",
    transferUrl: "savings/transfersV2",
    savingsLinkUrl: "savings/account",
    termsLink: "terms",
    loansLink: "loans",
  } as Config;

  public static convertSfDateOfBirth(dob: string): string {
    console.log("original date of birth: " + dob);
    if (dob && dob.split("-").length === 3) {
      return MiniMoment.formatSlash(new Date(dob + " 00:00:00"));
    } else {
      return "Error";
    }
  }

  public static cloneCard(original: Card): Card {
    const card = new Card();
    card.case_type = original.case_type;
    card.componentType = "action";
    card.name = original.name;
    card.icon = original.icon;
    card.text = "";
    card.actions = [];

    return card;
  }

  public static isSessionValid(started: Date): boolean {
    const ageInMinutes = MiniMoment.minutes(started, new Date());
    return ageInMinutes < Globals.MAX_SESSION_AGE_MINUTES;
  }

  public static shuffle(array: any[]): void {
    let j;
    let x;
    let i;
    for (i = array.length; i; i--) {
      j = Math.floor(Math.random() * i);
      x = array[i - 1];
      array[i - 1] = array[j];
      array[j] = x;
    }
  }
  public static getPreferredPhoneType(which: string): string {
    if (which && which.toLowerCase() === "home") {
      return "Home";
    }
    if (which && which.toLowerCase() === "mobile") {
      return "Mobile";
    }
    if (which && which.toLowerCase() === "work") {
      return "Work";
    } else {
      return null;
    }
  }

  /**
   * these fields are different.  we push updates to Salesforce, which then syncs down to RDS, but the field names in RDS do not match Salesforce.
   *
   * @param {string} which
   * @returns {string}
   */
  public static getRdsPhoneField(which: string): string {
    if (which && which.toLowerCase() === "home") {
      return "home_phone";
    }
    if (which && which.toLowerCase() === "mobile") {
      return "mobile_phone";
    }
    if (which && which.toLowerCase() === "work") {
      return "work_phone";
    } else {
      return "";
    }
  }

  public static getPhoneField(which: string): string {
    if (which && which.toLowerCase() === "home") {
      return "homephone";
    }
    if (which && which.toLowerCase() === "mobile") {
      return "mobilephone";
    }
    if (which && which.toLowerCase() === "work") {
      return "work_phone__c";
    } else {
      return null;
    }
  }

  public static getPreferredEmailType(which: string): string {
    if (which && which.toLowerCase() === "email 1") {
      return "Email_1";
    }
    if (which && which.toLowerCase() === "email 2") {
      return "Email_2";
    }
    if (which && which.toLowerCase() === "email 3") {
      return "Email_3";
    } else {
      return "";
    }
  }

  public static parseJwt(token): any {
    if (token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    } else {
      return {};
    }
  }

  public static getEmailField(which: string): string {
    if (which.toLowerCase() === "email 1") {
      return "Email_1__c";
    }
    if (which.toLowerCase() === "email 2") {
      return "Email_2__c";
    }
    if (which.toLowerCase() === "email 3") {
      return "Email_3__c";
    } else {
      return null;
    }
  }

  public static getConstants(): ServerConstants {
    return CONSTANTS;
  }
}

export class MiniMoment {
  public static minutes(start: Date, end: Date = new Date()): number {
    const diffMs = Math.abs(end.getTime() - start.getTime()); // milliseconds between now & Christmas
    return diffMs / 1000 / 60;
  }

  public static seconds(start: Date, end: Date = new Date()): number {
    const diffMs = Math.abs(end.getTime() - start.getTime()); // milliseconds between now & Christmas
    return diffMs / 1000;
  }

  /**
   * formats a date in YYYY-MM-DD format.
   * @param date
   * @returns {string}
   */
  public static format(d: Date): string {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return [d.getFullYear(), month, day].join("-");
  }

  public static formatSlash(d: Date): string {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return [month, day, d.getFullYear()].join("/");
  }

  /**
   * adds days to the passed in date, or to the current date.
   * @param {number} days
   * @param {Date} date
   * @returns {Date}
   */
  public static addDays(days: number, date: Date = new Date()): Date {
    const start = date.getTime() + days * 86400000;
    return new Date(start);
  }

  /**
   * returns true if second is after first. or if second is in the future if only one date is given.
   * @param {Date} first
   * @param {Date} second
   * @returns {boolean}
   */
  public static after(second: Date, first: Date = new Date()): boolean {
    return first.getTime() < second.getTime();
  }
}
