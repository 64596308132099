import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { appInjector } from "./app-injector";
import { AppModule } from "./app/app.module";
import { ServerConstants } from "./app/shared/models";
import amplitude from "amplitude-js";
import { init, instrumentAngularRouting } from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
declare var CONSTANTS: ServerConstants;

if (CONSTANTS.production) {
  enableProdMode();
}
amplitude.getInstance().init(CONSTANTS.amplitude.apiKey, undefined, {
  includeUtm: true,
  includeGclid: true,
  includeFbclid: true,
  includeReferrer: true,
});
init({
  dsn: CONSTANTS.sentryDsn,
  release: "mobileweb@2023-08-15", //TODO we should update this as part of release process
  environment: CONSTANTS.environmentName,
  tracesSampleRate: 1.0,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: instrumentAngularRouting,
    }),
  ],
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((module) => {
    window["app"] = module;
    appInjector(module.injector);
  });
