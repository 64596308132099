import { Injectable } from "@angular/core";

import { SavingsAccountAutosaveSnapshotService } from "../savings-account/savings-autosave-snapshot.service";
import { SavingsSetupNeededDetails, SavingsSetupState, SavingsState, SavingsStateService } from "../savings-state.service";
import { LinkedBankStatus, LinkedBank, SavingsAccountSnapshot } from "./savings-account.interface";

import { FinsolService } from "../../../finsol.service";
import { BridgeService } from "../../../../shared/services/bridge.service";

import * as moment from "moment-business-days";

export { SavingsState, SavingsAccountType, SavingsSetupState, SavingsSetupNeededDetails } from "../savings-state.service";
export interface SavingsAccountSnapshotSetupDetails {
  accountSnapshot: SavingsAccountSnapshot;
  setupNeededDetails?: SavingsSetupNeededDetails;
}

const MAX_BALANCE_AGE = 2 * 60 * 60; // seconds.

/**
 * This service is a singleton and state will be remain from use to use
 * This was done to be able to utilize it for state/setup checks as needed throughout the application.
 * Please DON'T include in any NgModule without changing expectation of being a singleton
 */
@Injectable({
  providedIn: "root"
})
export class SavingsAccountSnapshotService {
  static TransferableLinkedBankStatuses = [LinkedBankStatus.LINKED, LinkedBankStatus.ACTIVE];
  static isLinkedAccountWaiting(linkedBankAccount: LinkedBank): boolean {
    return linkedBankAccount?.status === LinkedBankStatus.LINK_VERIFY_DEPOSIT;
  }
  static isLinkedAccountVerifiable(linkedBankAccount: LinkedBank): boolean {
    return Boolean(
      SavingsAccountSnapshotService.isLinkedAccountWaiting &&
        moment().isSameOrAfter(
          moment(linkedBankAccount?.micro_meta?.micro_deposit_initiated_ts)
            .local()
            .businessAdd(1)
            .startOf("day")
        )
    );
  }

  private internalSnapOfAccount: SavingsAccountSnapshot;
  private firstPopulated;

  constructor(
    private bridgeService: BridgeService,
    private savingsAccountAutosaveSnapshotService: SavingsAccountAutosaveSnapshotService,
    private savingsStateService: SavingsStateService
  ) {}

  private throwErrorIfDataNotSet() {
    if (!this.hasDataPopulated) {
      // TODO this may need to become an event prop
      // this.bridgeService.emitEvent("savings_snapshot_failed");

      console.error(new Error("Savings Account Snapshot - No data populated yet"));
    }
  }

  public resetSnapshot() {
    this.internalSnapOfAccount = undefined;
    this.firstPopulated = undefined;

    this.savingsAccountAutosaveSnapshotService.reset();
    this.savingsStateService.reset();
  }

  public setAccount(details: SavingsAccountSnapshotSetupDetails) {
    this.internalSnapOfAccount = details.accountSnapshot;
    this.firstPopulated = new Date().toUTCString();

    if (details.setupNeededDetails) {
      this.savingsStateService.setSavingsInfo(details.setupNeededDetails);
    }

    this.savingsAccountAutosaveSnapshotService.accountUpdated(this.internalSnapOfAccount);
    this.savingsAccountAutosaveSnapshotService.setupStatusChange(this.savingsStateService.getSavingsState());
  }

  public isSetupState(state: SavingsSetupState): boolean {
    if (!state) {
      console.error("! Do not call this without a checker");
      return false;
    }

    this.throwErrorIfDataNotSet();

    return this.savingsStateService.setupState === state;
  }

  get account(): SavingsAccountSnapshot {
    this.throwErrorIfDataNotSet();

    return this.internalSnapOfAccount;
  }

  get autoSave(): SavingsAccountAutosaveSnapshotService {
    this.throwErrorIfDataNotSet();

    return this.savingsAccountAutosaveSnapshotService;
  }

  get hasDataPopulated(): boolean {
    return Boolean(this.firstPopulated);
  }

  get hasLinkedAccount(): boolean {
    this.throwErrorIfDataNotSet();

    return this.internalSnapOfAccount?.banks?.length > 0 && FinsolService.isLinked(this.internalSnapOfAccount.banks[0]);
  }

  get hasLinkedOrWaitingAccount(): boolean {
    this.throwErrorIfDataNotSet();

    return this.hasTransferableBank || this.hasTransferableBankWaitingVerification;
  }

  //ToDo: Find out if we can pull this or adjust.
  get hasNewlyLinkedAccount(): boolean {
    this.throwErrorIfDataNotSet();

    //TODO get the requirements of this feature and implement it properly
    return false;
  }

  get isNonActive(): boolean {
    return [SavingsState.NON_ACTIVE, SavingsState.ERROR].includes(this.savingsStateService.getSavingsState()) || !this.hasDataPopulated;
  }

  get hasErrors(): boolean {
    return this.savingsStateService.getSavingsState() === SavingsState.ERROR || !this.hasDataPopulated;
  }

  get hasUserCreationError(): boolean {
    return this.savingsStateService.setupState === SavingsSetupState.UserCreationError;
  }

  get hasTransferableBank(): boolean {
    return this.internalSnapOfAccount.banks?.some(bank => SavingsAccountSnapshotService.TransferableLinkedBankStatuses.includes(bank.status));
  }

  get hasTransferableBankWaitingVerification(): boolean {
    return this.internalSnapOfAccount.banks?.some(bank => SavingsAccountSnapshotService.isLinkedAccountWaiting(bank));
  }

  get isActive(): boolean {
    this.throwErrorIfDataNotSet();

    return this.savingsStateService.getSavingsState() === SavingsState.ACTIVE;
  }

  get isCreating(): boolean {
    this.throwErrorIfDataNotSet();

    return this.savingsStateService.getSavingsState() === SavingsState.IN_PROGRESS;
  }

  get isLinkedBankPresent(): boolean {
    return this.internalSnapOfAccount?.banks?.length > 0;
  }
}
