import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { FeedGuard } from "./feed.guard";
import { PartnerReferralComponent } from "./partner-referral/partner-referral.component";
import { FunctionalSpendingGuard } from "./dda/functional-spending.guard";
import { SpendingSetupGuard } from "./shared/guard/spending.guard";

const routes: Routes = [
  { path: "deep/finsol/savings/autosave/once", redirectTo: "finsol/deep/savings/autosave/once" },
  { path: "feed", loadChildren: () => import("./feed/feed.module").then((m) => m.FeedModule), canLoad: [FeedGuard] },
  /** this module is just for dev testing purposes **/
  { path: "profile", loadChildren: () => import("./profile/profile.module").then((m) => m.ProfileModule) },
  { path: "login", loadChildren: () => import("./login/login.module").then((m) => m.LoginModule) },
  { path: "lendingclub", loadChildren: () => import("./lendingclub/lendingclub.module").then((m) => m.LendingclubModule) },
  { path: "self-lender", loadChildren: () => import("./selflender/selflender.module").then((m) => m.SelfLenderModule) },
  { path: "savi", loadChildren: () => import("./savi/savi.module").then((m) => m.SaviModule) },
  { path: "salary-finance", redirectTo: "loans" },
  { path: "loans", loadChildren: () => import("./loans/loans.module").then((m) => m.LoansModule) },
  { path: "payroll", loadChildren: () => import("./payroll/payroll.module").then((m) => m.PayrollModule) },
  { path: "partner/:cardCaseType", component: PartnerReferralComponent },
  { path: "dda-setup", canLoad: [SpendingSetupGuard], loadChildren: () => import("./dda-setup/dda-setup.module").then((m) => m.DDASetupModule) },
  { path: "dda", canLoad: [FunctionalSpendingGuard], loadChildren: () => import("./dda/dda.module").then((m) => m.DDAModule) },
  { path: "finsol", loadChildren: () => import("./financial-solutions/financial-solutions.module").then((m) => m.FinancialSolutionsModule) },
  /** this module is just for dev testing purposes **/
  { path: "styles", loadChildren: () => import("./styleguide/styleguide.module").then((m) => m.StyleguideModule) },
  { path: "nothing", component: AppComponent } /** route to show a blank page for native transitions **/,
  { path: "**", redirectTo: "feed" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [FeedGuard, FunctionalSpendingGuard, SpendingSetupGuard],
})
export class AppRoutingModule {}
