import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";
import { StateNames } from "./state-names";

//Animation to slide page in from the left
const fromLeft = [
  style({ position: "fixed" }),
  query(":enter, :leave", [
    style({
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      width: "100%",
    }),
  ]),
  query(":enter", [style({ left: "-100%" })]),
  query(":leave", animateChild(), { optional: true }),
  group([
    query(":leave", [animate("300ms ease-out", style({ left: "100%" }))], { optional: true }),
    query(":enter", [animate("300ms ease-out", style({ left: "0%" }))]),
  ]),
  query(":enter", animateChild()),
];

//Animation to slide page in from the right
const fromRight = [
  style({ position: "relative" }),
  query(":enter, :leave", [
    style({
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: "100%",
    }),
  ]),
  query(":enter", [style({ right: "-100%" })]),
  query(":leave", animateChild(), { optional: true }),
  group([
    query(":leave", [animate("300ms ease-out", style({ right: "100%" }))], { optional: true }),
    query(":enter", [animate("300ms ease-out", style({ right: "0%" }))]),
  ]),
  query(":enter", animateChild()),
];

//Animation to slide page in from the right quickly
//Used for pages that autofocus an input field that activates the onscreen keyboard
const fromRightQuick = [
  style({ position: "relative" }),
  query(":enter, :leave", [
    style({
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: "100%",
    }),
  ]),
  query(":enter", [style({ right: "-100%" })]),
  query(":leave", animateChild(), { optional: true }),
  group([
    query(":leave", [animate("120ms ease-out", style({ right: "100%" }))], { optional: true }),
    query(":enter", [animate("120ms ease-out", style({ right: "0%" }))]),
  ]),
  query(":enter", animateChild()),
];
// tslint:disable-next-line:no-commented-code
/* not sure if this transition will be used but keeping it around for now
//Animation to slide page up from the bottom
const fromBottom = [
  query(":enter, :leave", style({ position: "fixed", left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
  query(":leave", style({ transform: "translateY(0%)" }), { optional: true }),
  query(":enter", style({ transform: "translateY(100%)" }), { optional: true }),
  group([
    query(":leave", [animate(".3s ease-in-out", style({ transform: "translateY(-100%)" }))], { optional: true }),
    query(":enter", [animate(".3s ease-in-out", style({ transform: "translateY(0%)" }))], { optional: true })
  ])
];
*/

//Animation to slide new page down from the top AND old page down out the bottom (simultaneous motion of both)
const fromTop = [
  query(":enter, :leave", style({ position: "fixed", left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
  query(":leave", style({ transform: "translateY(0%)" }), { optional: true }),
  query(":enter", style({ transform: "translateY(-100%)" }), { optional: true }),
  group([
    query(":leave", [animate(".3s ease-in-out", style({ transform: "translateY(100%)" }))], { optional: true }),
    query(":enter", [animate(".3s ease-in-out", style({ transform: "translateY(0%)" }))], { optional: true }),
  ]),
];

//Animation to slide outgoing page down from the top, the new page is revealed "underneath" and does not move
const fromTopLeave = [
  query(
    ":leave",
    style({ position: "fixed", left: 0, right: 0, top: 0, bottom: 0, zIndex: 2, backgroundColor: "white", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25" }),
    { optional: true }
  ),
  query(
    ":enter",
    style({ position: "fixed", left: 0, right: 0, top: 0, bottom: 0, zIndex: 1, backgroundColor: "white", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25" }),
    { optional: true }
  ),
  query(":leave", style({ transform: "translateY(0)" }), { optional: true }),
  group([
    query(":leave", [animate(".45s ease-in", style({ transform: "translateY(100%)" }))], { optional: true }),
    query(":enter", [animate(".45s ease-out", style({}))], { optional: true }),
  ]),
];

//Animation to slide page up from the bottom over the outgoing page
const fromBottomEnter = [
  query(
    ":enter",
    style({ position: "fixed", left: 0, right: 0, top: 0, bottom: 0, zIndex: 2, backgroundColor: "white", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25" }),
    { optional: true }
  ),
  query(":leave", style({ position: "fixed", left: 0, right: 0, top: 0, bottom: 0, zIndex: 1 }), { optional: true }),
  query(":enter", style({ transform: "translateY(100%)" }), { optional: true }),
  group([
    query(":leave", [animate(".45s ease-in", style({}))], { optional: true }),
    query(":enter", [animate(".45s ease-out", style({ transform: "translateY(0%)" }))], { optional: true }),
  ]),
];
//MAYBE ALL THESE TRANSITIONS CAN GO INTO THIER COMPONENTS. . .

export const slideIn =
  //trigger name "routeAnimations" is defined in app.component.html
  //NOTE: if adding new state data to modules/routes you have to restart the app before it will get picked up
  trigger("routeAnimations", [
    transition(`${StateNames.Education} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.ChatLanding} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.LinkAccount} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.LinkAccountLogin} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.SelectAccount} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.Home} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.Campaign} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.Timeout} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.LinkDecision} => ${StateNames.Feed}`, fromLeft),

    //enter savings flow
    transition(`* => ${StateNames.Campaign}`, fromRight),
    transition(`${StateNames.Feed} => ${StateNames.Education}`, fromRight),
    transition(`${StateNames.Feed} => ${StateNames.LinkAccount}`, fromRight),
    transition(`${StateNames.Feed} => ${StateNames.LinkAccountLogin}`, fromRight),
    transition(`${StateNames.Feed} => ${StateNames.SelectAccount}`, fromRight),
    transition(`${StateNames.Feed} => ${StateNames.Home}`, fromRight),
    transition(`${StateNames.Feed} => ${StateNames.LinkDecision}`, fromRight),

    transition(`${StateNames.Intro} => ${StateNames.LinkDecision}`, fromRight),
    transition(`${StateNames.LinkDecision} => ${StateNames.PaycheckDeductionAgreement}`, fromRight),
    transition(`${StateNames.LinkDecision} => ${StateNames.LinkAccount}`, fromRight),
    transition(`${StateNames.LinkAccount} => ${StateNames.LinkDecision}`, fromLeft),
    transition(`${StateNames.PaycheckDeductionAgreement} => ${StateNames.LinkDecision}`, fromLeft),
    transition(`${StateNames.PaycheckDeductionAgreement} => ${StateNames.Schedule}`, fromRight),

    transition(`${StateNames.Education} => ${StateNames.ChatLanding}`, fromRight),

    transition(`${StateNames.Education} => ${StateNames.Intro}`, fromRight),
    transition(`${StateNames.Intro} => ${StateNames.Education}`, fromLeft),
    transition(`${StateNames.Intro} => ${StateNames.Feed}`, fromLeft),

    transition(`${StateNames.Intro} => ${StateNames.LinkAccount}`, fromRight),
    transition(`${StateNames.LinkAccount} => ${StateNames.LinkAccountLogin}`, fromRight),
    transition(`${StateNames.LinkAccountLogin} => ${StateNames.LinkAccount}`, fromLeft),
    transition(`${StateNames.LinkAccountLogin} => ${StateNames.LinkAccountMFA}`, fromRight),
    transition(`${StateNames.LinkAccountMFA} => ${StateNames.LinkAccount}`, fromLeft),

    transition(`${StateNames.LinkAccountLogin} => ${StateNames.SelectAccount}`, fromRight),
    transition(`${StateNames.LinkAccountMFA} => ${StateNames.SelectAccount}`, fromRight),

    transition(`${StateNames.Home} => ${StateNames.Deposit}`, fromRight),
    transition(`${StateNames.Deposit} => ${StateNames.LinkAccountLogin}`, fromRight),
    transition(`${StateNames.Deposit} => ${StateNames.Home}`, fromLeft),

    transition(`${StateNames.Home} => ${StateNames.LinkAccountLogin}`, fromRight),
    transition(`${StateNames.LinkAccountLogin} => ${StateNames.Home}`, fromLeft),

    transition(`${StateNames.Home} => ${StateNames.Schedule}`, fromRight),
    transition(`${StateNames.Schedule} => ${StateNames.Home}`, fromLeft),

    transition(`${StateNames.PaycheckDeductionAgreement} => ${StateNames.PayrollSetup}`, fromRight),
    transition(`${StateNames.PayrollSetup} => ${StateNames.Home}`, fromLeft),

    transition(`${StateNames.Home} => ${StateNames.Withdraw}`, fromRight),
    transition(`${StateNames.Withdraw} => ${StateNames.Home}`, fromLeft),

    transition(`${StateNames.Home} => ${StateNames.Transaction}`, fromRight),
    transition(`${StateNames.Transaction} => ${StateNames.Home}`, fromLeft),

    transition(`${StateNames.Home} => ${StateNames.Disconnect}`, fromRight),
    transition(`${StateNames.Disconnect} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.Disconnect} => ${StateNames.Home}`, fromLeft),

    transition(`${StateNames.Home} => ${StateNames.Pause}`, fromRight),
    transition(`${StateNames.Pause} => ${StateNames.Home}`, fromLeft),

    transition(`${StateNames.Routing} => ${StateNames.NextSteps}`, fromRight),
    transition(`${StateNames.Routing} => ${StateNames.LinkAccount}`, fromLeft),
    transition(`${StateNames.NextSteps} => ${StateNames.Home}`, fromRight),
    transition(`${StateNames.NextSteps} => ${StateNames.ResendDeposits}`, fromRight),
    transition(`${StateNames.ResendDeposits} => ${StateNames.NextSteps}`, fromLeft),
    transition(`${StateNames.Home} => ${StateNames.Microdeposit}`, fromRight),
    transition(`${StateNames.Microdeposit} => ${StateNames.Home}`, fromRight),
    transition(`${StateNames.Microdeposit} => ${StateNames.HomeBack}`, fromLeft),
    transition(`${StateNames.LinkAccount} => ${StateNames.Routing}`, fromRight),
    transition(`${StateNames.LinkEducation} => ${StateNames.LinkAccount}`, fromRight),
    transition(`${StateNames.LinkAccount} => ${StateNames.LinkEducation}`, fromLeft),
    transition(`${StateNames.Home} => ${StateNames.LinkEducation}`, fromRight),
    transition(`${StateNames.LinkEducation} => ${StateNames.Home}`, fromLeft),
    transition(`${StateNames.NextSteps} => ${StateNames.HomeBack}`, fromLeft),

    // DDA Setup transitions
    transition(`${StateNames.Feed} => ${StateNames.DdaGetStarted}`, fromRight),
    transition(`${StateNames.DdaGetStarted} => ${StateNames.Feed}`, fromLeft),

    transition(`${StateNames.DdaGetStarted} => ${StateNames.DdaConsent}`, fromRight),
    transition(`${StateNames.DdaConsent} => ${StateNames.DdaGetStarted}`, fromLeft),

    transition(`${StateNames.DdaConsent} => ${StateNames.LicenseCapture}`, fromRight),
    transition(`${StateNames.LicenseCapture} => ${StateNames.DdaGetStarted}`, fromLeft),
    transition(`${StateNames.LicenseCapture} => ${StateNames.CameraAccess}`, fromRight),
    transition(`${StateNames.CameraAccess} => ${StateNames.Feed}`, fromLeft),

    transition(`${StateNames.LicenseCapture} => ${StateNames.LicenseProgress}`, fromRight),
    transition(`${StateNames.LicenseProgress} => ${StateNames.DdaHome}`, fromRight),

    // DDA transitions
    transition(`${StateNames.Feed} => ${StateNames.DdaHome}`, fromRight),
    transition(`${StateNames.DdaHome} => ${StateNames.Feed}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.NextSteps}`, fromRight),
    transition(`${StateNames.NextSteps} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.ShipCard}`, fromRight),
    transition(`${StateNames.ShipCard} => ${StateNames.EditAddress}`, fromRight),
    transition(`${StateNames.ShipCard} => ${StateNames.Congrats}`, fromRight),
    transition(`${StateNames.Congrats} => ${StateNames.DdaHome}`, fromTop),
    transition(`${StateNames.EditAddress} => ${StateNames.ShipCard}`, fromLeft),
    transition(`${StateNames.ShipCard} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.CardActivation}`, fromRightQuick),
    transition(`${StateNames.CardActivation} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaTransfers}`, fromRightQuick),
    transition(`${StateNames.DdaTransfers} => ${StateNames.DdaHome}`, fromLeft),
    transition(`${StateNames.DdaTransfers} => ${StateNames.DdaTransferError}`, fromRight),
    transition(`${StateNames.DdaTransferError} => ${StateNames.DdaHome}`, fromRight),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaAccountDetails}`, fromRight),
    transition(`${StateNames.DdaAccountDetails} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaTransaction}`, fromRight),
    transition(`${StateNames.DdaTransaction} => ${StateNames.DdaHome}`, fromLeft),
    transition(`${StateNames.DdaAllTransactions} => ${StateNames.DdaTransaction}`, fromRight),
    transition(`${StateNames.DdaTransaction} => ${StateNames.DdaAllTransactions}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaAllTransactions}`, fromRight),
    transition(`${StateNames.DdaAllTransactions} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaAllTransactions} => ${StateNames.DdaStatements}`, fromRight),
    transition(`${StateNames.DdaStatements} => ${StateNames.DdaAllTransactions}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaVerifySsn}`, fromRightQuick),
    transition(`${StateNames.DdaVerifySsn} => ${StateNames.DdaHome}`, fromLeft),
    transition(`${StateNames.LostCard} => ${StateNames.DdaVerifySsn}`, fromRight),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaLockUnlock}`, fromRight),
    transition(`${StateNames.DdaLockUnlock} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.VerifyCardNumber}`, fromRightQuick),
    transition(`${StateNames.VerifyCardNumber} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaTransaction} => ${StateNames.DdaReportIssue}`, fromRight),
    transition(`${StateNames.DdaReportIssue} => ${StateNames.DdaTransaction}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaFindAtm}`, fromRight),
    transition(`${StateNames.DdaFindAtm} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaStatements}`, fromRight),
    transition(`${StateNames.DdaStatements} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaInfo}`, fromRight),
    transition(`${StateNames.DdaInfo} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.LinkRouting}`, fromRight),
    transition(`${StateNames.LinkRouting} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.LinkVerify}`, fromRight),
    transition(`${StateNames.LinkVerify} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.NextSteps} => ${StateNames.LinkVerify}`, fromRight),
    transition(`${StateNames.LinkVerify} => ${StateNames.NextSteps}`, fromLeft),

    transition(`${StateNames.LinkVerify} => ${StateNames.LinkVerifyError}`, fromRight),
    transition(`${StateNames.LinkVerify} => ${StateNames.LinkSuccess}`, fromRight),
    transition(`${StateNames.LinkVerifyError} => ${StateNames.DdaHome}`, fromRight),
    transition(`${StateNames.LinkSuccess} => ${StateNames.DdaHome}`, fromRight),
    transition(`${StateNames.LinkVerifyError} => ${StateNames.LinkVerify}`, fromLeft),

    transition(`${StateNames.LinkRouting} => ${StateNames.NextSteps}`, fromRight),
    transition(`${StateNames.NextSteps} => ${StateNames.DdaHome}`, fromRight),

    transition(`${StateNames.DdaStatements} => ${StateNames.DdaStatement}`, fromRight),
    transition(`${StateNames.DdaStatement} => ${StateNames.DdaStatements}`, fromLeft),
    transition(`${StateNames.DdaHome} => ${StateNames.DdaSupport}`, fromRight),
    transition(`${StateNames.DdaSupport} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.DdaManageAccounts}`, fromRight),
    transition(`${StateNames.DdaManageAccounts} => ${StateNames.DdaAccountDetails}`, fromRight),

    transition(`${StateNames.DdaManageAccounts} => ${StateNames.LinkAccount}`, fromRight),
    transition(`${StateNames.LinkAccount} => ${StateNames.DdaManageAccounts}`, fromLeft),

    transition(`${StateNames.DdaLinkedAccountDetails} => ${StateNames.LinkAccount}`, fromRight),
    transition(`${StateNames.LinkAccount} => ${StateNames.DdaLinkedAccountDetails}`, fromLeft),

    transition(`${StateNames.DdaLinkedAccountDetails} => ${StateNames.Microdeposit}`, fromRight),
    transition(`${StateNames.Microdeposit} => ${StateNames.DdaLinkedAccountDetails}`, fromLeft),

    transition(`${StateNames.DdaLinkedAccountDetails} => ${StateNames.DdaManageAccounts}`, fromLeft),
    transition(`${StateNames.DdaManageAccounts} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaLinkedAccountDetails} => ${StateNames.NextSteps}`, fromRight),
    transition(`${StateNames.NextSteps} => ${StateNames.DdaLinkedAccountDetails}`, fromLeft),

    transition(`${StateNames.SavingsIntro} => ${StateNames.Feed}`, fromLeft),

    transition(`${StateNames.SavingsIntro} => ${StateNames.SavingsConsents}`, fromRight),
    transition(`${StateNames.SavingsConsents} => ${StateNames.SavingsIntro}`, fromLeft),

    transition(`${StateNames.SavingsConsents} => ${StateNames.SavingsAccountCreation}`, fromRight),
    transition(`${StateNames.SavingsAccountCreation} => ${StateNames.SavingsConsents}`, fromLeft),

    transition(`${StateNames.SavingsAccountCreation} => ${StateNames.SavingsOnboardingAutosave}`, fromRight),
    transition(`${StateNames.SavingsOnboardingAutosave} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsAccountCreation} => ${StateNames.SavingsOnboardingAutosaveComplete}`, fromRight),
    transition(`${StateNames.SavingsAccountCreation} => ${StateNames.SavingsOnboardingAutosaveError}`, fromRight),

    transition(`${StateNames.SavingsOnboardingAutosaveComplete} => ${StateNames.SavingsHome}`, fromRight),
    transition(`${StateNames.SavingsOnboardingAutosaveError} => ${StateNames.SavingsHome}`, fromRight),

    transition(`${StateNames.SavingsHome} => ${StateNames.LinkRouting}`, fromRight),
    transition(`${StateNames.LinkRouting} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.LinkVerify}`, fromRight),
    transition(`${StateNames.LinkVerify} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.LinkSuccess} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.SavingsAllTransactions}`, fromRight),
    transition(`${StateNames.SavingsAllTransactions} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsAllTransactions} => ${StateNames.SavingsTransactionDetails}`, fromRight),
    transition(`${StateNames.SavingsTransactionDetails} => ${StateNames.SavingsAllTransactions}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.SavingsTransactionDetails}`, fromRight),
    transition(`${StateNames.SavingsTransactionDetails} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.CashAdvanceHome}`, fromRight),
    transition(`${StateNames.CashAdvanceHome} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.CashAdvanceOnboardingIntro}`, fromRight),
    transition(`${StateNames.CashAdvanceOnboardingIntro} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.CashAdvanceOnboardingIntro} => ${StateNames.CashAdvanceOnboardingConsents}`, fromRight),
    transition(`${StateNames.CashAdvanceOnboardingConsents} => ${StateNames.CashAdvanceOnboardingIntro}`, fromLeft),

    transition(`${StateNames.CashAdvanceHome} => ${StateNames.CashAdvanceFunding}`, fromRight),
    transition(`${StateNames.CashAdvanceFunding} => ${StateNames.CashAdvanceHome}`, fromLeft),

    transition(`${StateNames.CashAdvanceHome} => ${StateNames.CashAdvanceDetails}`, fromRight),
    transition(`${StateNames.CashAdvanceDetails} => ${StateNames.CashAdvanceHome}`, fromLeft),

    transition(`${StateNames.CashAdvanceDetails} => ${StateNames.CashAdvanceFunding}`, fromRight),
    transition(`${StateNames.CashAdvanceFunding} => ${StateNames.CashAdvanceDetails}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.DdaManageAccounts}`, fromRight),
    transition(`${StateNames.DdaManageAccounts} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.SavingsTransfers}`, fromRight),
    transition(`${StateNames.SavingsTransfers} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.SavingsStatements}`, fromRight),
    transition(`${StateNames.SavingsStatements} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.SavingsStatementList}`, fromRight),
    transition(`${StateNames.SavingsStatementList} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsStatementList} => ${StateNames.SavingsStatementDisplay}`, fromRight),
    transition(`${StateNames.SavingsStatementDisplay} => ${StateNames.SavingsStatementList}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.SavingsDeleteSuccess}`, fromRight),
    transition(`${StateNames.SavingsDeleteSuccess} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.SavingsDelete}`, fromRight),
    transition(`${StateNames.SavingsDelete} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.DdaHome} => ${StateNames.CashAdvanceFunding}`, fromRight),
    transition(`${StateNames.CashAdvanceFunding} => ${StateNames.DdaHome}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.SavingsOnboardingAutosaveInfo}`, fromRight),
    transition(`${StateNames.SavingsOnboardingAutosaveInfo} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsOnboardingAutosaveInfo} => ${StateNames.SavingsOnboardingAutosaveEdit}`, fromRight),
    transition(`${StateNames.SavingsOnboardingAutosaveEdit} => ${StateNames.SavingsOnboardingAutosaveInfo}`, fromLeft),

    transition(`${StateNames.SavingsCommitIntro} => ${StateNames.SavingsCommitLater}`, fromRight),
    transition(`${StateNames.SavingsCommitLater} => ${StateNames.SavingsCommitIntro}`, fromLeft),

    transition(`${StateNames.SavingsCommitIntro} => ${StateNames.SavingsOnboardingAutosave}`, fromRight),
    transition(`${StateNames.SavingsOnboardingAutosave} => ${StateNames.SavingsCommitIntro}`, fromLeft),

    transition(`${StateNames.SavingsCommitLater} => ${StateNames.SavingsCommitComplete}`, fromRight),
    transition(`${StateNames.SavingsCommitComplete} => ${StateNames.SavingsCommitLater}`, fromLeft),

    transition(`${StateNames.FinConnectOnboardingIntro} => ${StateNames.FinConnectOnboardingConsents}`, fromRight),
    transition(`${StateNames.FinConnectOnboardingConsents} => ${StateNames.FinConnectOnboardingIntro}`, fromLeft),

    transition(`${StateNames.FinConnectOnboardingConsents} => ${StateNames.FinConnect}`, fromBottomEnter),
    transition(`${StateNames.FinConnect} => ${StateNames.FinConnectOnboardingConsents}`, fromTopLeave),

    transition(`${StateNames.FinConnect} => ${StateNames.FinConnectFinal}`, fromRight),
    transition(`${StateNames.FinConnectFinal} => ${StateNames.FinConnect}`, fromLeft),
    transition(`${StateNames.FinConnectFinal} => ${StateNames.FinConnections}`, fromTopLeave),
    transition(`${StateNames.FinConnectFinal} => ${StateNames.FinHome}`, fromTopLeave),
    transition(`${StateNames.FinHome} => ${StateNames.FinConnect}`, fromBottomEnter),

    transition(`${StateNames.FinHome} => ${StateNames.FinCategories}`, fromRight),
    transition(`${StateNames.FinCategories} => ${StateNames.FinHome}`, fromLeft),

    transition(`${StateNames.FinHome} => ${StateNames.FinCategoryDetails}`, fromRight),
    transition(`${StateNames.FinCategoryDetails} => ${StateNames.FinHome}`, fromLeft),

    transition(`${StateNames.FinCategories} => ${StateNames.FinCategoryDetails}`, fromRight),
    transition(`${StateNames.FinCategoryDetails} => ${StateNames.FinCategories}`, fromLeft),

    transition(`${StateNames.FinCategoryDetails} => ${StateNames.FinTransactionDetails}`, fromRight),
    transition(`${StateNames.FinTransactionDetails} => ${StateNames.FinCategoryDetails}`, fromLeft),

    transition(`${StateNames.FinTransactionDetails} => ${StateNames.FinUpdateCategory}`, fromRight),
    transition(`${StateNames.FinUpdateCategory} => ${StateNames.FinTransactionDetails}`, fromLeft),

    transition(`${StateNames.FinHome} => ${StateNames.FinAccountList}`, fromRight),
    transition(`${StateNames.FinAccountList} => ${StateNames.FinHome}`, fromLeft),

    transition(`${StateNames.FinAccountList} => ${StateNames.FinConnections}`, fromRight),
    transition(`${StateNames.FinConnections} => ${StateNames.FinAccountList}`, fromLeft),

    transition(`${StateNames.FinConnections} => ${StateNames.FinConnect}`, fromBottomEnter),
    transition(`${StateNames.FinConnect} => ${StateNames.FinConnections}`, fromTopLeave),

    transition(`${StateNames.FinConnection} => ${StateNames.FinConnect}`, fromBottomEnter),
    transition(`${StateNames.FinConnect} => ${StateNames.FinConnection}`, fromTopLeave),

    transition(`${StateNames.FinConnections} => ${StateNames.FinConnection}`, fromRight),
    transition(`${StateNames.FinConnection} => ${StateNames.FinConnections}`, fromLeft),

    transition(`${StateNames.FinConnection} => ${StateNames.FinConnections}`, fromLeft),

    transition(`${StateNames.Feed} => ${StateNames.FinConnect}`, fromRight),
    transition(`${StateNames.Feed} => ${StateNames.FinHome}`, fromRight),
    transition(`${StateNames.FinConnect} => ${StateNames.Feed}`, fromLeft),
    transition(`${StateNames.FinHome} => ${StateNames.Feed}`, fromLeft),

    // Not sure this would ever happen, but can add if so
    // transition(`${StateNames.Feed} => ${StateNames.FinConnectFinal}`, fromRight),
    // transition(`${StateNames.FinConnectFinal} => ${StateNames.Feed}`, fromLeft),

    //Verify microdeposits
    transition(`${StateNames.SavingsHome} => ${StateNames.NextSteps}`, fromRight),
    transition(`${StateNames.NextSteps} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.NextSteps} => ${StateNames.ResendDeposits}`, fromRight),

    transition(`${StateNames.LinkRouting} => ${StateNames.SavingsSummary}`, fromRight),
    transition(`${StateNames.SavingsSummary} => ${StateNames.LinkRouting}`, fromLeft),

    transition(`${StateNames.SavingsSummary} => ${StateNames.NextSteps}`, fromRight),
    transition(`${StateNames.NextSteps} => ${StateNames.SavingsSummary}`, fromLeft),

    transition(`${StateNames.NextSteps} => ${StateNames.LinkVerifyError}`, fromRight),
    transition(`${StateNames.LinkVerifyError} => ${StateNames.NextSteps}`, fromLeft),

    transition(`${StateNames.LinkVerifyError} => ${StateNames.SavingsHome}`, fromRight),
    transition(`${StateNames.LinkVerifyError} => ${StateNames.DdaHome}`, fromRight),

    transition(`${StateNames.DdaLinkedAccountDetails} => ${StateNames.LinkVerifyError}`, fromRight),

    transition(`${StateNames.Feed} => ${StateNames.DeepSavingsAutosave}`, fromRight),
    transition(`${StateNames.DeepSavingsAutosave} => ${StateNames.Feed}`, fromLeft),

    transition(`${StateNames.Feed} => ${StateNames.DeepSavingsAutosaveNext}`, fromRight),
    transition(`${StateNames.DeepSavingsAutosaveNext} => ${StateNames.Feed}`, fromLeft),

    transition(`${StateNames.DeepSavingsAutosave} => ${StateNames.SavingsConsents}`, fromRight),
    transition(`${StateNames.SavingsConsents} => ${StateNames.DeepSavingsAutosave}`, fromLeft),

    transition(`${StateNames.DeepSavingsAutosave} => ${StateNames.DeepSavingsAutosaveNext}`, fromRight),
    transition(`${StateNames.DeepSavingsAutosaveNext} => ${StateNames.DeepSavingsAutosave}`, fromLeft),

    transition(`${StateNames.SavingsAccountCreation} => ${StateNames.DeepSavingsAutosaveNext}`, fromRight),
    transition(`${StateNames.DeepSavingsAutosaveNext} => ${StateNames.SavingsAccountCreation}`, fromLeft),

    transition(`${StateNames.DeepSavingsAutosaveNext} => ${StateNames.LinkRouting}`, fromRight),
    transition(`${StateNames.LinkRouting} => ${StateNames.DeepSavingsAutosaveNext}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.DeepSavingsAutosave}`, fromRight),
    transition(`${StateNames.DeepSavingsAutosave} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SavingsHome} => ${StateNames.DeepSavingsAutosaveNext}`, fromRight),
    transition(`${StateNames.DeepSavingsAutosaveNext} => ${StateNames.SavingsHome}`, fromLeft),

    transition(`${StateNames.SurveyOnboardSavings} => ${StateNames.DeepSavingsAutosave}`, fromRight),
    transition(`${StateNames.DeepSavingsAutosave} => ${StateNames.SurveyOnboardSavings}`, fromLeft),

    transition(`${StateNames.SavingsConsents} => ${StateNames.SurveyOnboardSavingsComplete}`, fromRight),
    transition(`${StateNames.SurveyOnboardSavingsComplete} => ${StateNames.SavingsConsents}`, fromLeft),

    // transition(`${StateNames.CcCrusherInformation} => ${StateNames.CcCrusherDetails}`, fromTopLeave),
    // transition(`${StateNames.CcCrusherDetails} => ${StateNames.CcCrusherInformation}`, fromBottomEnter)

    // Set up basic reusable transition
    transition(`${StateNames.BasePage} => ${StateNames.DetailsPage}`, fromRight),
    transition(`${StateNames.DetailsPage} => ${StateNames.BasePage}`, fromLeft),

    transition(`${StateNames.CrusherIntro} => ${StateNames.DetailsPage}`, fromRight),
    transition(`${StateNames.DetailsPage} => ${StateNames.CrusherIntro}`, fromLeft),

    transition(`${StateNames.DetailsPage} => ${StateNames.CrusherPage}`, fromRight),
    transition(`${StateNames.CrusherPage} => ${StateNames.DetailsPage}`, fromLeft),

    transition(`${StateNames.CrusherPage} => ${StateNames.CrusherCommit}`, fromRight),
    transition(`${StateNames.CrusherCommit} => ${StateNames.CrusherPage}`, fromLeft),

    transition(`${StateNames.CrusherCommit} => ${StateNames.CrusherGoalConfirmation}`, fromRight),
    transition(`${StateNames.CrusherGoalConfirmation} => ${StateNames.CrusherCommit}`, fromLeft),

    transition(`${StateNames.CrusherGoalConfirmation} => ${StateNames.CrusherGoal}`, fromRight),
    transition(`${StateNames.CrusherGoal} => ${StateNames.CrusherGoalConfirmation}`, fromLeft),

    transition(`${StateNames.CrusherGoal} => ${StateNames.CrusherGoalDetails}`, fromRight),
    transition(`${StateNames.CrusherGoalDetails} => ${StateNames.CrusherGoal}`, fromLeft),

    transition(`${StateNames.FinLinkedAccountReview} => ${StateNames.SavingsHome}`, fromTopLeave),
  ]);
