import { Injectable } from "@angular/core";
import * as LaunchDarkly from "launchdarkly-js-client-sdk";

import { BehaviorSubject, Observable, Subject, zip } from "rxjs";
import { map, take, takeWhile, tap } from "rxjs/operators";
import { Globals } from "../../globals";

interface FlagContextInterface {
  kind: "user";
  key: string;
  guid: string;
  company: string;
  preferredLanguage: string;
  version: string;
}

abstract class FeatureFlagWrapperClass {
  client: LaunchDarkly.LDClient;

  protected _featureFlagsReady = new BehaviorSubject<boolean>(false);
  public readonly featureFlagsReady: Observable<boolean> = this._featureFlagsReady.asObservable();

  clientSideId = Globals.getConstants().featureFlagApp.clientSideId;

  constructor() {}

  protected _init(user: { preferredLanguage: string; kind: string; guid: string; company: string; version: string; key: string }) {
    const context = { ...user };
    this.client = LaunchDarkly.initialize(this.clientSideId, context);
    this.client.on("ready", () => {
      const flagKeys = Object.keys(this.client.allFlags());
      if (flagKeys.length !== 0) {
        this._featureFlagsReady.next(true);
      }
    });
  }

  protected _identity(user: { preferredLanguage: string; kind: string; guid: string; company: string; version: string; key: string }) {
    const context = { ...user };
    this.client.identify(context, "", () => {
      this._featureFlagsReady.next(true);
    });
  }

  protected _logOutClient() {
    this.client.close().then(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // tslint:disable-next-line:ban-ts-ignore
      // @ts-ignore
      delete this.client;
    });
    this._featureFlagsReady.next(false);
  }

  /**
   * T should be of type boolean | number | string
   * @param flag
   * @param defaultVal
   * @protected
   */
  _getFlag<T>(flag: string, defaultVal?: T): T {
    const flagResp = defaultVal ? this.client.variation(flag, defaultVal) : this.client.variation(flag);
    return flagResp;
  }
}

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService extends FeatureFlagWrapperClass {
  constructor() {
    super();
  }

  initializeFeatureFlag(user: { preferredLanguage: string; kind: string; guid: string; company: string; version: string; key: string }, consoleString: string) {
    console.log(`initializeFeatureFlag from ${consoleString}`, user);
    // don't initialize unless a guid is present
    if (user.guid) {
      const constInitUser = user;
      this._init(constInitUser);
    }
  }

  identifyContext(user: { preferredLanguage: string; kind: string; guid: string; company: string; version: string; key: string }) {
    if (user.guid) {
      const constInitUser = user;

      this._identity(constInitUser);
    }
  }

  disableClient() {
    this._logOutClient();
  }

  /**
   * returns an observable subject in case the feature flag service isn't ready
   * featureFlagsReady will continue to listen until flags are ready
   * @param flag
   */
  getFlag<T>(flag: string, defaultVal?: T): Observable<T> {
    const flagResponse = new Subject<T>();

    //sometimes this finishes too quickly
    setTimeout(() => {
      this.featureFlagsReady
        .pipe(
          tap((ready) => {
            if (ready) {
              const flagResp = this._getFlag<T>(flag, defaultVal);
              console.log(`ken in flagResp ${flag}: ${flagResp}`);
              flagResponse.next(flagResp);
            }
          }),
          takeWhile((ready) => !ready)
        )
        .subscribe();
    }, 0);

    // take(1) ensures any subscription to this method closes
    return flagResponse.asObservable().pipe(take(1));
  }

  checkMultipleFlags(flags: string[]): Observable<boolean> {
    const flagObservables: Array<Observable<boolean>> = [];
    flags.forEach((flag) => {
      const flagObs = this.getFlag<boolean>(flag);
      flagObservables.push(flagObs);
    });
    return zip(...flagObservables).pipe(
      take(1),
      map((values) => !values.includes(false))
    );
  }
}
