import { AUTO_MATCH_CYCLE_STATUS, AUTO_MATCH_REASON, SavingsScheduleType, SavingsSource } from "./savings-account.enum";

export interface BaseSavingsAccount {
  balance: number;
  available_balance: number;
  // do NOT use the status field; mobile-state savingsSetup tells us the correct account statuses for account creation
  // unknown how API/UX handles locked accounts
  status: string;
  // do NOT use the source field; not only is the data misleading but we ONLY support payroll autosavings at this time
  // need to use product eligibility (for new) and transfer_schedule.source (for existing)
  // if not eligible for payrollSavings product (dont allow create)
  // if transfer_schedule.source not payroll (dont allow edit)
  source?: SavingsSource;
  monthly_withdrawals_remaining: number | null;
}

export interface SavingsAccountSnapshot extends BaseSavingsAccount {
  id: number | string;
  next_deposit_amount?: number | null;
  total_rewards_balance: number;
  banks: LinkedBank[];
  autoSaveMatchReward: SavingsRewardAutoMatch | {};
  name: string;
  has_future_schedule: string;
  next_deposit_date?: string | null;
  schedule_type?: SavingsScheduleType | null;
  pay_frequency?: string;
  linked_account_status?: string; // should not use this, use banks[] instead
  schedule_paused?: string | null; // this is a date field
  is_first_schedule?: boolean;
}

export interface SavingsAccountAutosaveSnapshot {
  next_deposit_amount: number;
  has_future_schedule: string;
  next_deposit_date: string;
  schedule_type: SavingsScheduleType;
  schedule_paused?: string | null;

  is_first_schedule?: boolean;
}

export interface SavingsAch extends BaseSavingsAccount {
  routing_number: string;
  account_number: string;
}

export interface SavingsTransaction {
  label?: string; // undefined for skipped, TODO enum
  id: number | string;
  category: string; //TODO enum
  amount: number;
  source?: TransactionSource;
  cutoff_date?: string;
  estimated_arrival_date?: string | null;
  next_transfer_date?: string;
  transaction_date?: string;
  status: string; //TODO enum
  type: string; //TODO enum
  client_display?: string; // undefined for skipped TODO enum
  isPaycheck: boolean;
  isPending: boolean;
  isPendingReward: boolean;
  is_credit: boolean;
  skip_planned?: boolean; // used for skipped
  is_skipped: boolean;
  dateLabel: string;
}

export interface TransactionSource {
  type: string;
  synapse_id?: string;
  bank_name?: string;
  bank_logo?: string;
  account_class?: string;
  last_four_accnum?: string;
  merchant_name?: string;
}

export interface SavingsGoalsResponse {
  name?: string;
  status?: string;
  id?: string | number;
  message?: string;

  targetAmount?: number;
  currentAmount?: number;
  percentCompleted?: number;
}

export interface SavingsRewardAutoMatch {
  isRewardEligible: boolean;
  currentProjection: number;
  maxRewardAmountPossible: number;
  maxRewardAmount: number;
  rewards: SavingsRewardAutoMatchCycle[];
  reasons: AUTO_MATCH_REASON[];
}

export interface SavingsRewardAutoMatchCycle {
  cycle: number;
  status: AUTO_MATCH_CYCLE_STATUS;
  amount: number;
  max_amount: number;
  pay_date: string;
}

export enum TransferScheduleSource {
  PAYROLL = "payroll",
}

export class TransferScheduleOnboarding {
  schedule_type: string;
  next_transfer_date: string | Date;
  amount: number;
  source: string; // we ONLY support payroll
  action?: string; //pause/unpause/skip/update
}

export enum LinkedBankStatus {
  LINKED = "linked",
  LINKING = "linking",
  ACTIVE = "active",
  INVALID_ROUTING_CREDS = "invalid-routing-creds",
  LOCKED = "locked",
  LINK_VERIFY_DEPOSIT = "link-verify-deposit",
  //  CREDENTIALS_LOCKED = "credentials-locked" NO LONGER SUPPORTED
}

export class LinkedBank {
  id: number;
  status: LinkedBankStatus;
  type: string; // we only support type of routing now TODO make enum
  bank_name: string;
  last_four: string;
  account_class: "CHECKING" | "SAVINGS"; // TODO make enum
  create_ts: string;
  bank_code?: string;
  balance_verifiable: boolean;
  bank_logo?: string;
  micro_meta?: LinkedBankMicro | null;
}

export interface LinkedBankMicro {
  is_resend_available: boolean;
  micro_deposit_initiated_ts: string;
  remaining_verification_attempts: number;
  sent_micro_deposit_attempts: number;
}

export interface MicrodepositMetaData {
  remaining_verification_attempts: number;
  micro_deposit_initiated_ts: string;
  is_resend_available: boolean;
}
