import { Injectable } from "@angular/core";
import amplitude from "amplitude-js";

@Injectable({
  providedIn: "root",
})
export class AmplitudeService {
  constructor() {}

  _amplitudeInstance: any;

  get amplitudeInstance() {
    if (this._amplitudeInstance) {
      return this._amplitudeInstance;
    }
    this._amplitudeInstance = amplitude.getInstance();
    return this._amplitudeInstance;
  }

  logEvent(eventName: string, eventProperties?: { [key: string]: string }) {
    // tslint:disable-next-line:no-parameter-reassignment
    eventName = eventName.split("_").join(" ").toLowerCase();
    if (eventProperties) {
      this.amplitudeInstance.logEvent(eventName, eventProperties);
    } else {
      this.amplitudeInstance.logEvent(eventName);
    }
  }

  setUserId(userId: string) {
    this.amplitudeInstance.setUserId(userId);
  }
  setUserProperties(userProperties: { [key: string]: string }) {
    this.amplitudeInstance.setUserProperties(userProperties);
  }
}
