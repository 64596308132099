import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

// https://blog.hackages.io/our-solution-to-get-a-previous-route-with-angular-5-601c16621cf0
@Injectable({
  providedIn: "root",
})
export class RoutingStateService {
  private history = [];

  constructor(private router: Router) {}

  public loadRouting(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(({ urlAfterRedirects }: NavigationEnd) => {
      this.history = [...this.history, urlAfterRedirects];
    });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(numRoutesAgo = 0): string {
    const actualNumRoutesAgo = numRoutesAgo + 2;
    return this.history[this.history.length - actualNumRoutesAgo] || "/feed";
  }

  public getPreviousHome(): string {
    return "/dda/home";
  }

  isInSpendingFlow(): boolean {
    return this.getPreviousHome().includes("dda");
  }
}
