import { Consent } from "./models/consent";
import { MicroAccountsTranslationKey } from "./model/i18n/i18n.enum";

export enum ConsentType {
  DepositAccountAgreement = "DepositAccountAgreement",
  DebitCardholderAgreement = "DebitCardholderAgreement",
  CustodialAccount = "CustodialAccount",
  PayrollSavings = "PayrollSavings",
  //this key isn't actually stored in our system, it's just used to reference it in the map below.
  Withdrawal = "Withdrawal",
  FakeTerms = "FakeTerms",
  Kickforward = "Kickforward",
  CashAdvanceAgreement = "CashAdvanceAgreement",
}

export class ConsentManager {
  static InstructionalText = MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_INSTRUCTIONS;

  /**
   * NOT GOING TO TRANSLATE ONLY USED BY LOANS WHICH IS MICROAPP
   * get a vendor specific Consent object, but really only the 'key' is customized.
   * @param key
   */
  static vendorTerms(key: string): Consent {
    return {
      key,
      descriptionLink: "Brightside’s Direct Deposit and Automatic Payment Terms",
      descriptionBeforeLink: `By checking this box, you agree to use`,
    } as Consent;
  }

  static ConsentDetails: { [key: string]: Consent } = {
    DepositAccountAgreement: {
      key: "DepositAccountAgreement",
      descriptionBeforeLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_DEPOSIT_PRE_LINK,
      descriptionLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_DEPOSIT_LINK,
    } as Consent,
    DebitCardholderAgreement: {
      key: "DebitCardholderAgreement",
      descriptionBeforeLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_CARDHOLDER_PRE_LINK,
      descriptionLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_CARDHOLDER_LINK,
    } as Consent,
    FakeTerms: {
      key: ConsentType.FakeTerms,
      descriptionBeforeLink: `Seeing this will cause accepting terms call to fail.`,
      descriptionAfterLink: `Clear your 'failTerms' flag in mobile-state to fix it.`,
    } as Consent,
    CustodialAccount: {
      key: ConsentType.CustodialAccount,
      descriptionBeforeLink: "SPENDING_CONSENT_2",
    } as Consent,
    PayrollSavings: {
      key: ConsentType.PayrollSavings,
      descriptionBeforeLink: "SPENDING_CONSENT_1",
    } as Consent,
    Withdrawal: {
      key: "",
      descriptionBeforeLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_WITHDRAWAL_PRE_LINK,
    } as Consent,
    Kickforward: {
      key: ConsentType.Kickforward,
      descriptionBeforeLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_KICKFORWARD_PRE_LINK,
      descriptionLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_KICKFORWARD_LINK,
      linkKey: "BrightsideTerms",
      descriptionAfterLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_KICKFORWARD_POST_LINK,
      accepted: false,
    } as Consent,
    CashAdvanceAgreement: {
      key: ConsentType.CashAdvanceAgreement,
      descriptionBeforeLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_CASHADVANCE_PRE_LINK,
      descriptionLink: MicroAccountsTranslationKey.MICRO_ACCOUNTS_CONSENTS_CASHADVANCE_LINK,
    } as Consent,
  };
}
