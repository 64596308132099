/**
 * Created by davidhaveman on 7/25/17.
 */
import { Injectable } from "@angular/core";

import { Client } from "../models/types/client";
import { SimpleResponse } from "../models/types/simple.response";

import { HttpClient } from "@angular/common/http";
import { LocalStorage } from "@rars/ngx-webstorage";
import { Globals } from "../../globals";
import { HttpGet } from "./http.get";

@Injectable()
export class ClientService {
  @LocalStorage()
  clientName: string;

  @LocalStorage()
  client: Client;

  constructor(private http: HttpClient, private httpGet: HttpGet) {}

  async getClient(force = false): Promise<Client> {
    return this.httpGet
      .get<Client>(Globals.configs.profile, force, 300, "client")
      .toPromise()
      .then(client => {
        this.clientName = client.first_name + " " + client.last_name;
        return client;
      });
  }
}
