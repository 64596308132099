import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, ObservableInput, Subscription, throwError as observableThrowError } from "rxjs";

import { BridgeService, FirebaseActionName, FirebaseEventName, MessagingService } from "../../shared";

import { LocalStorage } from "@rars/ngx-webstorage";
import { catchError, filter, finalize, map, switchMap, take } from "rxjs/operators";
import { Globals } from "../../globals";
import { AuthService } from "./auth.service";
import { LogService } from "./log.service";
import { LoginResult } from "../../login/login-result.model";

export interface AnalyticsDetails {
  page: string;
  action?: string;
  category: string;
  subCategory?: string;
  custom?: object | unknown;
}

export interface ApiCustom {
  endpoint: string;
  status?: number;
  method: string;
}

export enum BlackListSkipFirebaseHeader {
  POLLING = "X-BS-POLLING",
}

export const POLLING_HEADER = { [BlackListSkipFirebaseHeader.POLLING]: "true" };

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, OnDestroy {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @LocalStorage()
  rawToken: string;

  @LocalStorage()
  accessToken: string;

  private subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    protected messaging: MessagingService,
    protected logService: LogService,
    protected bridgeService: BridgeService
  ) {
    this.bridgeService.exitView();
  }

  private shouldSkipFirebaseLogging(req: HttpRequest<any>): boolean {
    return req.headers.keys().includes(BlackListSkipFirebaseHeader.POLLING);
  }

  logFirebaseEvent(eventName: "request" | "response", custom: any, action?: string) {
    const instancedCustom = { ...custom };
    if (action) {
      instancedCustom.action = action;
    }

    const instance = { ...this.bridgeService.firebaseParameters, custom: instancedCustom } as AnalyticsDetails;
    this.bridgeService.firebaseEvent(eventName, instance);
  }

  logResponse(res: HttpResponse<any>, method: string, skipFirebaseLogging = false) {
    const action = res.status >= 200 && res.status <= 299 ? FirebaseActionName.Success : FirebaseActionName.Error;

    if (!skipFirebaseLogging) {
      this.logFirebaseEvent(
        FirebaseEventName.Response,
        {
          endpoint: new URL(res.url).pathname,
          status: res.status,
          method,
        },
        action
      );
    } else {
      //Log locally to console if needed
    }
  }

  logRequest(req: HttpRequest<any>, skipFirebaseLogging = false) {
    if (!skipFirebaseLogging) {
      this.logFirebaseEvent(FirebaseEventName.Request, {
        endpoint: new URL(req.url).pathname,
        method: req.method,
      });
    } else {
      //Log locally to console if needed
    }
  }

  handleDemoIntercept(): Observable<HttpEvent<any>> {
    this.bridgeService.showDemoBlock(this.bridgeService.firebaseParameters.category, this.bridgeService.firebaseParameters.subCategory);

    return Observable.throwError("");
  }

  intercept(inputreq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = inputreq.url.includes("assets/i18n") ? inputreq.url : Globals.rootUrl + inputreq.url;
    let req = inputreq.clone({
      url: reqUrl,
      setHeaders: {
        "Content-Type": "application/json",
        Authorization: this.authService.accessToken ? this.authService.accessToken : "",
      },
    });
    const skipFirebaseLogging = this.shouldSkipFirebaseLogging(req);

    //ToDo: do this in a not 1:1 way and maybe without the need for second clone...
    if (skipFirebaseLogging) {
      req = req.clone({
        headers: req.headers.delete(BlackListSkipFirebaseHeader.POLLING, "true"),
      });
    }

    this.logRequest(req, skipFirebaseLogging);

    if (this.authService.isDemoClient() && req.method !== "GET") {
      return this.handleDemoIntercept();
    }

    // based on https://github.com/IntertechInc/http-interceptor-refresh-token/blob/master/src/app/request-interceptor.service.ts
    // https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/
    // also https://gist.github.com/abereghici/054cefbdcd8ccd3ff03dcc4e5155242b#file-token-interceptor-service-ts
    return next.handle(req).pipe(
      map((resp: HttpResponse<any>) => {
        if (resp instanceof HttpResponse) {
          this.logResponse(resp, inputreq.method, skipFirebaseLogging);
        }
        return resp;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            return this.handle401Error(req, next);
          } else {
            return observableThrowError(error);
          }
        } else {
          return observableThrowError(error);
        }
      })
    );
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: token } });
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler): ObservableInput<any> {
    if (!this.isRefreshingToken) {
      this.subscription.add(
        this.logService.log(`http-interceptor: received 401 start refresh; url:${req.url}, tok:${this.rawToken ? this.rawToken.slice(-5) : ""}`).subscribe()
      );
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.subscription.add(
              this.logService.log(`http-interceptor: got new token, retry first call; url:${req.url}, tok:${newToken.slice(-5)}`).subscribe()
            );
            this.tokenSubject.next(newToken);
            return next.handle(this.addToken(req, newToken));
          }
          // If we don't get a new token, we are in trouble so logout.
          return Observable.throwError("could not get token");
        }),
        catchError((error) => {
          this.subscription.add(this.logService.log(`http-interceptor: timed out waiting for token`).subscribe());
          // If there is an exception calling 'refreshToken', bad news so logout.
          return Observable.throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      this.subscription.add(this.logService.log(`http-interceptor: received 401 already refreshing; url:${req.url}`).subscribe());
      return this.tokenSubject.pipe(
        filter((token) => !!token),
        take(1),
        switchMap((token) => {
          this.subscription.add(this.logService.log(`http-interceptor: got new token, retry waiting call; url:${req.url}, tok:${token.slice(-5)}`).subscribe());
          return next.handle(this.addToken(req, token));
        })
      );
    }
  }
}
