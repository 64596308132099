import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LoadingBarModule, LoadingBarService } from "@ngx-loading-bar/core";
import { ChartModule } from "angular-highcharts";
import { IconPathPipe } from "../financial-solutions/shared/pipes/icon-path";
import { AppLoadingComponent } from "./components/app-loading/app-loading.component";
import { LinkComponent } from "./components/buttons/link/link.component";
import { PrimaryCtaComponent } from "./components/buttons/primary-cta/primary-cta.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { CellComponent } from "./components/cell/cell.component";
import { DonutSpendingComponent } from "./components/charts/donut-spending/donut-spending.component";
import { ConsentItemComponent } from "./components/consents/consent/consent.component";
import { ConsentsComponent } from "./components/consents/consents.component";
import { DebtSliderComponent } from "./components/debt-slider/debt-slider.component";
import { ErrorRetryComponent } from "./components/error-retry/error-retry.component";
import { ActionSelectComponent } from "./components/forms/action-select/action-select.component";
import { FullpageSpinnerComponent } from "./components/fullpage.spinner";
import { GridListComponent } from "./components/grid/grid-list/grid-list.component";
import { IconComponent } from "./components/icon/icon.component";
import { ArrowBackComponent } from "./components/icons/arrow-back/arrow-back.component";
import { CloseComponent } from "./components/icons/close/close.component";
import { DownArrowComponent } from "./components/icons/down-arrow/down-arrow.component";
import { IframeNavComponent } from "./components/iframe-nav/iframe-nav.component";
import { InputComponent } from "./components/input.component";
import { FlyerComponent } from "./components/layout/flyer/flyer.component";
import { MwArticleComponent } from "./components/layout/mw-article/mw-article.component";
import { ActionListComponent } from "./components/list/action-list/action-list.component";
import { DataListComponent } from "./components/list/data-list/data-list.component";
import { ListItemComponent } from "./components/list/list-item/list-item.component";
import { LoadingSpinnerComponent } from "./components/loading.spinner";
import { LoadingComponent } from "./components/loading/loading.component";
import { Loading2Component } from "./components/loading/loading2.component";
import { MenuModalComponent } from "./components/menu.modal.component";
import { ModalComponent } from "./components/modal/modal.component";
import { NavProgressComponent } from "./components/nav-progress/nav-progress.component";
import { NavComponent } from "./components/nav/nav.component";
import { Nav2Component } from "./components/nav2/nav.component";
import { OverlayModalComponent } from "./components/overlay-modal/overlay-modal.component";
import { OverlaySpinnerComponent } from "./components/overlay-spinner/overlay-spinner.component";
import { OverlayComponent } from "./components/overlay/overlay.component";
import { ResultCardComponent } from "./components/result-card/result-card.component";
import { SectionComponent } from "./components/section.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { SubDetailComponent } from "./components/sub-detail/sub-detail.component";
import { SvgIconsComponent } from "./components/svg-icons";
import { TermsComponent } from "./components/terms/terms.component";
import { ToasterComponent } from "./components/toaster.component";
import { PageParagraphComponent } from "./components/typography/page-paragraph/page-paragraph.component";
import { PageSubtitleComponent } from "./components/typography/page-subtitle/page-subtitle.component";
import { PageTitleSubcategoryComponent } from "./components/typography/page-title-subcategory/page-title-subcategory.component";
import { PageTitleSubtitleComponent } from "./components/typography/page-title-subtitle/page-title-subtitle.component";
import { PageTitleComponent } from "./components/typography/page-title/page-title.component";
import { TypographyComponent } from "./components/typography/typography.component";
import { WaitProgressComponent } from "./components/wait-progress/wait-progress.component";
import { TextColorDirective } from "./directives/color/text-color.directive";
import { TimeoutDirective } from "./directives/timeout/timeout.directive";
import { CapitalizePipe } from "./pipes/capitalize.pipe";
import { MaxValueValidatorDirective } from "./pipes/max.value.directive";
import { PhonePipe } from "./pipes/phone.pipe";
import { Safe } from "./pipes/safeHtml";
import { TitleCaseExceptPipe } from "./pipes/title-case-except.pipe";
import { ClientService } from "./services/client.service";
import { CommonActionService } from "./services/common-action-service";
import { ErrorsService } from "./services/errors.service";
import { IntervalService } from "./services/interval.service";
import { LoanService } from "./services/loan.service";
import { LogService } from "./services/log.service";
import { StateTransferService } from "./services/state-transfer.service";
import { TermsService } from "./services/terms.service";
import { WindowService } from "./services/window.service";
import { TranslateModule } from "@ngx-translate/core";

/**
 * Created by davidhaveman on 8/23/17.
 */

@NgModule({
  imports: [CommonModule, LoadingBarModule, ChartModule, NgxSliderModule, TranslateModule],

  declarations: [
    SectionComponent,
    ModalComponent,
    CapitalizePipe,
    Safe,
    FullpageSpinnerComponent,
    LoadingSpinnerComponent,
    MaxValueValidatorDirective,
    SvgIconsComponent,
    MenuModalComponent,
    ToasterComponent,
    InputComponent,
    TermsComponent,
    ErrorRetryComponent,
    NavComponent,
    Nav2Component,
    PrimaryCtaComponent,
    LoadingComponent,
    Loading2Component,
    AppLoadingComponent,
    DownArrowComponent,
    ActionSelectComponent,
    NavProgressComponent,
    ArrowBackComponent,
    WaitProgressComponent,
    ConsentsComponent,
    ConsentItemComponent,
    CloseComponent,
    OverlayComponent,
    SpinnerComponent,
    SubDetailComponent,
    OverlaySpinnerComponent,
    PhonePipe,
    OverlayModalComponent,
    MwArticleComponent,
    CarouselComponent,
    IconPathPipe,
    TitleCaseExceptPipe,
    IframeNavComponent,
    FlyerComponent,
    PageTitleComponent,
    PageSubtitleComponent,
    PageTitleSubtitleComponent,
    TypographyComponent,
    PageParagraphComponent,
    DonutSpendingComponent,
    GridListComponent,
    IconComponent,
    ActionListComponent,
    DataListComponent,
    PageTitleSubcategoryComponent,
    LinkComponent,
    TextColorDirective,
    TimeoutDirective,
    CellComponent,
    DebtSliderComponent,
    ResultCardComponent,
    ListItemComponent,
  ],
  exports: [
    SectionComponent,
    ModalComponent,
    CapitalizePipe,
    Safe,
    FullpageSpinnerComponent,
    LoadingSpinnerComponent,
    MaxValueValidatorDirective,
    SvgIconsComponent,
    MenuModalComponent,
    ToasterComponent,
    InputComponent,
    TermsComponent,
    ErrorRetryComponent,
    NavComponent,
    Nav2Component /* TODO WB-35 move to finsol */,
    PrimaryCtaComponent,
    LoadingComponent,
    Loading2Component,
    AppLoadingComponent,
    DownArrowComponent,
    ActionSelectComponent,
    NavProgressComponent,
    ArrowBackComponent,
    WaitProgressComponent,
    ConsentsComponent,
    ConsentItemComponent,
    CloseComponent,
    SpinnerComponent,
    OverlayComponent,
    SubDetailComponent,
    OverlaySpinnerComponent,
    PhonePipe,
    OverlayModalComponent,
    MwArticleComponent,
    CarouselComponent,
    IconPathPipe,
    TitleCaseExceptPipe,
    IframeNavComponent,
    FlyerComponent,
    PageTitleComponent,
    PageSubtitleComponent,
    PageTitleSubtitleComponent,
    TypographyComponent,
    PageParagraphComponent,
    DonutSpendingComponent,
    GridListComponent,
    IconComponent,
    ActionListComponent,
    DataListComponent,
    PageTitleSubcategoryComponent,
    LinkComponent,
    TextColorDirective,
    TimeoutDirective,
    CellComponent,
    DebtSliderComponent,
    ResultCardComponent,
    ListItemComponent,
  ],
  providers: [
    TermsService,
    LoanService,
    ClientService,
    MaxValueValidatorDirective,
    CommonActionService,
    StateTransferService,
    ErrorsService,
    LogService,
    IntervalService,
    LoadingBarService,
    WindowService,
  ],
})
export class SharedModule {}
