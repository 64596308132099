/**
 * Created by davidhaveman on 7/25/17.
 */
import { Injectable } from "@angular/core";

import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { throwError } from "rxjs/internal/observable/throwError";
import { map, retry } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { Globals } from "../../globals";
import { SimpleResponse } from "../models/types/simple.response";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { from } from "rxjs";
export interface AxiosResponse {
  status: number;
}

@Injectable()
export class TermsService {
  termMap: Map<string, string> = new Map<string, string>();

  //TODO use HttpGet service for automatic caching
  constructor(private http: HttpClient) {}
  async acceptTerms(type: string): Promise<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${Globals.configs.termsLink}/acceptance?type=${type}`, {}).toPromise();
  }

  async fetchTerms(type: string): Promise<string> {
    if (this.termMap[type]) {
      return Promise.resolve(this.termMap[type]);
    }
    return this.http
      .get(Globals.configs.termsLink + "?deviceType=html&type=" + type, { responseType: "text" })
      .toPromise()
      .then((response) => {
        this.termMap[type] = response;
        return response;
      });
  }

  getTermsAcceptance(...keys: string[]): Observable<boolean> {
    return forkJoin(keys.map<Observable<AxiosResponse>>((key) => from(this.fetchTermsAcceptance(key)))).pipe(
      map((responses) => responses.every((response: AxiosResponse) => response.status === 200))
    );
  }

  fetchTermsAcceptance(type: string): Observable<HttpResponse<{}>> {
    return this.http.get(`${Globals.configs.termsLink}/acceptance?type=${type}`, { observe: "response" }).pipe(retry(3), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
