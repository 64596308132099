export enum StateNames {
  DdaTransferError = "DdaTransferError",
  LinkSuccess = "LinkSuccess",
  LinkVerifyError = "LinkVerifyError",
  LinkVerify = "LinkVerify",
  LinkRouting = "LinkRouting",
  DdaInfo = "DdaInfo",
  SavingsEducation = "SavingsEducation",
  Congrats = "Congrats",
  DdaSupport = "DdaSupport",
  LostCard = "LostCard",
  DdaReportIssue = "DdaReportIssue",
  Feed = "feed",
  ChatLanding = "ChatLanding",
  Education = "Education",
  Intro = "Intro",
  Timeout = "Timeout",
  Home = "Home",
  HomeBack = "HomeBack",
  Campaign = "Campaign",
  Pause = "Pause",
  LinkDecision = "LinkDecision",
  LinkAccountMFA = "LinkAccountMFA",
  LinkAccount = "LinkAccount",
  LinkAccountLogin = "LinkAccountLogin",
  PaycheckDeductionAgreement = "PaycheckDeductionAgreement",
  Disconnect = "Disconnect",
  Schedule = "Schedule",
  Deposit = "Deposit",
  PayrollSetup = "PayrollSetup",
  Withdraw = "Withdraw",
  Transaction = "Transaction",
  DdaTransaction = "DdaTransaction",
  DdaAllTransactions = "DdaAllTransactions",
  DdaVerifySsn = "DdaVerifySsn",
  SelectAccount = "SelectAccount",
  Routing = "Routing",
  NextSteps = "NextSteps",
  ResendDeposits = "ResendDeposits",
  Microdeposit = "Microdeposit",
  LinkEducation = "LinkEducation",
  DdaGetStarted = "DdaGetStarted",
  DdaConsent = "DdaConsent",
  ImgRetake = "ImgRetake",
  DdaLockUnlock = "DdaLockUnlock",
  LicenseCapture = "LicenseCapture",
  LicenseProgress = "LicenseProgress",
  CameraAccess = "CameraAccess" /* TODO define transitions, dont know how the user gets to this page */,
  DdaHome = "DdaHome",
  ShipCard = "ShipCard",
  CardActivation = "CardActivation",
  CreatePin = "CreatePin",
  VerifyCardNumber = "VerifyCardNumber",
  VerifyExpiration = "VerifyExpiration",
  EditAddress = "EditAddress",
  ActivationError = "ActivationError",
  DdaAccountDetails = "DdaAccountDetails",
  DdaStatements = "DdaStatements",
  DdaStatement = "DdaStatement",
  DdaFindAtm = "DdaFindAtm",
  DdaCheckProgress = "DdaCheckProgress",
  DdaTransfers = "DdaTransfers",
  DdaManageAccounts = "DdaManageAccounts",
  DdaLinkedAccountDetails = "DdaLinkedAccountDetails",
  SavingsIntro = "SavingsIntro",
  SavingsConsents = "SavingsConsents",
  SavingsAccountCreation = "SavingsAccountCreation",
  SavingsOnboardingAutosave = "SavingsOnboardingAutosave",
  SavingsOnboardingAutosaveInfo = "SavingsOnboardingAutosaveInfo",
  SavingsOnboardingAutosaveEdit = "SavingsOnboardingAutosaveEdit",
  SavingsOnboardingAutosaveComplete = "SavingsOnboardingAutosaveComplete",
  SavingsOnboardingAutosaveError = "SavingsOnboardingAutosaveError",
  SavingsHome = "SavingsHome",
  SavingsAllTransactions = "SavingsAllTransactions",
  SavingsTransactionDetails = "SavingsTransactionDetails",
  SavingsTransfers = "SavingsTransfers",
  SavingsStatements = "SavingsStatements",
  SavingsStatementList = "SavingsStatementList",
  SavingsStatementDisplay = "SavingsStatementDisplay",
  SavingsDelete = "SavingsDelete",
  SavingsDeleteSuccess = "SavingsDeleteSuccess",
  SavingsCommitIntro = "SavingsCommitIntro",
  SavingsCommitLater = "SavingsCommitLater",
  SavingsCommitComplete = "SavingsCommitComplete",
  SurveyOnboardSavings = "SurveyOnboardSavings",
  SurveyOnboardSavingsComplete = "SurveyOnboardSavingsComplete",
  CashAdvanceHome = "CashAdvanceHome",
  CashAdvanceFunding = "CashAdvanceFunding",
  CashAdvanceDetails = "CashAdvanceDetails",
  CashAdvanceOnboardingIntro = "CashAdvanceOnboardingIntro",
  CashAdvanceOnboardingConsents = "CashAdvanceOnboardingConsents",
  FinHome = "FinHome",
  FinConnectOnboardingIntro = "FinConnectOnboardingIntro",
  FinConnectOnboardingConsents = "FinConnectOnboardingConsents",
  FinConnect = "FinConnect",
  FinConnectFinal = "FinConnectFinal",
  FinConnections = "FinConnections",
  FinNoConnectionsComponent = "FinNoConnectionsComponent",
  FinConnection = "FinConnection",
  FinAccountList = "FinAccountList",
  FinCategories = "FinCategories",
  FinCategoryDetails = "FinCategoryDetails",
  FinTransactions = "FinTransactions",
  FinTransactionDetails = "FinTransactionDetails",
  FinUpdateCategory = "FinUpdateCategory",
  FinLinkedAccountReview = "FinLinkedAccountReview",
  SavingsSummary = "SavingsSummary",
  DeepSavingsAutosave = "DeepSavingsAutosave",
  DeepSavingsAutosaveNext = "DeepSavingsAutosaveNext",
  CcCrusherInformation = "CcCrusherInformation",
  CcCrusherDetails = "CcCrusherDetails",
  CcCrusher = "CcCrusher",
  BasePage = "BasePage",
  DetailsPage = "DetailsPage",
  CrusherPage = "CrushserPage",
  CrusherIntro = "CrusherIntro",
  CrusherCommit = "CrusherCommit",
  CrusherGoalConfirmation = "CrusherGoalConfirmation",
  CrusherGoal = "CrusherGoal",
  CrusherGoalDetails = "CrusherGoalDetails",
}
