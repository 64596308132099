import { Component } from "@angular/core";

/**
 * Created by davidhaveman on 8/21/17.
 */
@Component({
  selector: "app-svg-icons",
  templateUrl: "./svg-icons.html"
})
export class SvgIconsComponent {}
