import { Injectable } from "@angular/core";

export enum SavingsState {
  NON_ACTIVE = "non_active",
  IN_PROGRESS = "in_progress",
  ERROR = "error",
  CANCELING = "canceling",
  ACTIVE = "active-account",

  Unknown = "unknown"
}
//TODO rename or remove this
export enum SavingsAccountType {
  PAYROLL = "payroll",
  ACH = "ach"
}

export enum SavingsSetupState {
  UserCreating = "user-creating",
  UserCreationError = "user-creation-error",
  UserCreated = "user-created",
  ErrorFboCreation = "error-fbo-creation",
  ErrorCustodialCreation = "error-custodial-creation",
  PayrollSetup = "payroll-setup",
  CreatedAccount = "account-created",
  Canceled = "canceled",

  /* The following either are only states in front end code or not main setup status. */
  Unknown = "unknown"
}

/**
 * The following have been removed specific to ACH accounts. Ticket: SSI-112
 * ActiveAccount = "active-account",
 * Created = "created",
 * Linked = "linked",
 * CredentialsLocked = "credentials-locked"
 */

export interface SavingsSetupNeededDetails {
  accountType: SavingsAccountType;
  setupState: SavingsSetupState;
}

// Internal use to Savings state service, please don't export
type SavingsSetupStateMap = { [k in SavingsState]?: SavingsSetupState[] };
type SavingsSetupStateMapWithType = { [k in SavingsAccountType]?: SavingsSetupStateMap };

/**
 * This service is a singleton and state will be remain from use to use
 * This was done to be able to utilize it for state/setup checks as needed throughout the application.
 * Please DON'T include in any NgModule without changing expectation of being a singleton
 */
@Injectable({
  providedIn: "root"
})
export class SavingsStateService {
  // Default state map will check for any setup state non-specific to type. Each type should be given it's own check for specific type based mappings
  private stateMap: SavingsSetupStateMap = {
    [SavingsState.NON_ACTIVE]: [SavingsSetupState.Canceled, SavingsSetupState.Unknown],
    [SavingsState.IN_PROGRESS]: [SavingsSetupState.UserCreating, SavingsSetupState.UserCreated],
    [SavingsState.ERROR]: [SavingsSetupState.UserCreationError, SavingsSetupState.ErrorFboCreation, SavingsSetupState.ErrorCustodialCreation],
    [SavingsState.ACTIVE]: [SavingsSetupState.PayrollSetup]
  };

  private typeBasedStateMap: SavingsSetupStateMapWithType = {
    [SavingsAccountType.PAYROLL]: {
      [SavingsState.IN_PROGRESS]: [SavingsSetupState.CreatedAccount]
    },
    [SavingsAccountType.ACH]: {
      [SavingsState.ACTIVE]: [SavingsSetupState.CreatedAccount]
    }
  };

  private returnStateForUnknown = SavingsState.NON_ACTIVE;

  private lastSavingsAccountType: SavingsAccountType;
  private lastSavingsSetupState: SavingsSetupState;
  private lastSavingsState: SavingsState;

  constructor() {}

  get accountType(): SavingsAccountType {
    return this.lastSavingsAccountType;
  }

  get setupState(): SavingsSetupState {
    return this.lastSavingsSetupState;
  }

  get state(): SavingsState {
    return this.lastSavingsState;
  }

  public reset() {
    this.lastSavingsAccountType = undefined;
    this.lastSavingsSetupState = undefined;
    this.lastSavingsState = undefined;
  }

  setSavingsInfo(infoDetails: SavingsSetupNeededDetails) {
    this.lastSavingsAccountType = infoDetails.accountType;
    this.lastSavingsSetupState = infoDetails.setupState;
    this.lastSavingsState = this.getSavingsState(this.lastSavingsAccountType, this.lastSavingsSetupState);
  }

  getSavingsState(accountType?: SavingsAccountType, setupState?: SavingsSetupState): SavingsState {
    if (!accountType || !setupState) {
      // If nothing is provided, call again with the last used values if there
      if (this.lastSavingsAccountType && this.lastSavingsSetupState) {
        // ToDo: Should we just return the last saving state value instead?
        return this.getSavingsState(this.lastSavingsAccountType, this.lastSavingsSetupState);
      }

      return this.returnStateForUnknown;
    }

    // State will represent any setup state that is not dependent on the type of account. If there are overrides, like payroll/ach it should be found with stateWithType
    // The following has been removed specific to ACH accounts. Ticket: SSI-112 - const setupStateAfterChecks = this.checkSpecialEndsWithCases(setupState);
    const state = this.checkStateMap(setupState);
    const stateWithType = this.checkMap(setupState, this.typeBasedStateMap[accountType]);

    //Will return the state from type mapping first, then base state, and fallback to returnStateForUnknown
    return stateWithType || state;
  }

  /* The following has been removed specific to ACH accounts. Ticket: SSI-112
  private checkSpecialEndsWithCases(setupState: SavingsSetupState): SavingsSetupState {
    if (setupState.endsWith(SavingsSetupState.CredentialsLocked)) {
      return SavingsSetupState.CredentialsLocked;
    }

    return setupState;
  }
  */

  private checkStateMap(forState: SavingsSetupState): SavingsState {
    return this.checkMap(forState, this.stateMap) || this.returnStateForUnknown;
  }

  private checkMap(forState?: SavingsSetupState, withMap?: SavingsSetupStateMap): SavingsState | null {
    // If we don't have either of these we need to exit
    if (!forState || !withMap) {
      return null;
    }

    const filterMap = Object.keys(withMap).filter(state => withMap[state]?.includes(forState));

    // Filter should result in one record, if not log so the DEV can be aware
    if (filterMap.length > 1) {
      console.log("Something is wrong. We should NOT have a setup state mapped to more than one state");
    }

    return (filterMap[0] as SavingsState) || null;
  }
}
