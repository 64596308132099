import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { tap } from "rxjs/internal/operators/tap";
import { CacheHelper } from "./cache.helper";

export interface ApiGetHeaders {
  [header: string]: string | string[];
}

@Injectable()
export class HttpGet {
  constructor(private http: HttpClient, private cacheHelper: CacheHelper) {}

  get<T>(url: string, force = false, duration = 30, key?: string, options?: { headers?: HttpHeaders | ApiGetHeaders }): Observable<T> {
    const keyToUse = key ? key : url.toLowerCase();
    // we are not going to cache empty responses
    if (!force && this.cacheHelper.isValid(keyToUse) && this.cacheHelper.get(keyToUse)) {
      return Observable.of(this.cacheHelper.get(keyToUse));
    } else {
      return this.http.get<T>(url, options).pipe(
        tap(response => {
          this.cacheHelper.start(keyToUse, duration, response, force);
        })
      );
    }
  }
}
