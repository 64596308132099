import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

type GenericObject = Record<string, any>;

/**
 * copied verbatim from brightside-web
 */
@Injectable({
  providedIn: "root",
})
export class MicroUtilTranslationService extends TranslateService implements TranslateService {
  static Language = {
    English: { code: "en", label: "English" },
    Spanish: { code: "es", label: "Spanish" },
    Testing: { code: "zz", label: "Testing" },
  };

  private allowVerboseDebugMode = false;

  private logForDebugging(message?: any, ...optionalParams: any[]) {
    if (this.allowVerboseDebugMode) {
      console.log("MicroUtilTranslationService Debugger", message, optionalParams);
    }
  }

  /**
   * This is a passthrough to the underlying translation service that will return
   * the current (or if undefined the default) lang code
   */
  get currentCurrentLanguageCode(): string {
    return this.currentLang || this.defaultLang;
  }

  setLanguageAs(newLang: string) {
    //Only support en and es
    if (["en", "es"].includes(newLang)) {
      this.use(newLang);
    }
  }

  /**
   * This should be utilized from the module class for your micro application
   * only if you have local copy to add into the translation mapping.
   *
   * @param translations { [key: string]: string }
   */
  updateTranslate(translations: { [key: string]: string }): void {
    this.setTranslation(this.currentLang, translations, true);
    this.setLanguageAs(this.currentLang);
  }

  /**
   * Utilize this to traverse a template object and look to replace
   * copyKeys with pre-rendered text. This can be used to replace dynamic
   * values in the copy string since the template will not have any data
   * to do this.
   *
   * @param processObject type GenericObject = Record<string, any>;
   * @param replaceMapping { [key: string]: string } - key (Should be the copyKey) : value (Should be the display copy you want shown)
   * @returns <any>
   */
  deepCopyKeySwapForDynamicValue(processObject: GenericObject, replaceMapping: { [key: string]: string }): any {
    if (!processObject || typeof processObject !== "object") {
      return processObject;
    }

    const replaceKeys = Object.keys(replaceMapping);

    let rtnObj = {};

    Object.keys(processObject).forEach((key: string) => {
      let value = (processObject as any)[key];

      if (value) {
        if (Array.isArray(value)) {
          value = value.map((arryValue) => this.deepCopyKeySwapForDynamicValue(arryValue, replaceMapping));
        } else if (replaceKeys.includes(value)) {
          value = replaceMapping[value];
        } else if (typeof value === "object") {
          value = this.deepCopyKeySwapForDynamicValue(value, replaceMapping);
        }
      }

      rtnObj = { ...rtnObj, [key]: value };
    });

    return rtnObj;
  }
}
