import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Event, NavigationError, Router } from "@angular/router";
import { Observable } from "rxjs/Rx";
import { mergeMap } from "rxjs/operators";
import * as StackTrace from "stacktrace-js";
import { AuthService } from "./auth.service";
import { CacheHelper } from "./cache.helper";
import { LogService } from "./log.service";
import { BridgeService } from "./bridge.service";
import { AmplitudeService } from "./amplitude.service";
import { serializeError } from "serialize-error";

@Injectable()
export class ErrorsService {
  constructor(
    private injector: Injector,
    private router: Router,
    private logService: LogService,
    private auth: AuthService,
    private cache: CacheHelper,
    private bridgeService: BridgeService,
    private amplitudeService: AmplitudeService
  ) {
    // Subscribe to the NavigationError
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationError) {
        this.log(event.error).subscribe();
      }
    });
  }

  log(error): Observable<any> {
    const lastError = this.cache.get("error");
    let thisError = "";
    if (error.message) {
      thisError = error.message;
    } else {
      try {
        thisError = JSON.stringify(error);
      } catch {
        //ignore
      }
    }
    //dont want to log the same error repeatedly
    if (lastError === thisError) {
      return Observable.of();
    }
    this.cache.start("error", 30, thisError);
    // Log the error to the console
    console.error(error);
    // increment total errors viewed property
    this.bridgeService.emitIncrement("total errors viewed");
    this.amplitudeService.logEvent(`error_logged`, serializeError(error));
    // Send error to server
    return Observable.fromPromise(this.addContextInfo(error)).pipe(mergeMap((errorToSend) => this.logService.log(errorToSend)));
  }

  async addContextInfo(error) {
    const name = error.name || null;
    const user = this.auth.getTokenField("custom:guid") || "not-authed";
    const time = new Date().toISOString();
    const id = `${user}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : "";
    const agent = navigator.userAgent;
    const message = error.message || error.toString();
    let stack = null;
    if (!(error instanceof HttpErrorResponse)) {
      // we have to do an await to get source mapping on the stack
      const frames = await StackTrace.fromError(error);
      if (frames && frames.length) {
        stack = frames[0];
      }
    }
    return { name, user, time, id, url, agent, message, stack };
  }
}
