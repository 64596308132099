/**
 * Created by davidhaveman on 7/25/17.
 */

import { Type } from "@angular/core";
import { CardTypeComponent } from "./card.type.interface";

export class CardInput {
  constructor(public case_type: string, public action: string, public state?: string) {}
}

export class CardAction {
  //code friendly value for the action.

  constructor(options: { label: string; code: string; route?: string }) {
    this.code = options.code || "";
    this.label = options.label || "";
    this.route = options.route;
  }

  url = "";
  code: string;
  //text the user sees
  label: string;
  //terms and conditions required to accept before moving on.
  termsType = "";
  accepted = false;
  custom = false;
  //ui navigation elsewhere
  route: string;
  cssClass: string;
}

export class CardCaseDetails {
  type: string;
  sub_type: string;
  case_number: string;
}

export class Card {
  cssClass: string;
  name: string;
  state: string;
  action: string;
  text: string;
  componentType: string;
  accountState: string;
  case_type: string;
  type: string;
  icon: string;
  route: string[];
  actions: CardAction[];
  caseStatus: string;
  sort_order: number;
  case: CardCaseDetails;
  properties: CardProperty[];

  component: Type<CardTypeComponent>;

  public static getProperty<T>(key: string, props: CardProperty[]): T {
    if (props == null) {
      console.error("no properties given looking for key '" + key + "'!");
      return null;
    }
    for (const prop of props) {
      if (prop.key === key) {
        return prop.value as T;
      }
    }

    return null;
  }
}

export class CardProperty {
  key: string;
  value: any;
}
