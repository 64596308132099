import { Injectable } from "@angular/core";

import * as firebase from "firebase/app";
import "firebase/remote-config";
import "firebase/analytics";

import { Globals } from "../../globals";
const getEnvironment = () => {
  const hostname = window.location.hostname.toLowerCase();

  if (Globals.getConstants().production) {
    return "prod";
  } else if (hostname.includes("stage")) {
    return "stage";
  } else {
    return "dev2";
  }
};
@Injectable({
  providedIn: "root",
})
export class FirebaseRemoteConfigureService {
  constructor() {
    const fb = firebase.initializeApp({
      ...Globals.getConstants().firebaseAppConfig,
    });
    firebase.analytics(fb).setUserProperties({
      environment: getEnvironment(),
    });
  }

  public setUpConfig(defaultConfig?: { [key: string]: string | number | boolean }, defaultSupportNumber: string = "") {
    let remoteConfig: firebase.remoteConfig.RemoteConfig;

    remoteConfig = firebase.remoteConfig();

    if (defaultConfig) {
      remoteConfig.defaultConfig = defaultConfig;
    }

    remoteConfig.settings.minimumFetchIntervalMillis = Globals.getConstants().production ? 300000 : 15000;
    remoteConfig.fetchAndActivate().then(() => {
      Globals.getConstants().remoteConfig = remoteConfig;
    });
  }
}
