import { Component, Input } from "@angular/core";
/**
 * Created by davidhaveman on 8/21/17.
 */
@Component({
  selector: "app-fullpage-spinner",
  template: `
    <div id="loading-page" *ngIf="enabled" style="height: 100vh;">
      <div>
        <ng-container *ngIf="aboveSpinner">
          <p class="custom-text">{{ aboveSpinner }}</p>
          <br />
        </ng-container>
        <img src="../../../assets/images/loading.gif" [style.width.%]="16" alt="Loading" />
        <ng-container *ngIf="belowSpinner">
          <br />
          <p class="custom-text">{{ belowSpinner }}</p>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="!enabled">
      <ng-content></ng-content>
    </ng-container>
  `,

  styles: [
    `
      #loading-page {
        background-color: white;
        text-align: center;
        height: 100%;
      }

      #loading-page div {
        position: absolute;
        top: 35%;
        width: 100%;
      }

      #loading-page div img {
        text-align: center;
        top: 35%;
      }

      .custom-text {
        font-size: 16px;
        width: 80vw;
        color: #333;
        font-weight: bold;
        margin-left: 10vw;
        font-weight: 500;
        line-height: 19px;
      }
    `
  ]
})
export class FullpageSpinnerComponent {
  public opacity = 0.3;

  @Input("size")
  size = 35;

  @Input("aboveSpinner")
  aboveSpinner = null;

  @Input("belowSpinner")
  belowSpinner = null;

  public _enabled = false;

  @Input("enabled") set enabled(value: boolean) {
    this._enabled = value;
  }

  get enabled(): boolean {
    return this._enabled;
  }
}
