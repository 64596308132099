import { AnalyticsDetails } from "../services/http.interceptor";

import * as firebase from "firebase";
import RemoteConfig = firebase.remoteConfig.RemoteConfig;

export * from "./types/asset";
export * from "./types/liability";
export * from "./types/account.abstract";
export * from "./types/card";
export * from "./types/card.type.form.interface";
export * from "./types/client";
export * from "./types/cardType";
export * from "./types/config";
export * from "./types/simple.response";
export * from "./types/card.type.directive";
export * from "./types/menu.option";

export class ServerConstants {
  amplitude: { apiKey: string };
  production: boolean;
  apiUrl: string;
  apiDemoUrl: string;
  supportPhone: string;
  internalOwnerNames: string;
  gaPropertyId: string;
  loanVendors: LoanVendor[];
  synapsePublicKey: string;
  featureFlags: { [key: string]: boolean | string };
  configurableSettings: any;
  demoCompanyNames: string[];
  loadingTimeoutMS?: number;
  microApplication?: { [key: string]: MicroApplication };
  firebaseAppConfig?: { [key: string]: string };
  remoteConfig?: RemoteConfig;
  sentryDsn: string;
  environmentName: string;
  featureFlagApp: LaunchDarklyInitInterface;
}
interface LaunchDarklyInitInterface {
  clientSideId: string;
}
export interface MicroApplication {
  url: string;
  routes: { [key: string]: string };
}

export enum ServerFeatureKey {
  EXTERNAL_SAVINGS = "externalSavings",
}

export enum FirebaseEventName {
  Click = "click",
  View = "view",
  Error = "error",
  ErrorView = "error_view",
  Debug = "debug",
  Request = "request",
  Response = "response",
}
export enum FirebaseCategory {
  Smartwallet = "smartwallet",
  Loan = "loan",
}
export enum FirebaseSubcategory {
  Spending = "spending",
  SpendingOnb = "spending_onb",
  Savings = "savings",
  SavingsOnb = "savings_onb",
  CashAdvance = "cash_advance",
  Cashflow = "cashflow",
  DebtCrusher = "debt_crusher",
}
export enum FirebasePageName {
  PinActivateError = "pin_activate_error",
  AllTransactions = "all_transactions",
  PinActivateSuccess = "pin_activate_success",
  LockUnlockCard = "lock_unlock_card",
  ReportIssue = "report-issue",
  LostCard = "lost_card",
  HowTo = "how_to",
  NativeCam = "native_cam",
  LicenseProgress = "license_progress",
  CameraSettings = "camera_settings",
  DdaConsent = "dda_consent",
  GetStarted = "get_started",
  LicenseCapture = "license_capture",
  AccountDetails = "account_details",
  AccountList = "account_list",
  AdvanceState = "advance_state",
  AdvanceReenable = "advance_reenable",
  AdvanceIntro = "advance_intro",
  AdvanceConsent = "advance_consent",
  AdvanceFunding = "advance_funding",
  AdvanceHome = "advance_home",
  CreatePin = "create_pin",
  Home = "home",
  HomeActionMenu = "home_action_menu",
  LinkedAccountDetails = "linked_account_details",
  ManageAccounts = "manage_accounts",
  HomeLinkAccountModal = "home_link_account_modal",
  AccountCreation = "account_creation",
  Autosave = "autosave",
  AutosaveNativeOnboarding = "autosave_native_onboarding",
  AutosaveDeepCreation = "autosave_deep_creation",
  AutosaveDeepUpdate = "autosave_deep_update",
  AutosaveComplete = "autosave_complete",
  AutosaveError = "autosave_error",
  AutosavePrompt = "autosave_prompt",
  Consents = "consents",
  TransactionDetailsSavings = "transaction-details-savings",
  LinkVerify = "link-verify",
  LinkRouting = "link-routing",
  Intro = "intro",
  ShipCongrats = "ship_congrats",
  EditAddress = "edit_address",
  ShipCard = "ship_card",
  TransferError = "transfer_error",
  Transfers = "transfers",
  VerifyCardNum = "verify_card_num",
  VerifyCardErrorModal = "verify_card_error_modal",
  VerifyExpiration = "verify_expiration",
  VerifySsn = "verify_ssn",
  Kickforward = "kickforward",
  KickforwardSavings = "kickforward_savings",
  Payback = "payback",
  SavingsPrompt = "savings_prompt",
  SavingsSummary = "savings_summary",
  LinkPrompt = "link_prompt",
  LinkIntent = "link_intent",
  LinkLogin = "link_login",
  Mfa = "mfa",
  Subaccount = "subaccount",
  Carousel = "carousel",
  ErrorRetry = "error_retry",
  NextSteps = "next_steps",
  ResendDeposits = "resend_deposits",
  LinkVerifyError = "link_verify_error",
  CashflowNoConnections = "cashflow_noaccounts",
  CashflowHome = "cashflow_home",
  CashflowConnections = "cashflow_connections",
  CashflowConnectionDetails = "cashflow_connection_details",
  CashflowConnect = "cashflow_connect",
  CashflowConnectFinal = "cashflow_connect_final",
}
export enum FirebaseActionName {
  Undefined = "undefined",
  Submit = "submit",
  Open = "open",
  Success = "success",
  Error = "error",
  Invalid = "invalid",
  Refresh = "refresh",
  Dismiss = "dismissed",
  Logout = "logout",
  On = "on",
  Off = "off",
  Toggle = "toggle",
  Route = "route",
  Copy = "copy",
  Select = "select",
  Tapped = "tapped",
  Shown = "shown",
}

/**
 * analytics poto.
 */
export class FirebaseEvent {
  /**
   *
   * @param {string} event_name event name
   * @param {string} parameters parameter, if required, such as URL, Phone Number, or path clicked.
   */
  constructor(public event_name: string, public parameters: AnalyticsDetails) {
    if (parameters && parameters.custom && typeof parameters.custom === "object") {
      parameters.custom = JSON.stringify(parameters.custom);
    }
  }
}

export interface LoanVendor {
  name: string;
  icon_path: string;
  code: string;
  external_link?: string;
  terms_and_conditions: TermsAndConditionsRef[];
}

export interface TermsAndConditionsRef {
  key: string;
  type: string;
}
