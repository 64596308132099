import { Component, Input } from "@angular/core";
/**
 * Created by davidhaveman on 8/21/17.
 */
@Component({
  selector: "mw-loading-spinner",
  template: `
    <div *ngIf="enabled" align="center" style="margin-left: 20%; margin-right: 20%; width:50%; z-index: 100; padding: 3px;">
      <br *ngIf="text" />
      <div *ngIf="spinner">
        <div class="abs spinner" width="50%" height="50%">
          <img src="../../assets/ico/spinner.svg" alt="Loading" />
        </div>
        <div *ngIf="text" class="h2">{{ text }}</div>
        <div [style.opacity]="opacity" class="abs" align="left">
          <ng-content *ngIf="enabled"></ng-content>
        </div>
      </div>
    </div>
  `,

  styles: [
    `
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      .abs {
        position: absolute;
        text-align: center;
      }
      .h2 {
        text-align: center;
        margin: 0px 0px auto;
      }

      .spinner {
        animation: rotate 1s linear infinite;
        height: 200px;
        width: 200px;
      }
    `,
  ],
})
export class LoadingSpinnerComponent {
  public opacity = 0.3;
  /**
   * NOT GOING TO TRANSLATE ONLY USED BY FEED/SAVINGS
   */
  @Input("text")
  text = "Loading";

  @Input("spinner")
  spinner = true;

  public _enabled = false;

  @Input("enabled") set enabled(value: boolean) {
    this._enabled = value;
    this.opacity = this._enabled ? 0.3 : 1.0;
  }

  get enabled(): boolean {
    return this._enabled;
  }
}
