import { Injectable } from "@angular/core";
import { CanLoad, Route, Router, UrlSegment } from "@angular/router";
import { Observable } from "rxjs/Rx";
import { BridgeService, DeviceType } from "./shared";

@Injectable()
export class FeedGuard implements CanLoad {
  constructor(protected router: Router, protected bridge: BridgeService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const deviceType = this.bridge.getDeviceType();
    if (deviceType !== DeviceType.iOS && deviceType !== DeviceType.Android) {
      return true;
    }

    const version = this.bridge.getDeviceVersion();
    if (version < "1.16") {
      return true;
    } else {
      this.bridge.feed();
      this.router.navigate(["nothing"]);

      return false;
    }
  }
}
