import { DepositAccountResponse, DepositCard, DepositCardTransaction, DigitalDepositCard } from "../shared/models/vendor/synapse/synapse";
import {
  CashAdvanceLatestDetails,
  CashAdvanceOptions,
  CashAdvanceRepayment,
  CashAdvanceRepaymentStatus,
  CashAdvanceResponse,
  CashAdvanceStatus,
} from "../financial-solutions/shared/services/cash_advance.service";
import { LinkedBank, LinkedBankStatus } from "../financial-solutions/shared/services/savings-account/savings-account.interface";

const getSpendingCard = (params: Record<string, any>): DepositCard => ({
  id: params.id ?? 123,
  status: params.status ?? "active",
  last_four_card_num: params.last_four_card_num ?? "1234",
  name: params.name ?? "name",
  expiry_date: params.expiry_date ?? "12/2025",
  details: params.details ?? "details",
  shipping_requested_ts: params.shipping_requested_ts ?? "2023-05-22",
});
const getDepositAccount = (params: Record<string, any>): DepositAccountResponse => ({
  balance: params.balance ?? 100,
  last_four_acct_num: params.last_four_acct_num ?? "1234",
  cards: params.cards ?? [getSpendingCard(params)],
  name: params.name ?? "Spending Account",
  account_create_date: params.account_create_date ?? "2022-05-01",
  banks: params.banks ?? [],
  latest_statement: params.latest_statement ?? undefined,
});

const getSpendingTransaction = (params: Record<string, any>): DepositCardTransaction => ({
  id: params.id ?? 3,
  type: params.type ?? "foo",
  is_disputable: params.is_disputable ?? false,
  is_disputed: params.is_disputed ?? false,
  amount: params.amount ?? 17,
  merchant_name: params.merchant_name ?? "merchant",
  status: params.status ?? "COMPLETE",
  brightside_initiated: params.brightside_initiated ?? false,
  date: params.date ?? "2023-01-01",
  is_credit: params.is_credit ?? false,
  dispute_date: params.dispute_date ?? undefined,
});

const getCashAdvanceLatestDetails = (params: Record<string, any>): CashAdvanceLatestDetails => ({
  repay_optout: params.repay_optout ?? false,
  remaining_balance: params.remaining_balance ?? 15,
  status: params.status ?? CashAdvanceStatus.ACTIVE,
});
const getCashAdvanceRepayment = (params: Record<string, any>): CashAdvanceRepayment => ({
  repay_optout: params.repay_optout ?? false,
  advance_amount: params.advance_amount ?? 100,
  cash_advance_id: params.cash_advance_id ?? 1,
  cash_repayment_id: params.cash_repayment_id ?? 2,
  repay_amount: params.repay_amount ?? 50,
  advance_taken_date: params.advance_taken_date ?? "2023-05-02",
  deduction_date: params.deduction_date ?? "2023-06-10",
  expected_repay_date: params.expected_repay_date ?? "2023-12-20",
  status: params.status ?? CashAdvanceRepaymentStatus.PLANNED,
});
const getCashAdvanceOptions = (params: Record<string, any>): CashAdvanceOptions => ({
  spending: params.spending ?? { amount: 15 },
  external_debit: params.external_debit ?? { amount: 25 },
});
const getCashAdvanceResponse = (params: Record<string, any>): CashAdvanceResponse => ({
  is_eligible: params.is_eligible ?? true,
  has_error: params.has_error ?? false,
  existing_cash_advances: params.existing_cash_advances ?? true,
  next_pay_date: params.next_pay_date ?? "2023-07-04",
  next_eligible_date: params.next_eligible_date ?? "2023-08-04",
  cut_off_date: params.cut_off_date ?? "2023-06-15",
  cash_advance_options: params.cash_advance_options ?? getCashAdvanceOptions(params),
  cash_advance_repayments: params.cash_advance_repayments ?? [getCashAdvanceRepayment(params)],
  latest_cash_advance_details: params.latest_cash_advance_details ?? getCashAdvanceLatestDetails(params),
});
const getLinkedBank = (params: Record<string, any>): LinkedBank => ({
  id: params.id ?? 123,
  bank_logo: params.bank_logo ?? "hello.gif",
  bank_name: params.bank_name ?? "bank name",
  bank_code: params.bank_code ?? "code",
  balance_verifiable: params.balance_verifiable ?? true,
  status: params.status ?? LinkedBankStatus.LINKED,
  create_ts: params.create_ts ?? "2023-05-01",
  account_class: params.account_class ?? "SAVINGS",
  micro_meta: params.micro_meta ?? {},
  last_four: params.last_four ?? "1234",
  type: params.type ?? "type",
});
const getDigitalDepositCard = (params: Record<string, any>): DigitalDepositCard => ({
  cvc: params.cvc ?? "333",
  card_number: params.card_number ?? "1234432112344321",
  last_four_card_num: params.last_four_card_num ?? "4444",
  details: params.details ?? "details",
  expiry_date: params.expiry_date ?? "12-2025",
  name: params.name ?? "name",
  shipping_requested_ts: params.shipping_requested_ts ?? "2023-05-15",
  last_four: params.last_four ?? "5555",
  status: params.status ?? "status",
  id: params.id ?? 123,
});
const factory: Record<string, any> = {
  SpendingTransaction: getSpendingTransaction,
  SpendingCard: getSpendingCard,
  DepositAccount: getDepositAccount,
  CashAdvanceResponse: getCashAdvanceResponse,
  LinkedBank: getLinkedBank,
  DigitalDepositCard: getDigitalDepositCard,
};
/**
 * TODO merge this class with the SavingsTestData
 */
export class AccountsTestData {
  static generateTestObject = <T>(objType: string, params?: Record<string, any>): T => {
    const obj = factory[objType](params ?? {});
    //this will remove the empty fields (but not Date/NaN/functions)
    return JSON.parse(JSON.stringify(obj, (kk, vv) => vv ?? undefined));
  };
}
