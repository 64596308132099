import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { MobileService } from "../shared/services/mobile.service";
import { map } from "rxjs/operators";
import { BridgeService } from "../shared";

@Injectable({
  providedIn: "root"
})
export class FunctionalSpendingGuard implements CanActivate, CanLoad {
  constructor(private mobileSvc: MobileService, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkStatus();
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkStatus();
  }

  checkStatus(): Observable<boolean> {
    return this.mobileSvc.fetchMobileState().pipe(
      map(response => {
        if (MobileService.hasFunctionalSpendingAccount(response)) {
          return true;
        }
        this.router.navigate(["dda-setup"]);
        return false;
      })
    );
  }
}
