import { EventEmitter, Injectable } from "@angular/core";
import { Globals } from "../../globals";
import { filter, first } from "rxjs/operators";

interface MessageEvent {
  code: string;
}

export enum MessageKey {
  AUTH = "auth ",
  ANIMATION_COMPLETE = "animation_complete",
  CHAT = "chat-activated",
  DDA_SETUP = "depositSetup",
  FOREGROUND = "foregrounded",
  HALF_SHEET = "halfSheet ",
  LOAD_ACCT = "load-acct",
  LOAD_TX = "load-tx",
  LOAD_MS = "load-mobile-state",
  LOAD_SAVINGS_TRANS = "load-savings-transactions",
  NEW_TOKEN = "NEWTOKEN",
  PAYROLL_CREATED = "payroll-created",
  PERMISSION_CAMERA_ALLOWED = "camera_authorized",
  PERMISSION_CAMERA_BLOCKED = "camera_denied",
  REFRESH_CARDS = "refresh-cards",
  REFRESH_DDA = "refresh-dda",
}

@Injectable()
export class MessagingService {
  private debounceLastMessage = "";
  private debounceTimeout;
  private debounceAnyMatchingMessages = false;

  /**
   * this method will check if we should be debouncing the event or letting it through
   * @param message: string - from native
   * @returns boolean true = broadcast message false = suppress
   */
  private shouldAllowMessageToPublish(message: string): boolean {
    if (this.debounceAnyMatchingMessages && message === this.debounceLastMessage) {
      return false;
    }

    clearTimeout(this.debounceTimeout);

    this.debounceLastMessage = message;
    this.debounceAnyMatchingMessages = true;
    this.debounceTimeout = setTimeout(() => {
      this.debounceLastMessage = "";
      this.debounceAnyMatchingMessages = false;
    }, 500);

    return true;
  }

  /**
   * emits string | { [key: string]: any }
   */
  getEmitter(): EventEmitter<any> {
    return Globals.messageBus;
  }

  getEmitterOnce(filterFunc: (event: any) => boolean) {
    return this.getEmitter().pipe(filter(filterFunc), first());
  }

  getEmitterForKeys(keys: MessageKey[] = []) {
    return this.getEmitter().pipe(filter((resultEvent) => this.filterByKeys(resultEvent, keys)));
  }

  getEmitterForKeysOnce(keys: MessageKey[] = []) {
    return this.getEmitter().pipe(
      filter((resultEvent) => this.filterByKeys(resultEvent, keys)),
      first()
    );
  }

  /**
   * filters a pipe response from message bus for certain keys
   */
  filterByKeys(event: any, keys: MessageKey[]): boolean {
    const evt = `${event.code || event}`;
    let foundMatch = false;

    keys.forEach((key) => {
      if (evt.includes(key)) {
        foundMatch = true;
        return;
      }
    });

    return foundMatch;
  }

  /**
   *
   * @param message string | { [key: string]: any }
   */
  publish(message: any): void {
    if (this.shouldAllowMessageToPublish(message as string)) {
      Globals.messageBus.emit(message);
    }
  }
}
