import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService, SessionStorageService } from "@rars/ngx-webstorage";
import { from, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { MiniMoment } from "../../globals";

class CacheElement {
  started: Date;
  validity: number;

  constructor(validity: number) {
    this.started = new Date();
    this.validity = validity;
  }
}

@Injectable({
  providedIn: "root"
})
export class CacheHelper {
  //CACHE KEYS MUST BE IN LOWER CASE, LOCAL STORAGE KEYS ARE AUTOMATICALLY LOWERCASED (BUT SESSION ARE NOT)

  constructor(private http: HttpClient, private localStorageService: LocalStorageService, private session: SessionStorageService) {
    if (!this.session.retrieve("cacheKeys")) {
      this.session.store("cacheKeys", {});
    }
  }

  public start(key: string, validity = 30, data?: any, force = false): void {
    const lowerKey = key.toLowerCase();
    let ck = this.session.retrieve("cacheKeys");
    if (!ck) {
      ck = {};
    }

    ck[lowerKey] = new CacheElement(validity);

    this.session.store("cacheKeys", ck);

    if (data || force) {
      this.localStorageService.store(lowerKey, data);
    }
  }

  public isValid(key: string): boolean {
    const lowerKey = key.toLowerCase();
    const ck = this.session.retrieve("cacheKeys");
    if (!ck) {
      this.session.store("cacheKeys", {});
      return false;
    }
    const element = ck[lowerKey];

    if (!element) {
      return false;
    }
    const seconds = MiniMoment.seconds(new Date(element.started));

    return seconds < element.validity;
  }

  public get<T>(key: string): T | null {
    const lowerKey = key.toLowerCase();
    if (this.isValid(lowerKey)) {
      const value = this.localStorageService.retrieve(lowerKey);
      if (Array.isArray(value)) {
        // tslint:disable-next-line:ban-ts-ignore
        // @ts-ignore
        return [...value];
      } else if (value && typeof value === "object") {
        return { ...value };
      } else {
        return value;
      }
    } else {
      return null;
    }
  }

  public getApi<T>(key: string, path: string): Observable<T> {
    const cached = this.get<T>(`${key}__${path}`);
    if (cached) {
      return of(cached);
    } else {
      return from(this.http.get<T>(path)).pipe(tap(response => this.start(key, 30, response)));
    }
  }

  public flush(key?: string) {
    if (key) {
      const lowerKey = key.toLowerCase();
      this.localStorageService.clear(lowerKey);
      const ck = this.session.retrieve("cacheKeys");
      // tslint:disable-next-line:no-dynamic-delete
      delete ck[lowerKey];
      this.session.store("cacheKeys", ck);
    } else {
      this.localStorageService.clear();
      this.session.clear("cacheKeys");
    }
  }
}
