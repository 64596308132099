import { Injectable } from "@angular/core";
import { AmplitudeService } from "./amplitude.service";

@Injectable({
  providedIn: "root",
})
export class WindowService {
  constructor(protected amplitudeService: AmplitudeService) {}

  setLocationHref(locationHref: string) {
    this.amplitudeService.logEvent(`web_exited`, { url: window.location.hostname });
    window.location.href = locationHref;
  }
}
