import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CardAction } from "../models/types/card";
import { BridgeService } from "./bridge.service";

/**
 * this is a new class to consolidate as much of the CardAction processing as possible.
 *
 * Currently it does not handle:
 *
 * refreshing the current card
 * or submitting the card to the card service.
 *
 */
@Injectable()
export class CommonActionService {
  constructor(protected bridge: BridgeService, protected router: Router) {}

  /**
   * handle an cardAction that is activated
   *
   * @param {CardAction} action the action details
   * @returns {boolean} true if handled, false otherwise.
   */
  processAction(action: CardAction): boolean {
    if (action.route) {
      this.bridge.handleRouteThatMayContainFeed(action.route);
    } else if (action.code === "call") {
      this.bridge.phoneCall();
    } else if (action.code === "external-link") {
      this.bridge.externalLink(action.url);
    } else if (action.code === "chat") {
      this.bridge.showChat();
    } else {
      return false;
    }

    return true;
  }
}
