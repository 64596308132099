import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { BridgeService, CardService } from "../shared";
import { Globals } from "../globals";
import { WindowService } from "../shared/services/window.service";

@Component({
  selector: "app-partner-referral",
  templateUrl: "./partner-referral.component.html",
  styleUrls: ["./partner-referral.component.scss", "../../styles/main.scss"],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class PartnerReferralComponent implements OnInit {
  attemptsTried = 0;
  allowedAttempts = 3;
  attemptDelay = 250;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cardService: CardService,
    private bridgeService: BridgeService,
    private windowService: WindowService
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      const cardCaseType = paramMap.get("cardCaseType");
      this.getPathFromCardService(cardCaseType);
    });
  }

  getPathFromCardService(cardCaseType: string) {
    this.attemptsTried++;
    this.cardService.getCtaPathOnCard(cardCaseType, true).subscribe((path: string) => {
      if (path) {
        this.windowService.setLocationHref(`${Globals.getConstants().microApplication.payrollLoan.url}${path}`);
      } else if (this.attemptsTried <= this.allowedAttempts) {
        setTimeout(() => {
          this.getPathFromCardService(cardCaseType);
        }, this.attemptDelay);
      } else {
        this.bridgeService.feed();
      }
    });
  }
}
