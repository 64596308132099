/**
 * Created by davidhaveman on 7/25/17.
 */

export class Client {
  public username: string;
  public first_name: string;
  public relationship: string;
  public last_name: string;
  public middle_name: string;
  public employee_id: string;
  public owner_name: string;
  public company: string;

  public mobile_phone: string;
  public home_phone: string;
  public work_phone: string;

  public email_1: string;
  public email_2: string;
  public email_3: string;

  public preferred_email: string;
  public preferred_phone: string;
}
