import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { ErrorsService } from "./errors.service";

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    const errorsService = this.injector.get(ErrorsService);

    errorsService.log(error).subscribe();
  }
}
