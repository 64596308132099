import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import { Globals } from "../../globals";
import { LoanRedirect } from "../../loans/payroll-loan.service";
import { HttpGet } from "./http.get";

export class LoanOfferLink {
  name: string;
  link: string;
  logo = "lendingclub.png";
  terms: string;
}

@Injectable()
export class LoanService {
  constructor(private httpGet: HttpGet, private http: HttpClient) {}

  async loans(loanType: string): Promise<LoanOfferLink[]> {
    return this.httpGet
      .get<LoanOfferLink[]>(Globals.configs.loansLink + "/" + loanType)
      .toPromise()
      .then(offers => {
        for (const offer of offers) {
          offer.logo = "../assets/images/" + offer.logo;
        }

        return offers;
      });
  }

  getLoanRedirectUrl(provider: string): Observable<LoanRedirect> {
    console.log(this.http);
    return this.http.post<LoanRedirect>(`loans/link?provider=${provider}&product=${"ACH Payment"}`, {});
  }
}
