import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { HttpGet, ApiGetHeaders } from "./http.get";
import { CacheHelper } from "./cache.helper";
import { filter, map, switchMap } from "rxjs/operators";
import { Card, ServerConstants } from "../models";
import { BridgeService } from "./bridge.service";
import { BlackListSkipFirebaseHeader } from "./http.interceptor";
import { MessagingService } from "./messaging.service";

import { SavingsAccountSnapshotService } from "../../financial-solutions/shared/services/savings-account/savings-account-snapshot.service";

declare var CONSTANTS: ServerConstants;

export interface MobileStateRedirect {
  duration?: number;
  redirect?: string;
}

export interface ActivatedCard {
  state: string;
  ctaPath: string;
  case_type: string;
  title: string;
}

export interface CardSegment {
  icon: string;
  title: string;
  id: string;
  subText?: string;
  ctaPath?: string;
  condition?: string;
}

export interface ProductCard extends ActivatedCard {
  errorText?: string;
  segments?: [CardSegment];
}

export interface LoanWelcomeCard extends ActivatedCard {
  subText?: string;
  closeable?: boolean;
  graphic: string;
  cta: string;
  type: string;
  style: string;
}

/**
 * the name of the enum matches the status used in RDS, the value of the enum matches the status used in mobile_state
 */
export enum DepositSetupStatus {
  initiated = "initiated",
  canceled = "canceled",
  userCreationError = "user-creation-error",
  docsInvalid = "document-verification-error",
  userCreating = "user-creating",
  userCreated = "user-created",
  docsVerified = "document-verification-success",
  accountSetup = "account-setup",
}

export interface MobileState {
  readonly flags?: { [key: string]: boolean };
  readonly savingsSetup?: { status: string };
  readonly ignoreRedirect?: boolean;
  readonly user_guid?: string;
  readonly survey?: object;
  // @deprecated, do not use anymore.
  readonly cards?: Card[];
  readonly redirectComplete?: boolean;
  readonly savingsRefresh?: number;
  readonly device?: object;
  readonly depositSetup?: { payload?: any; status: DepositSetupStatus; invalid_reasons?: string[] };
  readonly document_submitted_ts?: string;
  temporaryRedirect?: MobileStateRedirect;
  readonly sessionTimeout?: number;
  readonly supportNumber?: string;
  readonly cashAdvanceConsentAccepted?: boolean;
  readonly payrollEnabled?: boolean;
  readonly products?: string[];
  readonly creditCardCrusher?: { lastRefresh: number; goalId: number };

  //start to use this for all card related needs.
  readonly activatedCards?: ActivatedCard[];
}

export const MobileStateKey = `client/mobilestate`;
export const MobileStateProductsKey = "product_access_list";

export const AutoSavePromptKey = "autosave_prompt_key";
export const LinkingPromptKey = "linking_prompt_key";

export const OneWeekSeconds = 600000;

export enum ProductType {
  PAYROLL_SAVINGS = "payrollSavings",
  ACH_SAVINGS = "achSavings",
  CREDIT_SCORE = "creditScore",
  SPENDING = "spending",
  CASH_ADVANCE = "cashAdvance",
  LOAN = "loan",
}

const NoDepositAccount = [undefined, DepositSetupStatus.canceled];
const FailedDepositCreation = [DepositSetupStatus.userCreationError, DepositSetupStatus.docsInvalid];
const PendingDepositCreation = [DepositSetupStatus.initiated, DepositSetupStatus.userCreated, DepositSetupStatus.userCreating, DepositSetupStatus.docsVerified];
export const FunctionalDepositAccount = [DepositSetupStatus.accountSetup];
const DepositWaitingRoom = [...FailedDepositCreation, ...PendingDepositCreation];

@Injectable()
export class MobileService {
  readonly oneWeekSeconds = OneWeekSeconds; // not exactly a week but I like round numbers

  constants?: ServerConstants = typeof CONSTANTS === "object" ? CONSTANTS : null;

  constructor(
    private httpClient: HttpClient,
    private httpGet: HttpGet,
    private cache: CacheHelper,
    private bridge: BridgeService,
    private msg: MessagingService,
    private savingsAccountSnapshotService: SavingsAccountSnapshotService
  ) {}

  private pollingHeader: ApiGetHeaders = { [BlackListSkipFirebaseHeader.POLLING]: "true" };

  updateMobileState(state: MobileState): Observable<void> {
    return this.httpClient.put<void>(MobileStateKey, state);
  }

  public checkMobileStateFlag(flag: string, defaultValue: boolean, nonProdOnly = true) {
    if (nonProdOnly && this.constants && this.constants.production === true) {
      return;
    }

    const mobileState: MobileState = this.cache.get(MobileStateKey);
    if (!mobileState || !mobileState.flags || typeof mobileState.flags[flag] !== "boolean") {
      return defaultValue;
    }

    return mobileState.flags[flag];
  }

  fetchMobileState(force = false, fromPollingEvent: boolean = false): Observable<MobileState> {
    // adding query parameters to bypass android http interception
    const url = force ? `client/mobilestate?force=true` : MobileStateKey;

    return this.httpGet
      .get<MobileState>(
        url,
        force,
        120,
        MobileStateKey,
        this.bridge.isBrowser() ? { headers: { "X-BS-AppVersion": "9.9.9", ...(fromPollingEvent ? this.pollingHeader : {}) } } : undefined
      )
      .pipe(
        map((ms) => {
          this.cache.start(MobileStateProductsKey, this.oneWeekSeconds, ms.products || []);

          return ms;
        })
      );
  }

  fetchMobileStateWithPush(force = false): Observable<MobileState> {
    return this.msg.getEmitter().pipe(
      filter((evt) => evt === "foregrounded" || evt.code === "depositSetup" || evt === "load-mobile-state" || evt.code === "refresh-cards"),
      switchMap((evt) => this.fetchMobileState(force || evt !== "load-mobile-state", true))
    );
  }

  fetchMobileStateFromCache(): MobileState {
    return this.cache.get(MobileStateKey);
  }

  flushMobileState() {
    this.cache.flush(MobileStateKey);
  }

  //DO NOT USE OUTSIDE OF SAVINGS_GUARD - Without addressing cache missing
  isMissingLinkedAccount() {
    //ToDo: backup if mobilestate is missing from cache
    const currentMs = this.fetchMobileStateFromCache();

    if (!currentMs) {
      return false;
    }

    return !MobileService.hasFunctionalSpendingAccount(currentMs) && !this.hasLinkedAccountWaitingVerification();
  }

  static hasFunctionalSpendingAccount(ms: MobileState): boolean {
    return FunctionalDepositAccount.includes(ms?.depositSetup?.status);
  }

  static hasNoSpendingAccount(ms: MobileState): boolean {
    return NoDepositAccount.includes(ms.depositSetup?.status);
  }

  static hasWaitingSpendingAccount(ms: MobileState): boolean {
    return DepositWaitingRoom.includes(ms.depositSetup?.status);
  }

  static hasProductAccessInProducts(type: ProductType, products: string[] = []) {
    return products.indexOf(type) > -1;
  }

  hasLinkedAccountWaitingVerification() {
    return this.savingsAccountSnapshotService.hasLinkedOrWaitingAccount;
  }

  hasProductAccess(type: ProductType) {
    const productList: string[] = this.cache.get<string[]>(MobileStateProductsKey);
    return MobileService.hasProductAccessInProducts(type, productList);
  }

  hasAccessToAutosave(ms: MobileState) {
    //First let's check if there is an active schedule
    if (this.savingsAccountSnapshotService.autoSave.hasSetupBefore) {
      return true;
    }

    return MobileService.hasProductAccessInProducts(ProductType.PAYROLL_SAVINGS, ms.products);
  }

  getAutoSavePrompt() {
    return this.cache.get(AutoSavePromptKey) || false;
  }

  getLinkingPrompt() {
    return this.cache.get(LinkingPromptKey) || false;
  }
}
