import { Injectable } from "@angular/core";
import { LocalStorage, LocalStorageService, SessionStorage, SessionStorageService } from "@rars/ngx-webstorage";
import * as moment from "moment";
import { CookieService } from "ngx-cookie-service";
import { Observable, ReplaySubject } from "rxjs";
import { take, tap, timeout } from "rxjs/operators";
import { Globals } from "../../globals";
import { LoginResult } from "../../login/login-result.model";
import { Client } from "../models";
import { BridgeService } from "./bridge.service";
import { CacheHelper } from "./cache.helper";
import { LogService } from "./log.service";
import { MessageKey, MessagingService } from "./messaging.service";
import { FeatureFlagService } from "./feature-flag.service";

declare let ga: Function;

@Injectable()
export class AuthService {
  @LocalStorage()
  rawToken: string;
  @LocalStorage()
  accessToken: string;
  @SessionStorage()
  webLoginResult: LoginResult;

  @LocalStorage()
  Credentials: LoginResult;

  @LocalStorage()
  client: Client;

  @LocalStorage()
  company: string;

  //replay the most recent message from the last 500 ms
  replay = new ReplaySubject<string>(1, 500);

  constructor(
    private storage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    protected cacheHelper: CacheHelper,
    private bridge: BridgeService,
    private message: MessagingService,
    private cookie: CookieService,
    private featureFlagService: FeatureFlagService
  ) {
    this.bridge.exitView();
    //forward any auth tokens from the message service to the replay subject
    this.message
      .getEmitter()
      .filter((msg) => msg && typeof msg === "string" && msg.indexOf("auth ") === 0)
      .map((msg) => msg.substr(5))
      .subscribe(this.replay);
  }

  getTokenField(field: string): string {
    const parsedToken = Globals.parseJwt(this.rawToken);
    return parsedToken[field];
  }

  private setGuid(token): void {
    const parsedToken = Globals.parseJwt(token);
    const guid = parsedToken["custom:guid"];

    console.log(`Setting userId in GA to ${guid}`);
    ga("set", "&uid", guid);
  }

  public saveRawToken(token: string): void {
    if (token && this.rawToken !== token) {
      this.setGuid(token);
      this.rawToken = token;
      this.cookie.set("auth", token, undefined, undefined, "/");
      this.message.publish(MessageKey.NEW_TOKEN);
    }
  }

  public saveAccessToken(accessToken: string): void {
    if (accessToken && this.accessToken !== accessToken) {
      this.accessToken = accessToken;
    }
  }

  public setCredentials(credentials: LoginResult): void {
    if (credentials.IdToken) {
      this.Credentials = credentials;
      this.saveRawToken(this.Credentials.IdToken);
      this.accessToken = credentials.AccessToken;
      const flagUser = {
        kind: "user",
        guid: this.getTokenField("custom:guid") ?? "",
        key: this.getTokenField("custom:guid") ?? "",
        company: this.getTokenField("custom:company") ?? "",
        preferredLanguage: this.getTokenField("locale") ?? "en",
        version: "1.88.0",
      };

      this.featureFlagService.initializeFeatureFlag(flagUser, "auth.service");
    }
  }

  public checkValidity(): boolean {
    if (!this.rawToken) {
      return false;
    } else {
      const tokenExp: number = parseInt(this.getTokenField("exp"), 10);
      return moment.unix(tokenExp).isAfter(moment());
    }
  }

  public clear(): void {
    this.storage.clear();
  }

  public clearToken(): void {
    this.rawToken = undefined;
    this.cookie.delete("auth", "/");
    this.bridge.handleAuthFailure();
  }

  getClientName(): string {
    if (this.client) {
      return this.client.first_name + " " + this.client.last_name;
    } else {
      const raw = this.rawToken;
      if (raw && this.checkValidity()) {
        const token = this.parseJwt(raw);
        return `${token["given_name"]} ${token["family_name"]}`;
      }
    }
  }

  getClientFirstName(): string {
    if (this.client) {
      return this.client.first_name;
    } else {
      const raw = this.rawToken;
      if (raw && this.checkValidity()) {
        const token = this.parseJwt(raw);
        return token["given_name"];
      }
    }
  }

  getClientCompany(): string {
    if (this.company) {
      return this.company;
    } else {
      const raw = this.rawToken;
      if (raw && this.checkValidity()) {
        const token = this.parseJwt(raw);
        return token["custom:company"];
      }
    }
  }

  isComcastClient(): boolean {
    const company = this.getClientCompany();

    return company && company.toLowerCase() === "comcast";
  }

  isB4BClient(): boolean {
    const company = this.getClientCompany();

    return company && company.toLowerCase() === "brightside";
  }

  isDemoClient(): boolean {
    const company = this.getClientCompany();
    /** Defaulted to [] instead of ["demo"] to avoid hardcoded in two places. In PROD this is not needed anyway because a true DEMO client will never need */
    const demoList = Globals.getConstants().demoCompanyNames || [];

    // Only valid for logging in on mobile web side
    if (!company) {
      return false;
    }
    return demoList.indexOf(company.toLowerCase()) !== -1;
  }

  parseJwt(token): any {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }

  refreshToken(): Observable<string> {
    const currToken = this.rawToken;
    this.clearToken();
    return this.replay
      .filter((newTok) => newTok !== currToken)
      .pipe(
        tap((newTok) => this.saveRawToken(newTok)),
        take(1),
        timeout(3000)
      );
  }
}
