/**
 * Created by davidhaveman on 8/2/17.
 */
import { Injectable } from "@angular/core";

const _window = (): any => {
  // return the global native browser window object
  return window;
};

@Injectable()
export class WindowRef {
  get nativeWindow(): any {
    return _window();
  }
}
