<!-- svg refs -->
<svg
  style="position: absolute; width: 0; height: 0; overflow: hidden;"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <defs>
    <symbol id="icon-arrow-left" viewBox="0 0 18 32">
      <title>arrow-left</title>
      <path
        d="M1.016 16c0 0.599 0.186 1.143 0.557 1.578l12.070 13.605c0.743 0.816 1.903 0.816 2.646-0.054 0.696-0.871 0.696-2.231-0.046-3.102l-10.677-12.027 10.677-12.027c0.743-0.816 0.743-2.231 0.046-3.102s-1.903-0.871-2.646-0.054l-12.070 13.605c-0.371 0.435-0.557 0.98-0.557 1.578z"
      ></path>
    </symbol>
    <symbol id="icon-arrow-right" viewBox="0 0 18 32">
      <title>arrow-right</title>
      <path
        d="M16.985 16c0 0.599-0.186 1.143-0.557 1.578l-12.070 13.605c-0.743 0.816-1.903 0.816-2.646-0.054-0.696-0.871-0.696-2.231 0.046-3.102l10.677-12.027-10.677-12.027c-0.743-0.816-0.743-2.231-0.046-3.102s1.903-0.871 2.646-0.054l12.070 13.605c0.371 0.435 0.557 0.98 0.557 1.578z"
      ></path>
    </symbol>
    <symbol id="icon-arrow-top" viewBox="0 0 32 32">
      <title>arrow-top</title>
      <path
        d="M16 8.015c-0.599 0-1.143 0.186-1.578 0.557l-13.605 12.070c-0.816 0.743-0.816 1.903 0.054 2.646 0.871 0.696 2.231 0.696 3.102-0.046l12.027-10.677 12.027 10.677c0.816 0.743 2.231 0.743 3.102 0.046s0.871-1.903 0.054-2.646l-13.605-12.070c-0.435-0.371-0.98-0.557-1.578-0.557z"
      ></path>
    </symbol>
    <symbol id="icon-arrow-bottom" viewBox="0 0 32 32">
      <title>arrow-bottom</title>
      <path
        d="M16 23.985c-0.599 0-1.143-0.186-1.578-0.557l-13.605-12.070c-0.816-0.743-0.816-1.903 0.054-2.646 0.871-0.696 2.231-0.696 3.102 0.046l12.027 10.677 12.027-10.677c0.816-0.743 2.231-0.743 3.102-0.046s0.871 1.903 0.054 2.646l-13.605 12.070c-0.435 0.371-0.98 0.557-1.578 0.557z"
      ></path>
    </symbol>
    <symbol id="icon-file-edit" viewBox="0 0 32 32">
      <title>file-edit</title>
      <path
        d="M31.611 16.69l-3.777-3.779c-0.519-0.519-1.369-0.519-1.888 0.002l-12.018 12.016-1.406 7.071 7.070-1.406 12.018-12.016c0.52-0.519 0.52-1.369 0-1.888zM18.278 28.133l-2.358 0.47 0.47-2.358 10.501-10.498 1.89 1.888-10.502 10.498z"
      ></path>
      <path
        d="M9.198 29.333h-6.57v-26.667h9.198v6.667c0 1.472 1.176 2.667 2.628 2.667l9.198-0.001v-2.665l-9.198-9.333h-11.826c-1.451 0-2.628 1.194-2.628 2.667v26.667c0 1.471 1.177 2.666 2.628 2.666h6.57v-2.667zM14.454 3.77l5.482 5.563h-5.482v-5.563z"
      ></path>
    </symbol>
    <symbol id="icon-trash-can-control" viewBox="0 0 31 32">
      <title>trash-can-control</title>
      <path d="M14.056 16h2.555v10.407h-2.555v-10.407z"></path>
      <path d="M8.944 16h2.556v10.407h-2.556v-10.407z"></path>
      <path d="M19.167 16h2.556v10.407h-2.556v-10.407z"></path>
      <path
        d="M28.111 5.593h-6.389v-2.602c0-1.431-1.151-2.602-2.556-2.602h-7.667c-1.405 0-2.556 1.171-2.556 2.602v2.602h-6.389c-1.405 0-2.556 1.17-2.556 2.601v5.204h2.556v15.61c0 1.431 1.15 2.602 2.555 2.602h20.445c1.405 0 2.555-1.171 2.555-2.602v-15.61h2.556v-5.204c0-1.431-1.15-2.601-2.556-2.601zM11.5 2.992h7.667v2.602h-7.667v-2.602zM25.556 29.008h-20.445v-15.61h20.445v15.61zM28.111 10.796h-25.555v-2.602h25.555v2.602z"
      ></path>
    </symbol>
    <symbol id="icon-Cross" viewBox="0 0 32 32">
      <title>Cross</title>
      <path d="M1.314 5.086l25.6 25.6c1.041 1.041 2.73 1.041 3.771 0s1.041-2.73 0-3.771l-25.6-25.6c-1.041-1.041-2.73-1.041-3.771 0s-1.041 2.73 0 3.771z"></path>
      <path
        d="M5.086 30.686l25.6-25.6c1.041-1.041 1.041-2.73 0-3.771s-2.73-1.041-3.771 0l-25.6 25.6c-1.041 1.041-1.041 2.73 0 3.771s2.73 1.041 3.771 0z"
      ></path>
    </symbol>
    <symbol id="icon-log-out" viewBox="0 0 32 32">
      <title>log-out</title>
      <path
        d="M23.626 2.592c-0.428-0.168-1.018-0.592-1.626-0.592-1.106 0-2 0.894-2 2s1.226 1.67 2 2c3.572 2.078 6 5.57 6 10 0 6.626-5.374 12-12 12s-12-5.374-12-12c0-4.43 2.428-7.922 6-10 0.708-0.198 2-0.894 2-2s-0.894-2-2-2c-0.61 0-1.2 0.35-1.626 0.592-5.046 2.57-8.374 7.292-8.374 13.408 0 8.836 7.164 16 16 16s16-7.164 16-16c0-6.116-3.328-10.838-8.374-13.408zM16 18c1.106 0 2-0.894 2-2v-14c0-1.106-0.894-2-2-2s-2 0.894-2 2v14c0 1.106 0.894 2 2 2z"
      ></path>
    </symbol>
    <symbol id="icon-assistant" viewBox="0 0 32 32">
      <title>assistan</title>
      <path
        d="M16 4.8c-3.628 0-6.108 2.958-5.511 6.577l0.903 5.477c0.358 2.172 2.431 3.947 4.608 3.947s4.25-1.775 4.608-3.947l0.903-5.477c0.597-3.619-1.884-6.577-5.511-6.577z"
      ></path>
      <path
        d="M26.667 15.999c0.737 0 1.333-0.595 1.333-1.334v-3.777c0-0.737-0.596-1.333-1.333-1.333h-1.112c0-5.276-4.279-9.555-9.555-9.555s-9.555 4.278-9.555 9.555l-1.112 0.001c-0.737 0-1.333 0.595-1.333 1.334v3.777c0 0.737 0.596 1.333 1.333 1.333h3.112v-6.445c0-4.172 3.383-7.555 7.555-7.555s7.555 3.383 7.555 7.555v6.445l3.112-0.001z"
      ></path>
      <path
        d="M27.451 22.721l-6.211-1.921c-1.379 1.278-3.25 2.096-5.24 2.096s-3.861-0.817-5.238-2.096l-6.212 1.921c-1.182 0.365-2.15 1.611-2.15 2.766v6.513h27.2v-6.514c0.001-1.155-0.966-2.401-2.149-2.765z"
      ></path>
    </symbol>
    <symbol id="icon-cheque" viewBox="0 0 32 32">
      <title>cheque</title>
      <path
        d="M31.333 11.333c0.367 0 0.667 0.299 0.667 0.667v17.333c0 0.367-0.3 0.667-0.667 0.667h-30.667c-0.368 0-0.667-0.3-0.667-0.667v-17.333c0-0.368 0.299-0.667 0.667-0.667h9.172l-5.255 5.252c-0.22 0.219-0.385 0.487-0.483 0.783l-1.333 4c-0.517 1.555 0.964 3.051 2.531 2.528l4-1.333c0.295-0.097 0.563-0.263 0.781-0.483l10.748-10.747h10.505zM18.811 20.963c0 0.577 0.484 1.037 1.081 1.037h7.027c0.6 0 1.081-0.464 1.081-1.037 0-0.577-0.484-1.037-1.081-1.037h-7.027c-0.6 0-1.081 0.464-1.081 1.037zM13.477 24.963c0 0.577 0.484 1.037 1.081 1.037h12.36c0.6 0 1.081-0.464 1.081-1.037 0-0.577-0.484-1.037-1.081-1.037h-12.36c-0.6 0-1.081 0.464-1.081 1.037z"
      ></path>
      <path
        d="M18.811 20.963c0-0.573 0.481-1.037 1.081-1.037h7.027c0.597 0 1.081 0.46 1.081 1.037 0 0.573-0.481 1.037-1.081 1.037h-7.027c-0.597 0-1.081-0.46-1.081-1.037zM13.477 24.963c0-0.573 0.481-1.037 1.081-1.037h12.36c0.597 0 1.081 0.46 1.081 1.037 0 0.573-0.481 1.037-1.081 1.037h-12.36c-0.597 0-1.081-0.46-1.081-1.037z"
      ></path>
      <path d="M4.538 21.319c-0.154 0.46 0.292 0.89 0.745 0.737l3.185-1.052-2.868-2.844-1.061 3.159z"></path>
      <path d="M6.955 16.542l7.992-7.992 3.158 3.158-7.992 7.992z"></path>
      <path
        d="M23.322 5.757l-2.353-2.333c-0.229-0.228-0.602-0.228-0.832 0l-1.644 1.629-1.054-1.045c-0.229-0.228-0.602-0.228-0.832 0l-3.529 3.5c-0.229 0.228-0.229 0.597 0 0.825s0.602 0.227 0.832 0l3.113-3.088 0.639 0.633-1.227 1.217 3.185 3.158 3.702-3.671c0.229-0.228 0.229-0.596 0-0.824z"
      ></path>
    </symbol>
    <symbol id="icon-home-bar" viewBox="0 0 32 32">
      <title>home-bar</title>
      <path d="M16 0l-16 16 1.886 1.886 2.114-2.114v16.229h9.333v-10.666h5.334v10.666h9.333v-16.229l2.114 2.114 1.886-1.886z"></path>
    </symbol>
    <symbol id="icon-more" viewBox="0 0 32 32">
      <title>more</title>
      <path d="M8 16c0 2.209-1.791 4-4 4s-4-1.791-4-4c0-2.209 1.791-4 4-4s4 1.791 4 4z"></path>
      <path d="M20 16c0 2.209-1.791 4-4 4s-4-1.791-4-4c0-2.209 1.791-4 4-4s4 1.791 4 4z"></path>
      <path d="M32 16c0 2.209-1.791 4-4 4s-4-1.791-4-4c0-2.209 1.791-4 4-4s4 1.791 4 4z"></path>
    </symbol>
    <symbol id="icon-profile" viewBox="0 0 32 32">
      <title>profile</title>
      <path
        d="M23.157 7.771c0.776-4.275-2.446-7.771-7.158-7.771v0c-4.71 0-7.932 3.496-7.158 7.771l1.172 6.475c0.466 2.566 3.159 4.664 5.986 4.664v0c2.828 0 5.52-2.099 5.984-4.664l1.173-6.475z"
      ></path>
      <path
        d="M21.603 18.909c-1.504 1.446-3.494 2.365-5.603 2.365s-4.099-0.919-5.603-2.365l-7.867 2.683c-1.392 0.474-2.53 2.091-2.53 3.59v6.819h32v-6.819c0-1.499-1.138-3.116-2.53-3.59l-7.867-2.683z"
      ></path>
    </symbol>
    <symbol id="icon-person" viewBox="0 0 32 32">
      <title>person</title>
      <path
        d="M23.157 7.771c0.776-4.275-2.446-7.771-7.158-7.771v0c-4.71 0-7.932 3.496-7.158 7.771l1.172 6.475c0.466 2.566 3.159 4.664 5.986 4.664v0c2.828 0 5.52-2.099 5.984-4.664l1.173-6.475z"
      ></path>
      <path
        d="M21.603 18.909c-1.504 1.446-3.494 2.365-5.603 2.365s-4.099-0.919-5.603-2.365l-7.867 2.683c-1.392 0.474-2.53 2.091-2.53 3.59v6.819h32v-6.819c0-1.499-1.138-3.116-2.53-3.59l-7.867-2.683z"
      ></path>
    </symbol>
    <symbol id="icon-student-hat" viewBox="0 0 32 32">
      <title>student-hat</title>
      <path
        d="M15.408 16.904c-0.207 0-0.413-0.025-0.613-0.076l-8.818-2.202v6.682c0 0.167 0.065 0.326 0.185 0.444 1.873 1.873 6.007 2.541 9.246 2.541s7.498-0.668 9.37-2.541c0.118-0.118 0.312-0.277 0.312-0.444v-6.481l-8.998 2.018c-0.183 0.039-0.497 0.060-0.684 0.060z"
      ></path>
      <path
        d="M30.246 8.858l-14.667-2c-0.059-0.009-0.118-0.013-0.179-0.013-0.067 0-0.132 0.005-0.199 0.015l-13.333 2c-0.629 0.095-1.102 0.62-1.134 1.255-0.030 0.635 0.393 1.203 1.009 1.357 0 0 13.853 3.629 13.952 3.607l10.443-2.368 0.513-0.175 0.003 7.642c0 0.369 0.203 0.964 1.060 0.964s1.15-0.595 1.15-0.964v-8.481l1.229-0.218c0.629-0.144 1.198-0.716 1.17-1.358-0.026-0.645-0.377-1.178-1.017-1.264z"
      ></path>
    </symbol>
    <symbol id="icon-wallet" viewBox="0 0 32 32">
      <title>wallet</title>
      <path
        d="M27.333 0.8h-22.667c-0.529 0-1.033 0.092-1.505 0.253 1.716 0.383 5.669 1.34 15.116 3.747l3.701 1.333c1.797 1.187 3.087 3.203 3.315 5.333l0.040 1.333v12h2c2.573 0 4.667-2.093 4.667-4.667v-14.667c0-2.573-2.093-4.667-4.667-4.667z"
      ></path>
      <path
        d="M19.112 7.221c-0.671-0.17-15.986-4.093-16.679-4.093-1.335 0-1.565 1.689-1.633 3.074 0 0 0 16.185 0 16.215 0 2.331 1.844 4.66 4.113 5.194l13.888 3.269c2.455 0.574 4.399-1.14 4.399-3.486v-14.933c0-2.327-1.771-4.662-4.088-5.24zM16.978 21.795c-1.716 0-3.111-1.395-3.111-3.111 0-1.715 1.395-3.111 3.111-3.111 1.715 0 3.111 1.396 3.111 3.111 0 1.716-1.396 3.111-3.111 3.111z"
      ></path>
    </symbol>
  </defs>
</svg>
