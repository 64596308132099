import { Component, OnDestroy, OnInit } from "@angular/core";
import { SessionStorage } from "@rars/ngx-webstorage";

import { NavigationEnd, NavigationStart, Router, RouterEvent, RouterOutlet } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { slideIn } from "./shared/animations/slide-in";
import { AuthService } from "./shared/services/auth.service";
import { BridgeService, DeviceType, InjectedData } from "./shared/services/bridge.service";
import { CacheHelper } from "./shared/services/cache.helper";
import { LogService } from "./shared/services/log.service";
import { MobileStateKey } from "./shared/services/mobile.service";
import { RoutingStateService } from "./shared/services/routing-state.service";
import { AmplitudeService } from "./shared/services/amplitude.service";
import { Globals } from "./globals";
import { MessageKey, MessagingService } from "./shared";
import { TranslateService } from "@ngx-translate/core";
import * as Sentry from "@sentry/browser";
import { FeatureFlagService } from "./shared/services/feature-flag.service";

declare var Injected: InjectedData;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  animations: [slideIn],
})
export class AppComponent implements OnInit, OnDestroy {
  hideNavigation: string[] = [
    "/savings",
    "/savi",
    "/self-lender",
    "/salary-finance",
    "/loans",
    "/consent",
    "/profile/terms",
    "/lendingclub",
    "/finsol",
    "/dda",
    "/dda-setup",
  ];
  @SessionStorage()
  navigationState: string;

  public isBrowser;
  hideLoading = true;
  logSub = new Subscription();

  getEnvironment(): string {
    const hostname = window.location.hostname.toLowerCase();

    if (Globals.getConstants().production) {
      return "prod";
    } else if (hostname.includes("stage")) {
      return "stage";
    } else {
      return "dev2";
    }
  }

  ngOnInit(): void {
    this.bridge.getDeviceType();
    this.bridge.exitView();
    this.isBrowser = this.bridge.getDeviceType() === DeviceType.Browser;

    if (typeof Injected === "object") {
      if (Injected.injectedTs == null) {
        Injected.injectedTs = new Date();
      }
      if (Injected.mobileState) {
        //cache the mobile-state value so the next API call will see it and be like, cool it's already there!
        this.cacheHelper.start(MobileStateKey, 30, Injected.mobileState);
      }
      this.auth.saveRawToken(Injected.authToken);
      this.auth.saveAccessToken(Injected.token.accessToken);
    }

    const flagUser = {
      kind: "user",
      guid: this.auth.getTokenField("custom:guid") ?? "",
      key: this.auth.getTokenField("custom:guid") ?? "",
      company: this.auth.getTokenField("custom:company") ?? "",
      preferredLanguage: this.auth.getTokenField("locale") ?? "en",
      version: "1.88.0",
    };

    this.featureFlagService.initializeFeatureFlag(flagUser, "app.component");

    this.translateService.use(this.auth.getTokenField("locale") ?? this.auth.getTokenField("custom:locale"));
    this.amplitudeService.setUserId(this.auth.getTokenField("custom:guid"));
    this.amplitudeService.setUserProperties({
      environment: this.getEnvironment(),
      company: this.auth.getTokenField("custom:company"),
    });

    Sentry.configureScope((scope) => {
      scope.setUser({ id: this.auth.getTokenField("custom:guid") });
    });

    //TODO we need to hook into the navigation events and show spinner during navigation
  }

  ngOnDestroy() {
    this.logSub.unsubscribe();
  }

  constructor(
    private router: Router,
    private bridge: BridgeService,
    private routingState: RoutingStateService,
    private cookies: CookieService,
    private auth: AuthService,
    private logger: LogService,
    private cacheHelper: CacheHelper,
    protected amplitudeService: AmplitudeService,
    private messagingService: MessagingService,
    private translateService: TranslateService,
    private featureFlagService: FeatureFlagService
  ) {
    this.routingState.loadRouting();
    this.router.events.subscribe((event: RouterEvent) => {
      this.handleNavigationEvent(event);
    });
    this.logSub.add(
      this.messagingService.getEmitter().subscribe((message) => {
        if (message === MessageKey.NEW_TOKEN) {
          this.translateService.use(this.auth.getTokenField("locale") ?? this.auth.getTokenField("custom:locale"));
        }
      })
    );
  }

  public handleNavigationEvent(event: RouterEvent) {
    if (event instanceof NavigationEnd && event.urlAfterRedirects === "/feed" && event.url !== "/feed") {
      console.log(`[deep_link_error]: ${event.url} is invalid`);
      this.bridge.firebaseEvent("deep_link_error", {
        page: "unknown",
        category: "unknown",
        custom: { url: event.url },
      });
    }
    if (event instanceof NavigationStart && event.url.includes("deposit")) {
      this.hideLoading = false;
    } else if (event instanceof NavigationEnd) {
      this.checkNavigationState(event.urlAfterRedirects);
      this.bridge.recordPageView(event.urlAfterRedirects);
      if (event.url.includes("deposit")) {
        this.hideLoading = true;
      } else if (event.url.includes("savings/create") || event.url.includes("dda-setup/license-capture")) {
        //Do nothing here right now but these cases end up
        //going to a micro app that could require special handling later
      } else {
        this.bridge.pageLoaded();
      }
    }
  }

  checkNavigationState(url: string): void {
    let set = false;
    for (const nav of this.hideNavigation) {
      if (url.indexOf(nav) === 0) {
        if ((this.navigationState && this.navigationState === "shown") || !this.navigationState) {
          this.bridge.hideNavigation();
        }
        this.navigationState = "hidden";
        set = true;
        break;
      }
    }

    if (set) {
      return;
    }

    if (this.navigationState === "hidden") {
      this.bridge.showNavigation();
    }

    this.navigationState = "shown";
  }

  /*
   * AppComponent defines a method that can detect when a view changes.
   * The method assigns an animation state value to the animation * trigger (@routeAnimation)
   * based on the route configuration data property value.
   */
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"];
  }
}
