import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

@Injectable()
export class StateTransferService {
  private linkAcctModalState = new Subject<any>();

  sendLinkAcctState(popModalState: any) {
    this.linkAcctModalState.next(popModalState);
  }

  getLinkAcctState(): Observable<any> {
    return this.linkAcctModalState.asObservable();
  }
}
