import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpGet, ServerConstants } from "../../../shared";
import { TranslateService } from "@ngx-translate/core";
import { AccountsTestData } from "../../../dda/accounts-test-data";

declare var CONSTANTS: ServerConstants;

export interface CashAdvancePayload {
  amount: number;
  disbursement_destination_type: string;
}
export interface CashAdvanceResponse {
  is_eligible: boolean;
  has_error?: boolean;
  existing_cash_advances: boolean;
  next_pay_date: string;
  next_eligible_date: string;
  cut_off_date: string;
  cash_advance_options?: CashAdvanceOptions;
  cash_advance_repayments?: CashAdvanceRepayment[];
  latest_cash_advance_details?: CashAdvanceLatestDetails;
}

export interface CashAdvanceOptions {
  spending?: CashAdvanceOption;
  external_debit?: CashAdvanceOption;
}

export interface CashAdvanceOption {
  amount: number;
}

export interface CashAdvanceLatestDetails {
  repay_optout?: boolean;
  remaining_balance?: number;
  status?: CashAdvanceStatus;
}

export interface CashAdvanceRepayment {
  repay_optout: boolean;
  advance_amount: number;
  cash_advance_id: number;
  cash_repayment_id: number;
  repay_amount: number;
  advance_taken_date: string;
  deduction_date: string;
  expected_repay_date?: string;
  status: CashAdvanceRepaymentStatus;
}

export enum CashAdvanceType {
  SPENDING = "spending",
  EXTERNAL_DEBIT = "external_debit",
}

export enum CashAdvanceStatus {
  ACTIVE = "Advanced",
  PENDING = "Pending",
  QUEUED = "Queued",
  CANCELED = "Canceled",
  REPAID = "Repaid",
}

export enum CashAdvanceRepaymentStatus {
  COMPLETE = "COMPLETED",
  PARTIAL_COMPLETED = "PARTIAL_COMPLETED",
  PLANNED = "PLANNED",
  SENT_PAYROLL = "SENT_TO_PAYROLL",
  DEDUCTION = "DEDUCTION_TAKEN",
  DEDUCTION_PARTIAL = "PARTIAL_DEDUCTION_TAKEN",
  MISSED = "MISSED",
  OPTED_OUT = "OPTED_OUT",
  CANCELLED = "CANCELLED",
}
// this is just for local testing
const useRealData = true;

@Injectable({
  providedIn: "root",
})
export class CashAdvanceService {
  private API_BASE = "cashadvances";

  constructor(private http: HttpClient, private httpGet: HttpGet, private translateService: TranslateService) {}

  getEligibility(): Observable<CashAdvanceResponse> {
    const force = true;
    return this.httpGet.get<CashAdvanceResponse>(`${this.API_BASE}?filter=eligibility`, force);
  }

  getHistory(): Observable<CashAdvanceResponse> {
    if (useRealData) {
      const force = true;
      return this.httpGet.get<CashAdvanceResponse>(`${this.API_BASE}`, force);
    }
    return of(AccountsTestData.generateTestObject("CashAdvanceResponse"));
  }

  createFundingRequest(payload: CashAdvancePayload): Observable<CashAdvanceResponse> {
    return this.http.post<CashAdvanceResponse>(`${this.API_BASE}`, payload);
  }

  reEnableRequest(id, repaymentId): Observable<CashAdvanceResponse> {
    return this.http.patch<CashAdvanceResponse>(`${this.API_BASE}`, {
      cash_advance_id: id,
      cash_advance_repayment_id: repaymentId,
      repay_optout: false,
    });
  }

  optOutRequest(id, repaymentId): Observable<CashAdvanceResponse> {
    return this.http.patch<CashAdvanceResponse>(`${this.API_BASE}`, {
      cash_advance_id: id,
      cash_advance_repayment_id: repaymentId,
      repay_optout: true,
    });
  }
}
