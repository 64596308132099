export class MenuOption {
  /**
   * @param {string} display what to show the user
   * @param {string} value the event the component will see
   * @param {string} ga firebase event if any
   * @param {string} route where we want to navigate to on action click
   * @param {string} color text color for the option
   */
  constructor(public display: string, public value: string, public ga?: string, public route?: string | string[], public color?: string) {}

  toString(): string {
    return this.display;
  }

  equals(x: MenuOption): boolean {
    return x.display === this.display && x.value === this.value;
  }

  clone(): MenuOption {
    return new MenuOption(this.display, this.value, this.ga);
  }
}
