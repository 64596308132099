import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { LocalStorage } from "@rars/ngx-webstorage";
import { CookieService } from "ngx-cookie-service";
import { Globals } from "../../globals";
import { FirebaseActionName, FirebaseCategory, FirebaseEvent, FirebaseEventName, FirebaseSubcategory, ServerConstants } from "../models";
import { CacheHelper } from "./cache.helper";
import { ClientService } from "./client.service";
import { AnalyticsDetails } from "./http.interceptor";
import { MobileState } from "./mobile.service";
import { WindowRef } from "./window.ref";
import { MessageKey } from "./messaging.service";
import { AmplitudeService } from "./amplitude.service";

declare let ga: Function;
// defined in the android app
// webview.addJavascriptInterface(new JavascriptEventHandler(),"jsHandler");
declare var jsHandler: any;
declare var Injected: InjectedData;

export const Native_Cashflow = "CASHFLOW_LINK";

interface InjectedToken {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}

export interface InjectedData {
  authToken: string;
  version: string;
  mobileState: MobileState;
  injectedTs?: Date;
  token: Partial<InjectedToken>;
}

export class NativeMessage {
  event: BridgeKey;
  data: object = {};

  constructor(event: BridgeKey, data?: object) {
    this.event = event;
    this.data = data;
  }

  stringify(): string {
    return JSON.stringify(this as object);
  }

  toJson(): object {
    return this as object;
  }
}

export interface NativeExternalLinkOptions {
  navigation?: string;
  title?: string;
  chatIcon?: boolean;
  displayStyle?: NativeExternalLinkType;
}

export interface NativeAnimationDetails {
  id: string;
  animationPath?: string;
  dismissible?: boolean;
  remote?: boolean;
  loop?: boolean;
}

/**
 * This had some version and inconsistent issues on the Native side, we are building it correctly for apps 1.55.0 and higher.
 * In the bridge.service.ts we are backfilling it for older iOS apps that were wrong.
 */
export interface NativeModalDetails {
  id: string;
  title: string;
  subText: string;
  image?: string;
  style: NativeModalStyle;
  type: NativeModalType;

  dismissable: boolean;

  cells?: NativeModalCell[];
  // Do not use except for older iOS Apps.
  elements?: NativeModalCell[];

  analytics: NativeAnalyticsDetails;
  primaryCta?: NativePrimaryCtaDetails;
  secondaryCta?: NativeSecondaryCtaDetails;
}
export interface NativeModalCellDetail {
  image?: string;
  title?: string;
  value?: string;
}

export interface NativeModalCell {
  id: string;
  left?: NativeModalCellDetail;
  right?: NativeModalCellDetail;
  style: NativeCellStyle;
  path?: string;
  code?: string;
}

export interface NativeModalElementDetail {
  label: string;
  value: string;
}

export interface NativeAnalyticsDetails {
  category: FirebaseCategory | string;
  subCategory: FirebaseSubcategory | string;
}

export interface NativePrimaryCtaDetails {
  label: string;
  code: string;
  style?: NativeModalStyle;
  path?: string;
}

export interface NativeSecondaryCtaDetails {
  label: string;
  code: string;
  path?: string;
}

export enum BridgeKey {
  ACCOUNT_SETUP = "ACCOUNT_SETUP",
  AUTH = "AUTH",
  CALL = "CALL",
  CASHFLOW_LINK = "CASHFLOW_LINK",
  COLOR = "COLOR",
  CREDENTIALS = "CREDENTIALS",
  CHAT = "CHAT",
  EXTERNAL_LINK = "EXTERNAL_LINK",
  EXIT_VIEW = "EXIT_VIEW",
  FIREBASE = "FIREBASE",
  HIDE_ANIMATION = "HIDE_ANIMATION",
  HIDE_NAV = "HIDE_NAV",
  MAPS = "MAPS",
  PERMISSIONS = "PERMISSIONS",
  SHOW_ANIMATION = "SHOW_ANIMATION",
  SHOW_CAMERA = "SHOW_CAMERA",
  SHOW_FEED = "SHOW_FEED",
  SHOW_HALFSHEET = "SHOW_HALFSHEET",
  SHOW_NAV = "SHOW_NAV",
  SHARE_FILE = "SHARE_FILE",
  UPDATE_APP = "UPDATE_APP",
  LOADED = "LOADED",
  INCREMENT = "INCREMENT",
  SET_USER_PROPERTY = "SET_USER_PROPERTY",
  ANALYTICS = "ANALYTICS",
  HIDE_LOADING = "HIDE_LOADING",
  SHOW_LOADING = "SHOW_LOADING",
  ROUTE = "ROUTE",
}

export enum NativeModalStyle {
  DEFAULT = "Default",
  PRIMARY = "Primary",
  WARNING = "Warning",
  LIST = "List",
}

export enum NativeModalType {
  TEXT = "Text",
  CELL = "Cell",
}

export enum NativeCellStyle {
  DEFAULT = "Default",
  INFO = "InfoText",
}

export enum NativeExternalLinkType {
  WEB_VIEW = "webview",
  BROWSER = "browser",
}

export enum DeviceType {
  iOS,
  Browser,
  Android,
  Unknown,
}

export enum ExitViewResult {
  SUCCESS = "success",
  FAILED = "failed",
  NEUTRAL = "neutral",
}

@Injectable()
export class BridgeService {
  static parseForMessageKey = (rawResponseString: string, key: MessageKey) => rawResponseString.slice(`${key}`.length);
  static parseHalfSheet = (rawResponseString: string, convertSingleQuotes: boolean = false) => {
    let respString = BridgeService.parseForMessageKey(rawResponseString, MessageKey.HALF_SHEET);

    if (convertSingleQuotes) {
      respString = respString.replace(/'/g, '"');
    }

    return JSON.parse(respString);
  };

  @LocalStorage()
  gaSetup: boolean;

  @LocalStorage()
  rawToken: string;

  firebaseParameters: AnalyticsDetails = null;

  deviceType = DeviceType.Unknown;

  private debouncingHalfSheet = false;

  constructor(
    private win: WindowRef,
    private router: Router,
    private cache: CacheHelper,
    private cookies: CookieService,
    private clientService: ClientService,
    protected amplitudeService: AmplitudeService
  ) {
    this.discoverDevice();
  }

  /**
   * moved GoogleAnalyticsService into BridgeService.
   * MEM-1481
   */

  private checkGa(): boolean {
    if (this.gaSetup) {
      return true;
    }

    if (this.rawToken) {
      const token = Globals.parseJwt(this.rawToken);
      const userId = token.guid;

      ga("create", Globals.getConstants().gaPropertyId, { userId });
      this.gaSetup = true;

      return true;
    }

    return false;
  }

  private saveFirebaseParameters(parameters: any) {
    this.firebaseParameters = parameters;
  }

  public discoverDevice(): void {
    if (typeof jsHandler !== "undefined") {
      this.deviceType = DeviceType.Android;
    } else if (
      this.win.nativeWindow.webkit &&
      typeof this.win.nativeWindow.webkit.messageHandlers !== "undefined" &&
      typeof this.win.nativeWindow.webkit.messageHandlers.buttonClicked !== "undefined"
    ) {
      this.deviceType = DeviceType.iOS;
    } else {
      this.deviceType = DeviceType.Browser;
    }

    console.log(DeviceType[this.deviceType]);
  }

  public getDeviceType(): DeviceType {
    return this.deviceType;
  }

  public isBrowser(): boolean {
    return this.deviceType === DeviceType.Browser;
  }

  public isAndroid(): boolean {
    return this.deviceType === DeviceType.Android;
  }

  public isIOS(): boolean {
    return this.deviceType === DeviceType.iOS;
  }

  public recordPageView(url: string): void {
    if (this.checkGa()) {
      ga("set", "page", url);
      ga("send", "pageview");
    } else {
      console.log("GA not setup yet!");
    }
  }

  public emitShowLoading(stringKey: string) {
    const data = `{"key":"${stringKey}"}`;
    this.postMessage(new NativeMessage(BridgeKey.SHOW_LOADING, JSON.parse(data)));
  }

  public emitHideLoading() {
    this.postMessage(new NativeMessage(BridgeKey.HIDE_LOADING));
  }

  public emitIncrement(userProp: string) {
    const data = `{"key":"${userProp}"}`;
    this.postMessage(new NativeMessage(BridgeKey.INCREMENT, JSON.parse(data)));
  }

  public emitAnalytics(eventName: string, action: string, parameters?: { [key: string]: any }) {
    // TODO restrict actions to certain enums
    const paramsString = JSON.stringify(parameters);

    let data = `{"event":"${eventName}","action":"${action}"}`;
    console.log("emitAnalytics name", eventName);
    console.log("emitAnalytics action", action);
    console.log("emitAnalytics parameters", parameters);
    console.log("emitAnalytics paramsString", paramsString);

    if (parameters) {
      data = `{"event":"${eventName}","action":"${action}","parameters":${paramsString}}`;
    }

    this.postMessage(new NativeMessage(BridgeKey.ANALYTICS, JSON.parse(data)));
  }

  public emitSetUserProperty(userProp: string, propValue: string | number | boolean) {
    const data = `{"key":"${userProp}","value": "${propValue}"}`;
    this.postMessage(new NativeMessage(BridgeKey.SET_USER_PROPERTY, JSON.parse(data)));
  }

  public isOlderDeviceVersionThan(version: string): boolean {
    const currentVersion = this.getDeviceVersion();

    return Boolean(currentVersion === null || currentVersion < version);
  }

  public getDeviceVersion(): string | null {
    if (typeof Injected === "object" && Injected.version) {
      return Injected.version;
    }

    const deviceType = this.getDeviceType();
    if (deviceType === DeviceType.Android || deviceType === DeviceType.iOS) {
      const versionCookie = this.cookies.get("version");
      if (versionCookie) {
        return versionCookie;
      } else {
        return this.cache.get("version");
      }
    } else {
      return null;
    }
  }

  public showUpdateApp(): boolean {
    return this.getDeviceVersion() >= "1.22.0";
  }

  /* Trigger bridge events */

  postMessage(message: NativeMessage): boolean {
    if (message.event === BridgeKey.EXIT_VIEW || message.event === BridgeKey.SHOW_FEED) {
      this.amplitudeService.logEvent(`web_exited`, { url: window.location.hostname });
    }
    console.log("sending message:", message.stringify());
    if (message.data && message.data["parameters"] && message.data["parameters"].custom) {
      message.data["parameters"].custom = JSON.stringify(message.data["parameters"].custom);
    }
    if (this.deviceType === DeviceType.Unknown) {
      this.discoverDevice();
    }
    if (this.deviceType === DeviceType.Android) {
      jsHandler.postMessage(message.stringify());
      return true;
    } else if (this.deviceType === DeviceType.iOS) {
      this.win.nativeWindow.webkit.messageHandlers.buttonClicked.postMessage(message);
      return true;
    } else {
      return false;
    }
  }

  /**
   * if first route path includes feed, call feed() otherwise navigate to route
   * @param {string[]} route inputs to router.navigate
   */
  handleRouteThatMayContainFeed(route: string[] | string) {
    if (Array.isArray(route)) {
      if (route[0].includes("feed")) {
        this.feed();
      } else {
        this.router.navigate(route);
      }
    } else {
      if (route.includes("feed")) {
        this.feed();
      } else {
        this.router.navigateByUrl(route);
      }
    }
  }

  /**
   * handle an auth and send it to the native app if present, if not native, show mobile login page.
   */
  handleAuthFailure(): void {
    // TODO this may need to become an event prop or error shown
    // this.emitEvent("auth_refresh");
    if (!this.postMessage(new NativeMessage(BridgeKey.AUTH))) {
      this.router.navigate(["login"]);
    }
  }

  showModal(details: NativeModalDetails) {
    const finalDetails = details;

    if (this.isIOS() && this.getDeviceVersion() < "1.55.0") {
      finalDetails.elements = finalDetails.cells;
      finalDetails.style = NativeModalStyle.LIST;
      finalDetails.type = NativeModalType.TEXT;
    }

    this.postMessage(new NativeMessage(BridgeKey.SHOW_HALFSHEET, finalDetails));
  }

  showAnimation(details: NativeAnimationDetails) {
    this.postMessage(new NativeMessage(BridgeKey.SHOW_ANIMATION, details));
  }

  hideAnimation(details: NativeAnimationDetails) {
    this.postMessage(new NativeMessage(BridgeKey.HIDE_ANIMATION, details));
  }

  pageLoaded() {
    this.postMessage(new NativeMessage(BridgeKey.LOADED));
  }

  externalLink(url: string, options: NativeExternalLinkOptions = {}): boolean {
    // TODO this may need to become an event prop
    // this.emitEvent("external_link", url);

    if (
      !this.postMessage(
        new NativeMessage(BridgeKey.EXTERNAL_LINK, {
          url,
          navigation: options["navigation"] || "back",
          title: options["title"] === undefined ? null : options["title"],
          chatIcon: options["chatIcon"] === undefined ? true : options["chatIcon"],
          displayStyle: options["displayStyle"] === undefined ? NativeExternalLinkType.WEB_VIEW : options["displayStyle"],
        })
      )
    ) {
      this.win.nativeWindow.open(url);
    }
    return true;
  }

  hideNavigation(): void {
    this.postMessage(new NativeMessage(BridgeKey.HIDE_NAV));
  }

  showNavigation(): void {
    this.postMessage(new NativeMessage(BridgeKey.SHOW_NAV));
  }

  /**
   * Call this method to close out the current webview
   * @param {Result} result
   */
  exitView(result: ExitViewResult = ExitViewResult.NEUTRAL) {
    if (this.deviceType === DeviceType.Android) {
      this.postMessage(new NativeMessage(BridgeKey.EXIT_VIEW, { result }));
    } else if (this.deviceType === DeviceType.iOS) {
      this.postMessage(new NativeMessage(BridgeKey.ROUTE, { path: "home" }));
      this.postMessage(new NativeMessage(BridgeKey.EXIT_VIEW, { result }));
    } else {
      this.postMessage(new NativeMessage(BridgeKey.ROUTE, { path: "home" }));
      this.postMessage(new NativeMessage(BridgeKey.EXIT_VIEW, { result }));
    }
  }

  phoneCall(value?): boolean {
    const number = value || Globals.supportPhone;
    // TODO this may need to become an event prop
    // this.emitEvent("phone_call", number);

    if (!this.postMessage(new NativeMessage(BridgeKey.CALL, { number }))) {
      console.log("native not present, opening new tab to tel:" + number);
      this.win.nativeWindow.open("tel:" + number);
      return true;
    }
  }

  /**
   * if device is browser, route back to native app otherwise post NativeMessage EXIT_VIEW
   * @param {NavigationExtras} extras second parameter of router.navigate, may contain queryParams that indicate toast message
   */
  feed(extras?: NavigationExtras) {
    this.exitView();
  }

  credentials(): boolean {
    return this.postMessage(new NativeMessage(BridgeKey.CREDENTIALS));
  }

  /**
   * send an analytics event to the native app and log it through Firebase.
   *
   * @params {FirebaseEvent | string} event the whole event or just a event id.
   */

  firebaseEvent(event: string, params: AnalyticsDetails = null): void {
    // TODO will need to update events appropriately
    //
    // if (params) {
    //   switch (event) {
    //     case FirebaseEventName.View:
    //       console.log("firebaseEvent view", event, params);
    //       if (!params.action && !params.custom) {
    //         this.emitAnalytics(params.page, FirebaseActionName.Shown);
    //       }
    //       break;
    //     case FirebaseEventName.Click:
    //       console.log("firebaseEvent click", event, params);
    //       if (params.action === FirebaseActionName.Submit || params.action === FirebaseActionName.Route) {
    //         this.emitAnalytics(params.page, FirebaseActionName.Tapped);
    //       } else if (params.action === FirebaseActionName.Dismiss) {
    //         this.emitAnalytics(params.page, FirebaseActionName.Dismiss);
    //       }
    //       break;
    //     default:
    //       break;
    //   }
    // }
    //
  }

  showChatWithFeed(): void {
    this.emitAnalytics("chat", FirebaseActionName.Shown);
    this.feed();
    this.postMessage(new NativeMessage(BridgeKey.CHAT));
  }

  showChat(): void {
    this.emitAnalytics("chat", FirebaseActionName.Shown);
    this.postMessage(new NativeMessage(BridgeKey.CHAT));
  }

  showShareReport(fileName: string, data: any) {
    const eventDetails = { filename: fileName, contentType: "application/pdf", content: data };

    this.postMessage(new NativeMessage(BridgeKey.SHARE_FILE, eventDetails));
  }

  /* Special pre-configured triggers */
  showDemoBlock(category, subCategory) {
    if (this.debouncingHalfSheet) {
      return;
    }

    this.debouncingHalfSheet = true;
    this.showModal({
      id: "demoClientHalfSheetError",
      title: "This feature has been turned off",
      subText:
        "We're sorry but since you're signed in with a demo account, some app functionality has been disabled. For any questions, please talk to your Brightside Sales representative. Thank you!",
      image: "dancing_pig",
      style: NativeModalStyle.DEFAULT,
      type: NativeModalType.TEXT,
      analytics: {
        category,
        subCategory,
      },
      dismissable: true,
      primaryCta: {
        label: "Close",
        path: "dismiss",
        code: "primaryCtaKey",
      },
    });

    setTimeout(() => (this.debouncingHalfSheet = false), 1500);
  }

  isFeatureEnabled(featureKey: string) {
    const currentConstants = Globals.getConstants();
    const feature = currentConstants.featureFlags[featureKey];

    if (!currentConstants.production) {
      console.log({ method: "isFeatureEnabled", featureKey, feature, debug: { fullFeatureList: Globals.getConstants().featureFlags } });
    }

    if (typeof feature === "undefined") {
      return false;
    } else if (typeof feature === "boolean") {
      return feature;
    } else if (typeof feature === "string" && Boolean(feature)) {
      if (Boolean(this.getDeviceVersion())) {
        return feature <= this.getDeviceVersion();
      } else if (this.isBrowser()) {
        return true;
      }
    }
    return false;
  }
}
