import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { map } from "rxjs/operators";
import { Globals } from "../../globals";
import { Card, CardInput } from "../models/types/card";
import { FinancialServicesResponse } from "../models/vendor/synapse/synapse";
import { CacheHelper } from "./cache.helper";
import { MobileService } from "./mobile.service";
import { RegistryService } from "./registry.service";

@Injectable()
export class CardService {
  async postAction(cardInput: CardInput): Promise<Card> {
    this.forceCardRefresh();
    return this.http.post<Card>(Globals.configs.cardsUrl, cardInput).toPromise();
  }

  putAction(cardInput: CardInput): Observable<FinancialServicesResponse> {
    this.forceCardRefresh();
    return this.http.put<FinancialServicesResponse>(Globals.configs.cardsUrl, cardInput);
  }

  async refreshCard(caseType: string): Promise<Card> {
    return this.findCard(caseType, true);
  }

  async getCards(force = false): Promise<Card[]> {
    if (this.cardsAreValid() && !force) {
      return this.cacheHelper.get<Card[]>("cards");
    }
    this.cacheHelper.flush("forceCardRefresh");
    const response = await this.mobile.fetchMobileState(true).toPromise();

    console.log("process card response!");
    this.processCards(response.cards);
    this.setCards(response.cards);
    return response.cards;
  }

  getCardsFromCache(): Card[] {
    let cards = this.cacheHelper.get<Card[]>("cards");
    if (!cards) {
      const ms = this.mobile.fetchMobileStateFromCache();
      if (ms) {
        cards = ms.cards;
      }
    }
    return cards;
  }

  updateCache(card: Card): Card[] {
    const cards = this.getCardsFromCache();
    for (let c = 0; c < cards.length; c++) {
      if (cards[c].case_type === card.case_type) {
        cards[c] = card;
      }
    }

    this.setCards(cards);

    return cards;
  }

  protected cardsAreValid(): boolean {
    return this.cacheHelper.isValid("cards");
  }
  protected setCards(cards: Card[]): void {
    this.cacheHelper.start("cards", 120, cards);
  }

  protected processCards(cards: Card[]): void {
    if (!cards || !cards.length) {
      console.log("error loading cards.");
      return;
    }

    for (const car of cards) {
      if (!car.componentType) {
        continue;
      }
      car.component = RegistryService.getCardComponent(car.componentType);
    }

    for (const card of cards) {
      card.cssClass = card.actions && card.actions.length - 1 < Globals.PaperClasses.length ? Globals.PaperClasses[card.actions.length - 1] : "paper--single";
    }
  }

  //TODO use HttpGet service for automatic caching
  constructor(private http: HttpClient, private cacheHelper: CacheHelper, private mobile: MobileService) {}

  async findCard(caseType: string, force = false): Promise<Card> {
    const cards = await this.getCards(force);
    return cards ? cards.find(c => c.case_type === caseType) : null;
  }

  findCardFromCache(caseType: string): Card {
    const cards = this.getCardsFromCache();
    return cards ? cards.find(c => c.case_type === caseType) : null;
  }

  static isCardActive(card: Card): boolean {
    return !!card && !!card.actions && card.actions.length === 1 && !!card.actions[0].route && !!card.actions[0].route.length;
  }

  public forceCardRefresh() {
    this.cacheHelper.flush("cards");
    this.cacheHelper.start("forceCardRefresh", 30, true);
  }

  private findPathFromCard(card: Card): string {
    if (card && card.actions && card.actions.length && card.actions[0].route) {
      return card.actions[0].route;
    } else {
      return null;
    }
  }

  getCtaPathOnCard(caseType: string, force = false): Observable<string> {
    return this.mobile.fetchMobileState(force).pipe(
      map(ms => {
        if (ms.activatedCards) {
          const card = ms.activatedCards.find(ci => ci.case_type === caseType);
          if (card) {
            return card.ctaPath;
          }
        }
        if (ms.cards) {
          const card = ms.cards.find(ci => ci.case_type === caseType);
          return this.findPathFromCard(card);
        }

        //fallback.
        return null;
      })
    );
  }
}
