import { Injectable } from "@angular/core";

import { SavingsState } from "../savings-state.service";
import { SavingsAccountAutosaveSnapshot, SavingsAccountSnapshot } from "./savings-account.interface";

/**
 * This service is a singleton and state will be remain from use to use
 * This was done to be able to utilize it for checks as needed throughout the application.
 * Please DON'T include in any NgModule without changing expectation of being a singleton
 */
@Injectable({
  providedIn: "root"
})
export class SavingsAccountAutosaveSnapshotService {
  private internalSnapOfAutosave?: SavingsAccountAutosaveSnapshot;
  private lastSavingsAccountState: SavingsState;

  constructor() {}

  public reset() {
    this.internalSnapOfAutosave = undefined;
    this.lastSavingsAccountState = undefined;
  }

  public accountUpdated(accountSnapshot?: SavingsAccountSnapshot) {
    this.internalSnapOfAutosave = accountSnapshot
      ? {
          next_deposit_amount: accountSnapshot.next_deposit_amount,
          has_future_schedule: accountSnapshot.has_future_schedule,
          next_deposit_date: accountSnapshot.next_deposit_date,
          schedule_type: accountSnapshot.schedule_type,
          schedule_paused: accountSnapshot.schedule_paused,
          is_first_schedule: accountSnapshot.is_first_schedule
        }
      : undefined;
  }

  public setupStatusChange(savingsState: SavingsState) {
    this.lastSavingsAccountState = savingsState;
  }

  private checkIfSetupDetailsExist() {
    return this.internalSnapOfAutosave?.next_deposit_amount && this.internalSnapOfAutosave?.schedule_type;
  }

  get details(): SavingsAccountAutosaveSnapshot {
    return this.internalSnapOfAutosave;
  }

  get isAvailableForUse(): boolean {
    //ToDo: ... what? lol
    return false;
  }

  get isFirstSchedule() {
    return Boolean(this.internalSnapOfAutosave?.is_first_schedule);
  }

  get isSaving(): boolean {
    // For an active savings we are looking for three things: Schedule is not paused, we have some value for next deposit, and there is a scheduled type
    return Boolean(!this.isPaused && this.checkIfSetupDetailsExist());
  }

  get isPaused(): boolean {
    return Boolean(this.internalSnapOfAutosave?.schedule_paused);
  }

  get hasSetupBefore(): boolean {
    return Boolean(this.lastSavingsAccountState === SavingsState.ACTIVE && this.checkIfSetupDetailsExist());
  }
}
