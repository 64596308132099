import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment-business-days";
import { Observable } from "rxjs/Observable";
import { HttpGet } from "../shared";
import { DepositAccountResponse, FinancialServicesResponse, LinkRoutingResponse, RoutingCreating } from "../shared/models/vendor/synapse/synapse";
import { CacheHelper } from "../shared/services/cache.helper";
import { ApiResponse } from "./shared/services/external-savings/external-savings.interface";
import { LinkedBank, LinkedBankStatus, SavingsAccountSnapshot } from "./shared/services/savings-account/savings-account.interface";
import { of } from "rxjs";
import { AccountsTestData } from "../dda/accounts-test-data";
import { TranslateService } from "@ngx-translate/core";
import { MicroAccountsTranslationKey } from "../shared/model/i18n/i18n.enum";

export enum MicrodepositStepStatus {
  complete = "complete",
  current = "current",
  incomplete = "incomplete",
}

export enum MicrodepositStepState {
  waitForDeposits,
  verifyAccount,
}

export enum TransferAccountType {
  ACH = "ACH",
  Savings = "Savings",
  Spending = "Spending",
}
export class TransferAccount {
  name: string;
  logo: string;
  subdetails: string;
  type: TransferAccountType;
  balance?: number;
  lastFour?: string;
  id?: number;
}

export interface LinkedBanksResponse extends FinancialServicesResponse {
  banks: LinkedBank[];
}

export class TransferParticipant {
  id?: number;
  type: TransferAccountType;
}
export class Transfer {
  "from": TransferParticipant;
  "to": TransferParticipant;
  "transfer_date": string;
  "amount": number;
  "transfer_reason_key"?: string;
}
// this is just for local testing
const useRealData = true;

@Injectable({
  providedIn: "root",
})
export class FinsolService {
  constructor(private http: HttpClient, private httpGet: HttpGet, private cache: CacheHelper, private translateService: TranslateService) {}

  flushSavingsCachedResponses() {
    this.flushAccount();

    //ToDo: Add keys to flush out
    this.cache.flush(`deposit/link`);
    this.cache.flush(`deposit/link/routing`);
    this.cache.flush(`deposit/v2`);
  }

  flushAccount() {
    this.cache.flush("savings");
    this.cache.flush("client/mobilestate");
  }

  linkRouting(payload: RoutingCreating, bankId?: number): Observable<LinkRoutingResponse> {
    this.flushSavingsCachedResponses();

    if (bankId) {
      return this.retryLinkRouting(payload, bankId);
    } else {
      return this.http.post<LinkRoutingResponse>("deposit/link/routing", payload);
    }
  }

  retryLinkRouting(payload: RoutingCreating, bankId: number): Observable<LinkRoutingResponse> {
    this.flushSavingsCachedResponses();

    return this.http.put<LinkRoutingResponse>(`deposit/link/routing/${bankId}`, payload);
  }

  resendMicrodeposits(bankId: number): Observable<FinancialServicesResponse> {
    this.flushSavingsCachedResponses();

    const payload = { action: "resendMicroDeposits" };
    return this.http.patch<FinancialServicesResponse>(`deposit/link/routing/${bankId}`, payload);
  }

  verifyMicrodeposit(bankId: number, amounts: number[]): Observable<FinancialServicesResponse> {
    this.flushSavingsCachedResponses();

    const payload = { amounts, action: "verifyMicroDeposits" };
    return this.http.patch<FinancialServicesResponse>(`deposit/link/routing/${bankId}`, payload);
  }

  mapClassToCopy(accountClass: "CHECKING" | "SAVINGS"): string {
    if (accountClass === "CHECKING") {
      return this.translateService.instant(MicroAccountsTranslationKey.MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_CHECKING);
    }
    if (accountClass === "SAVINGS") {
      return this.translateService.instant(MicroAccountsTranslationKey.MICRO_ACCOUNTS_LINK_ACCOUNT_ACCOUNT_TYPE_SAVINGS);
    }
  }

  getAccountsList(banks: LinkedBank[]): TransferAccount[] {
    // TODO 1. External bank account(s) in alphabetical order
    if (banks && banks.length > 0) {
      return banks.filter(FinsolService.isLinked).map((bank: LinkedBank) => {
        const acct = new TransferAccount();
        acct.type = TransferAccountType.ACH;
        acct.logo = "bank-circle.svg";
        acct.name = bank.bank_name;
        acct.subdetails = `${this.mapClassToCopy(bank.account_class)} - ${bank.last_four}`;
        acct.lastFour = bank.last_four;
        acct.id = bank.id;
        return acct;
      });
    } else {
      return [];
    }
  }

  mapSpendingAccount(spending: DepositAccountResponse): TransferAccount {
    const balance = Number(spending.balance).toFixed(2);

    return {
      type: TransferAccountType.Spending,
      logo: "transfer-spending.svg",
      // TODO in the future the api should return a key for the name
      name: this.translateService.instant(MicroAccountsTranslationKey.MICRO_ACCOUNTS_SPENDING_PRODUCT_NAME),
      subdetails: `$${balance} ${this.translateService.instant(MicroAccountsTranslationKey.MICRO_ACCOUNTS_LINKED_BANK_DETAILS_BALANCE)}`,
      balance: spending.balance,
      lastFour: spending.last_four_acct_num,
    } as TransferAccount;
  }

  mapSavingsAccount(savings: SavingsAccountSnapshot, isDelete = false): TransferAccount {
    const balance = Number(savings.available_balance).toFixed(2);
    const subdetailSuffix = isDelete
      ? this.translateService.instant(MicroAccountsTranslationKey.MICRO_ACCOUNTS_LINKED_BANK_DETAILS_WITHDRAW)
      : this.translateService.instant(MicroAccountsTranslationKey.MICRO_ACCOUNTS_LINKED_BANK_DETAILS_BALANCE);

    return {
      type: TransferAccountType.Savings,
      logo: "transfer-savings.svg",
      // TODO in the future the api should return a key for the name
      name: this.translateService.instant(MicroAccountsTranslationKey.MICRO_ACCOUNTS_SAVINGS_PRODUCT_NAME),
      subdetails: `$${balance} ${subdetailSuffix}`,
      balance: savings.available_balance,
    } as TransferAccount;
  }

  transferFunds(payload: Transfer): Observable<FinancialServicesResponse> {
    this.cache.flush("savings/transactions");
    return this.http.post<FinancialServicesResponse>("savings/transactions", payload);
  }

  getLinkedBanks(): Observable<LinkedBanksResponse> {
    if (useRealData) {
      return this.httpGet.get<LinkedBanksResponse>("deposit/link", true);
    }
    return of({ statusCode: 200, banks: [AccountsTestData.generateTestObject("LinkedBank", { status: LinkedBankStatus.LINK_VERIFY_DEPOSIT })] });
  }

  deleteLinkedBank(bankId: string): Observable<ApiResponse> {
    this.flushSavingsCachedResponses();

    return this.http.delete<ApiResponse>(`deposit/link/${bankId}`);
  }

  getLinkedBanksWLogo(): Observable<LinkedBank[]> {
    return this.getLinkedBanks().map((res) => {
      if (res && res.banks) {
        res.banks.forEach((bank) => {
          if (!bank.bank_logo) {
            bank.bank_logo = "bank-circle.svg";
          }
        });

        return res.banks;
      } else {
        return [];
      }
    });
  }

  static isLinked(bank: LinkedBank) {
    return bank.status === LinkedBankStatus.ACTIVE || bank.status === LinkedBankStatus.LINKED;
  }

  static isLinking(bank: LinkedBank) {
    return bank.status === LinkedBankStatus.LINK_VERIFY_DEPOSIT || bank.status === LinkedBankStatus.LINKING;
  }

  getMicroLinkingState(initiatedTime) {
    const startOfNextBusinessDay = moment(initiatedTime).local().businessAdd(1).startOf("day");
    return moment().isSameOrAfter(startOfNextBusinessDay) ? MicrodepositStepState.verifyAccount : MicrodepositStepState.waitForDeposits;
  }

  getSavingsAccount(): Observable<SavingsAccountSnapshot | undefined> {
    return this.httpGet.get<SavingsAccountSnapshot | undefined>("savings");
  }
}
